import React, { useState, useEffect } from 'react'
import '../styles/login.scss'
import { Helmet } from 'react-helmet'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@material-ui/core'
import useWindowDimesions from '../components/useWindowDimesions'
import CheckAuth from '../components/CheckAuth'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { CountryData } from '../data/country-data'
import SplashScreen from './SplashScreen'
import { callApi } from '../utils/api-utils'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { v4 as uuidv4 } from 'uuid'
import authConfig from '../config/auth'

const defaults = {
  methods: {
    POST: {
      method: 'POST'
    }
  }
}

// const signUpEndpoint = {
//   ...defaults.methods.POST,
//   uri: '/api/user/signup'
// }
const referralEndpoint = {
  ...defaults.methods.POST,
  uri: '/api/referralHistory/add-referral-history'
}

// const referralCode = length => {
//   let result = ''
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
//   const charactersLength = characters.length
//   let counter = 0
//   while (counter < length) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength))
//     counter += 1
//   }
//   return result
// }

export default function Signup(props) {
  const { width } = useWindowDimesions()
  const [
    ,
    // loadings
    setLoadings
  ] = useState(false)
  const userRegister = useSelector(state => state.userRegister)
  const [passReferrers, setPassReferrers] = useState(null)

  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const {
    userInfo,
    loading
    //  error
  } = userRegister
  // const [verified, setVerified] = useState(false)
  // const redirect = props?.location.search
  //   ? props.location.search.split("=")[1]
  //   : "/";

  const [email, setEmail] = useState('')
  const [wait, setWait] = useState(false)
  const [legalname, setLegalName] = useState('')
  const [phonenumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [screen, setScreen] = useState(1)

  // const [OTP, setOTP] = useState(3)
  const [ConfirmOTP, setConfirmOTP] = useState('')
  // const [selectCountryValue, setSelectCountryValue] = useState('')
  const [selectCountryCode, setSelectCountryCode] = useState('91')
  const [otpSelect, setOtpSelect] = useState('')
  const [studentSelect, setStudentSelect] = useState('')

  // const gpassword = '#@12344234'
  // const responseGoogle = response => {
  //   // console.log(response.profileObj);
  //   setLoadings(true)
  //   dispatch(register(response.profileObj.name, response.profileObj.email, gpassword, verified))
  // }

  // const dispatch = useDispatch()
  useEffect(() => {
    if (userInfo) {
      window.location.href('/')
    }
    if (window) {
      setPassReferrers(window?.location?.search?.split('=')[1] || null)
    }
  }, [props.history, userInfo])

  async function FirstNext(e) {
    setWait(true)
    e.preventDefault()
    if (email.length !== 0 && legalname.length !== 0 && password.length !== 0 && phonenumber.length !== 0) {
      const OTPcode = Math.floor(100000 + Math.random() * 900000)

      await axios
        .get(
          `/api/mail/app/${email}/${OTPcode} - Your Email Verification Code/Hello, \n Your Email Verification Code is ${OTPcode} \n If you didn't ask to code, you can simply ignore this email. \n Thanks,\n Samuel Hanemann Academy for Homeopathy`,
          {
            headers: {
              otpSelect,
              phone: `${selectCountryCode.concat(phonenumber)}/${'true'}`,
              email,
              is_register: 'true'
            }
          }
        )

        .then(() => {
          setWait(false)
          setScreen(2)
        })
        .catch(err => {
          setWait(false)
          alert(err.response.data.message)
        })
      // setWait(false)
      // setScreen(2)
    } else {
      setWait(false)
      alert('Something went wrong')
    }
  }

  async function SecondNext(e) {
    setWait(true)
    let body = { otp: ConfirmOTP }
    e.preventDefault()
    await axios
      .post(`/api/mail/otp-match`, body, { headers: { phone: phonenumber, email } })
      .then(async response => {
        if (response.data.statusCode === 202) {
          setWait(false)
          alert(response.data.message)
        } else {
          const body = {
            legal_name: legalname,
            password: password,
            email: email,
            phone: selectCountryCode.concat(phonenumber),
            verified: true,
            created: new Date().toDateString(),
            referral_code_use: passReferrers || null,
            student_select: studentSelect,
            is_register: true,
            device_id: uuidv4()
          }

          try {
            await axios.post('/api/user/signup', body).then(async ({ data }) => {
              if (data) {
                localStorage.setItem('mongoId', data.data.id)
                localStorage.setItem('referral_code', data.data.referral_code)
                const stringifyDataStudent = JSON.stringify(data.data)
                localStorage.setItem('profileInformation', stringifyDataStudent)
                localStorage.setItem('studentStore', stringifyDataStudent)
                localStorage.setItem('userData', stringifyDataStudent)
                await localStorage.setItem(authConfig.storageTokenKeyName, data?.data?.token || '')

                const body = {
                  refer_use_userId: data.id,
                  referral_code_use: passReferrers || null
                }

                setTimeout(async () => {
                  await callApi({ uriEndPoint: referralEndpoint, body })
                    .then(({ data }) => {
                      if (data) {
                        window.location.href = '/'
                      }
                    })
                    .catch(e => console.log(e))
                }, 800)
              }
            })
          } catch (error) {
            console.log(`🚀  ~ file: Signup.js:203 ~ SecondNext ~ error:`, error)
          }
        }
      })
      .catch(error => alert(error))
  }

  const [seconds, setSeconds] = useState(60)

  useEffect(() => {
    if (screen === 2) {
      if (seconds > 0) {
        const timer = setTimeout(() => setSeconds(seconds - 1), 1000)
        return () => clearTimeout(timer)
      }
    }
  }, [seconds, screen])

  const reCount = async () => {
    setSeconds(60)
    const OTPcode = Math.floor(100000 + Math.random() * 900000)
    await axios
      .get(
        `/api/mail/app/${email}/${OTPcode} - Your Email Verification Code/Hello, \n Your Email Verification Code is ${OTPcode} \n If you didn't ask to code, you can simply ignore this email. \n Thanks,\n Samuel Hanemann Academy for Homeopathy`,
        { headers: { otpSelect, phone: phonenumber, email } }
      )
      .then(() => {
        setScreen(2)
      })
      .catch(() => {
        alert('Please try again')
      })
  }
  if (wait) {
    return <SplashScreen />
  } else {
    return (
      <CheckAuth
        imp={
          <>
            <div className='signup' style={{ backgroundColor: '#252d4a' }}>
              <Helmet>
                <title> Sign up | Samuel Hahnemann Academy for Homeopathy – India's Only Homeopathy E-Learning</title>

                <meta
                  name='description'
                  content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
                />
                {/* Add more meta tags as needed */}
                <script type='application/ld+json'>
                  {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'E-Learning @ SHAH',
                    name: 'E-Learning @ SHAH | Samuel Hahnemann Academy for Homeopathy',
                    url: 'https://www.samuelhahnemann.org/'
                    // Add more structured data properties
                  })}
                </script>
              </Helmet>
              <div
                className='signup-form'
                style={{ paddingBottom: width < 999 ? 20 : 20, marginTop: width < 999 ? 20 : 0 }}
              >
                <img className='logo' src='/shahlogo.svg' alt='drkrutikshah.online' />

                {screen === 1 ? (
                  <form onSubmit={FirstNext}>
                    <TextField
                      required
                      id='outlined-required'
                      className='legalname'
                      label='Legal name'
                      value={legalname}
                      onChange={e => setLegalName(e.target.value)}
                      size='small'
                      variant='outlined'
                    />

                    <TextField
                      required
                      id='outlined-required'
                      className='email'
                      label='Email address '
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      size='small'
                      variant='outlined'
                    />

                    <div style={{ display: 'flex' }}>
                      <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
                        <InputLabel id='demo-simple-select-outlined-label'>ISD Code</InputLabel>
                        <Select
                          label='ISD Code'
                          value={selectCountryCode}
                          required
                          id='demo-simple-select-outlined'
                          labelId='demo-simple-select-outlined-label'
                          onChange={event => setSelectCountryCode(event.target.value)}
                        >
                          {CountryData.map((i, indexedDB) => (
                            <MenuItem key={indexedDB + 'calling_code'} value={i.calling_code}>
                              {i.calling_code}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          value={phonenumber}
                          style={{ marginLeft: '5px' }}
                          type='text'
                          required
                          id='outlined-required'
                          className='phone-number'
                          label='Phone number'
                          onChange={e => {
                            const value = e.target.value.slice(0, 10)
                            if (Number(value)) {
                              setPhoneNumber(value)
                            } else {
                              if (e.target.value.length === 0) {
                                setPhoneNumber('')
                              }
                            }
                          }}
                          size='small'
                          variant='outlined'
                        />
                      </FormControl>
                    </div>
                    <TextField
                      required
                      id='outlined-required'
                      className='password'
                      label='Create Password'
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      size='small'
                      variant='outlined'
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton onClick={handleTogglePasswordVisibility} edge='end'>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <FormControl>
                      <Typography>
                        <b>Select Student Type</b>{' '}
                      </Typography>
                      <RadioGroup
                        row
                        aria-label='controlled'
                        name='controlled'
                        value={studentSelect}
                        onChange={e => setStudentSelect(e.target.value)}
                      >
                        <FormControlLabel value='indian_students' control={<Radio />} label='Indian Students' />
                        <FormControlLabel
                          value='indian_homeopathic_practitioners'
                          control={<Radio />}
                          label='Indian Homeopathic Practitioners'
                        />
                        <FormControlLabel
                          style={{ marginTop: width < 992 ? '10px' : '' }}
                          value='international_homeopathic_students'
                          control={<Radio />}
                          label='International Students and Homeopaths'
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <Typography>
                        <b>Select OTP</b>{' '}
                      </Typography>
                      <RadioGroup
                        row
                        aria-label='controlled'
                        name='controlled'
                        value={otpSelect}
                        onChange={e => setOtpSelect(e.target.value)}
                      >
                        <FormControlLabel value='email' control={<Radio />} label='Email' />
                        <FormControlLabel value='sms' control={<Radio />} label='SMS' />
                      </RadioGroup>
                    </FormControl>

                    <button onClick={() => setLoadings(true)} style={{ cursor: 'pointer' }} type='submit'>
                      SignUp
                    </button>
                  </form>
                ) : screen === 2 ? (
                  <>
                    <form onSubmit={SecondNext}>
                      <TextField
                        required
                        id='outlined-required'
                        className='email'
                        label='OTP Code'
                        value={ConfirmOTP}
                        onChange={e => setConfirmOTP(e.target.value)}
                        size='small'
                        variant='outlined'
                      />
                      <div style={{ marginTop: '1.5rem' }}>
                        <a href='/terms-conditions'>
                          <input type='checkbox' />
                          <span style={{ marginLeft: '5px' }}>
                            I accept all the Terms and Condition and Privacy Policy of SHAH
                          </span>
                        </a>
                      </div>

                      <button style={{ cursor: 'pointer' }} type='submit'>
                        {loading === true ? (
                          <div className='loadingio-spinner-ellipsis-wqipn74m1df'>
                            <div className='ldio-r4q1jc8bpcd'>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        ) : (
                          'Create Account'
                        )}
                      </button>
                      <div style={{ marginTop: '10px' }}>
                        <Button
                          variant='contained'
                          disabled={seconds !== 0 ? true : false}
                          style={{ marginLeft: '10px' }}
                          onClick={() => reCount()}
                        >
                          Resend {seconds !== 0 && 'in'} {seconds}
                        </Button>
                      </div>
                    </form>
                  </>
                ) : (
                  <></>
                )}

                <div className='extra-option'>
                  <div className='options'>
                    Have an account{' '}
                    <a
                      href='/login'
                      style={{
                        textDecoration: 'none',
                        color: '#000',
                        fontWeight: 'bold'
                      }}
                    >
                      LogIn?
                    </a>
                  </div>
                  <div className='options'>
                    <a href='/forget-password' style={{ textDecoration: 'none', color: '#000' }}>
                      Forgot Password
                    </a>
                  </div>
                </div>
              </div>
              <div className='signup_notes'>
                <p style={{ color: 'white' }}>
                  PLEASE NOTE : You will receive course completion certificate and badge of honor by courier at your
                  door step worldwide.
                </p>
              </div>
            </div>
          </>
        }
      />
    )
  }
}
