import React from 'react'

export default function EmptyPage(props) {
  return (
    <>
      <div className='emptyPage'>
        <img src='/notfound.png' alt={props.name} />
        <p>Coming Soon</p>
      </div>
    </>
  )
}
