import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ShowMyQuiz from './ShowMyQuiz'
export function ShowMyChapters(props) {
  const [Chapters, setChapters] = useState([])

  useEffect(() => {
    getChapters()
  }, [])

  async function getChapters() {
    await axios
      .post('/api/course/all-chapters', {
        id: props.id,
        body: 'front-end-api'
      })
      .then(res => {
        setChapters(res.data)
      })
      .catch(e => console.log(e))
  }

  return <>{Chapters.length}</>
}

export function ShowMyCoursePageLessons({ id }) {
  const [Chapters, setChapters] = useState('')

  useEffect(() => {
    getChapters()
  }, [])

  async function getChapters() {
    await axios
      .get(`/api/course/all-course-lessons/${id}?type=front-end-api/`)
      .then(res => {
        //    const FilterChapter = courses.filter(r=>r.courseID===props.chapters)

        // const lessonsl = []
        // const r = res.data

        // lessonsl.push([...r[0],...r[1]])

        //

        let v = 0

        for (let i = 0; res.data.length > i; i++) {
          const scan = res.data[i]
          v = v + scan.length
        }

        setChapters(v)
      })
      .catch(e => console.log(e))
  }

  return <>{Chapters}</>
}

export function ShowMyCoursePageQuiz(props) {
  const [Chapters, setChapters] = useState([])

  useEffect(() => {
    getChapters()
  }, [])

  async function getChapters() {
    await axios
      .get('/api/course/chapters?type=front-end-api')
      .then(res => {
        const FilterChapter = res.data.courses.filter(r => r.courseID === props.id)
        setChapters(FilterChapter)
      })
      .catch(e => console.log(e))
  }

  return (
    <>
      <ShowMyQuiz Chapters={Chapters} />
    </>
  )
}
