import React, { Suspense, lazy } from 'react'
import useWindowDimesions from '../components/useWindowDimesions'
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'

const Footer = lazy(() => import('../components/Footer'))

const MobileNavBar = lazy(() => import('../components/MobileNavBar'))

const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))

const QuestionAndAnswerPage = lazy(() => import('./QuestionAndAnswer'))

const QuestionAnswerMainPage = () => {
  const { width } = useWindowDimesions()

  if (width < 799) {
    return (
      <>
        <Helmet>
          <title>E-Learning @ SHAH - Organon with SHAH</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'E-Learning @ SHAH',
              name: 'E-Learning @ SHAH - Free Webinar',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <MobileNavBar />
          <QuestionAndAnswerPage />
          <Footer />
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>E-Learning @ SHAH - Organon with SHAH</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'E-Learning @ SHAH',
              name: 'E-Learning @ SHAH - Free Webinar',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <div className='desktop'>
            <DesktopNavBar />
            <QuestionAndAnswerPage />
            <div>
              <Footer />
            </div>
          </div>
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  }
}

export default QuestionAnswerMainPage
