import React, { useEffect, useState } from 'react'
import '../styles/quiz.scss'
import useWindowDimensions from '../components/useWindowDimesions'
import { callApi } from '../utils/api-utils'
import moment from 'moment-timezone'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const quizEndpoint = {
  ...defaults.methods.POST,
  uri: '/api/course/user-quiz'
}

// export default function QuizPlay() {
//   const questions = [
//     {
//       questionText: 'What is the meaning of “MISSION”?',
//       answerOptions: [
//         { answerText: 'Help somebody with personal benefit', isCorrect: false },
//         {
//           answerText: 'Help somebody without personal benefit',
//           isCorrect: false
//         },
//         {
//           answerText: 'Help somebody without personal benefit without expectation',
//           isCorrect: true
//         },
//         {
//           answerText: 'Help somebody with personal benefit with expectation',
//           isCorrect: false
//         }
//       ]
//     },
//     {
//       questionText: '“Restore the sick to health” is used for?',
//       answerOptions: [
//         { answerText: 'Definite & Fixed purpose', isCorrect: false },
//         { answerText: 'Individual & Relative purpose', isCorrect: true },
//         { answerText: 'Both are true', isCorrect: false },
//         { answerText: 'None are true', isCorrect: false }
//       ]
//     },
//     {
//       questionText: 'To construct so-called systems is allowed in Homeopathy?',
//       answerOptions: [
//         { answerText: 'Yes, we should.', isCorrect: false },
//         { answerText: 'No, we should not.', isCorrect: true },
//         { answerText: 'Amazon', isCorrect: false },
//         { answerText: 'Microsoft', isCorrect: false }
//       ]
//     },
//     {
//       questionText: 'Mode of the disease originate in the invisible interior of the body is',
//       answerOptions: [
//         { answerText: 'Definitely Known to us.', isCorrect: false },
//         { answerText: 'Never known to us.', isCorrect: true },
//         { answerText: 'Conditionally Possible', isCorrect: false },
//         { answerText: 'None of the above', isCorrect: false }
//       ]
//     }
//   ]

//   const [currentQuestion, setCurrentQuestion] = useState(0)
//   const [showScore, setShowScore] = useState(false)
//   const [score, setScore] = useState(0)

//   const handleAnswerOptionClick = isCorrect => {
//     if (isCorrect) {
//       setScore(score + 1)
//     }

//     const nextQuestion = currentQuestion + 1
//     if (nextQuestion < questions.length) {
//       setCurrentQuestion(nextQuestion)
//     } else {
//       setShowScore(true)
//     }
//   }

//   useEffect(() => {
//     const data = JSON.parse(localStorage.getItem('quiz'))
//     if (data) {
//       console.log(`🚀  ~ file: Quizplay.js:77 ~ useEffect ~ data.questions:`, data.questions)
//     }
//   }, [])

//   return (
//     <div className='quizaap'>
//       <img src='/images/academy.png' />
//       <div className='app'>
//         {showScore ? (
//           <div className='score-section'>
//             {score === 4 ? (
//               <>
//                 Congratulations !! You successfully made it{' '}
//                 <a style={{ color: '#fff' }} href='/certificate.png' download>
//                   Download Certificate
//                 </a>
//               </>
//             ) : (
//               <>
//                 You scored {score} out of {questions.length}
//                 <button onClick={() => window.history.back()}>Back to the Course</button>
//               </>
//             )}
//           </div>
//         ) : (
//           <>
//             <div className='question-section'>
//               <div className='question-count'>
//                 <span>Question {currentQuestion + 1}</span>/{questions.length}
//               </div>
//               <div className='question-text'>{questions[currentQuestion].questionText}</div>
//             </div>
//             <div className='answer-section'>
//               {questions[currentQuestion].answerOptions.map((answerOption, index) => (
//                 <button onClick={() => handleAnswerOptionClick(answerOption.isCorrect)} key={index + 'questions'}>
//                   {answerOption.answerText}
//                 </button>
//               ))}
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   )
// }

export default function QuizPlay() {
  const [currentQuestion, setCurrentQuestion] = useState(0)

  const [showScore, setShowScore] = useState(false)
  const [score, setScore] = useState(0)
  const { width } = useWindowDimensions()
  const [seconds, setSeconds] = useState(300) // 5 minutes in seconds

  const [answerData, setAnswerData] = useState(null)

  const [clickedOptions, setClickedOptions] = useState(null)
  const [stopClick, setStopClick] = useState(true)

  const handleAnswerOptionClick = (isCorrect, index, answerIndex) => {
    setStopClick(false)

    const updatedClickedOptions = [...clickedOptions]
    updatedClickedOptions[index] = true
    updatedClickedOptions[answerIndex] = true
    setClickedOptions(updatedClickedOptions)

    // Create a copy of clickedOptions for use inside setTimeout
    const clickedOptionsCopy = [...updatedClickedOptions]

    setTimeout(() => {
      const nextQuestion = currentQuestion + 1
      if (nextQuestion < answerData.length) {
        setCurrentQuestion(nextQuestion)
      } else {
        setShowScore(true)
      }

      if (isCorrect === answerData[currentQuestion]?.answer) {
        setScore(score + 1)
      }
      setClickedOptions([...clickedOptionsCopy])
      setStopClick(true)
    }, 2000)
  }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('quiz'))

    if (data) {
      // Input time in minutes as a string
      const inputTime = data.time

      // Parse the input time using moment
      const parsedTime = moment(inputTime, 'mm:ss')

      // Get the total seconds by converting minutes to seconds
      const totalSeconds = parsedTime.minutes() * 60
      setSeconds(totalSeconds)
      const modifiedArray = data.finalQuestionArr.map(item => {
        const options = [item.option1, item.option2, item.option3, item.option4].filter(Boolean)
        return {
          question: item.question,
          answer: item.answer,
          options: options,
          type: item.type,
          date: item.date,
          _id: item._id
        }
      })

      setAnswerData(modifiedArray)
      setClickedOptions(Array(modifiedArray[currentQuestion].options.length).fill(false))
    }
  }, [])

  useEffect(() => {
    if (answerData) {
      setClickedOptions(Array(answerData[currentQuestion].options.length).fill(false))
    }
  }, [currentQuestion])

  const scoreSendToApi = async () => {
    const data = JSON.parse(localStorage.getItem('quiz'))
    const body = { score: score, chapterid: data.chapterid, quizid: data._id, question: answerData.length }
    await callApi({
      uriEndPoint: quizEndpoint,
      body
    })
      .then(() => {
        window.history.back()
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => prevSeconds - 1)
      } else {
        // When the countdown reaches 0, show an alert
        clearInterval(interval) // Clear the interval to stop the countdown
        setShowScore(true)
      }
    }, 1000)

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval)
  }, []) // Include seconds in the dependency array to re-run the effect when seconds change

  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  if (answerData)
    return (
      <div className='quizaap'>
        <img src='/images/academy.png' />
        <div style={{ marginBottom: '0.5rem' }}>
          Time Remaining: {minutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
        </div>
        <div className='app' style={{ display: width > 992 ? 'flex' : 'block', width: width > 992 ? '450px' : 'auto' }}>
          {showScore ? (
            <div className='score-section'>
              {score ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  Congratulations !! You successfully made it You scored {score} out of {answerData.length}
                  <button style={{ justifyContent: 'center', marginTop: '1rem' }} onClick={() => scoreSendToApi()}>
                    Continue Next Chapter
                  </button>
                </div>
              ) : (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    Congratulations !! You successfully made it You scored {score} out of {answerData.length}
                    <button style={{ justifyContent: 'center', marginTop: '1rem' }} onClick={() => scoreSendToApi()}>
                      Continue Next Chapter
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <div className='question-section'>
                <div className='question-count'>
                  <span>Question {currentQuestion + 1}</span>/{answerData.length}
                </div>
                <div className='question-text'>{answerData[currentQuestion]?.question}</div>
              </div>
              <div className='answer-section' style={{ gap: '1rem' }}>
                {answerData[currentQuestion].options.map((i, index) => {
                  const isCorrect = i === answerData[currentQuestion].answer

                  const answerIndex = answerData[currentQuestion].options.findIndex(
                    option => option === answerData[currentQuestion].answer
                  )

                  let buttonClass = clickedOptions[index]
                    ? isCorrect
                      ? 'green'
                      : 'red' // If clicked, turn green if correct, red if wrong
                    : '#252d4a' // Default blue color // Default blue color

                  return (
                    <button
                      style={{ backgroundColor: buttonClass }}
                      onClick={() => (stopClick ? handleAnswerOptionClick(i, index, answerIndex) : null)}
                    >
                      {i}
                    </button>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </div>
    )
}
