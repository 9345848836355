import React from 'react'

export default function DesktopNavBar() {
  const user = localStorage.getItem('mongoId')
  return (
    <>
      <marquee behavior='scroll' direction='left' style={{ fontFamily: " 'Jost',sans-serif", color: 'orangered' }}>
        It is the only e-learning program in the world giving exclusive education in the subject of Organon of Medicine
        and Homeopathic Philosophy.
      </marquee>
      {/* <marquee behavior='scroll' direction='left' style={{ fontFamily: " 'Jost',sans-serif", color: 'orangered' }}>
        USE CODE <b className='blink-hard'>SHAH30</b> To Avail <b className='blink-hard'>30%</b> Discount on your
        courses on the occasion of Diwali and New Year Celebrations!
      </marquee> */}

      <div className='follow-us-desktop' style={{ paddingTop: '3px' }}>
        <div className='attendwebinar'>
          <a href='/freewebinar'>Free Webinars</a>
          <span style={{ marginLeft: '8px' }}>
            <a href='/paidwebinar'>Paid Webinars</a>
          </span>
          <span style={{ marginLeft: '8px' }}>
            <a href='/qawithshah'>Organon with SHAH</a>
          </span>
        </div>
        {/* <div className='web-app'>
          Download App
          <a href='' target='__blank'>
            <i className='fa fa-apple' aria-hidden='true'></i>
          </a>{' '}
          |
          <a href='' target='__blank'>
            <i className='fa fa-android' aria-hidden='true'></i>
          </a>
        </div> */}

        <div className='follow-us'>
          <div className='title'>
            <a href='tel:+917567756443' style={{ cursor: 'pointer', textDecoration: 'none' }}>
              <i className='fa fa-phone'></i>
              <span>+917567756443</span>
            </a>
            <a href='mailto:organon@samuelhahnemann.org' style={{ cursor: 'pointer', textDecoration: 'none' }}>
              <i className='fa fa-envelope'></i>
              <span>organon@samuelhahnemann.org</span>
            </a>
          </div>
        </div>
        {/* <div className='follow-us'>
          <div className='title'>
            Follow us :{' '}
            <a href='https://www.facebook.com/samuelhahnemannacademy/' target='__blank'>
              <i className='fa fa-facebook'></i>
            </a>
            <a href='https://www.linkedin.com/company/shahacademy/' target='__blank'>
              <i className='fa fa-linkedin'></i>
            </a>
            <a href='https://www.instagram.com/samuelhahnemannacademy' target='__blank'>
              <i className='fa fa-instagram'></i>
            </a>
            <a href='https://www.youtube.com/channel/UC8vuW0lJO5zNOW6eRhJQOvg' target='__blank'>
              <i className='fa fa-youtube'></i>
            </a>
          </div>
        </div> */}
      </div>
      <div className='nv'>
        <img
          onClick={() => (window.location.href = '/')}
          style={{ cursor: 'pointer' }}
          src='/shahlogo.svg'
          alt='shahedu.in'
        />
        <div className='signup-btn'>
          <a href={user ? '/profile' : '/signup'} className='button' role='button'>
            <span className='elementor-button-text'>{user ? 'My Account' : 'Join Now'}</span>
          </a>

          <a
            href='/login'
            style={{
              marginLeft: 12,
              color: '#000',
              textDecoration: 'none',
              opacity: user ? 0 : 1,
              fontFamily: " 'Jost', sans-serif "
            }}
          >
            Login{' '}
          </a>
        </div>
      </div>

      <div className='line'></div>

      <div className='deskNav'>
        <ul className='navItem'>
          <li>
            <a
              href='#'
              style={{
                color:
                  window.location.pathname === '/digitalorganon'
                    ? 'orangered'
                    : window.location.pathname === '/Theory1'
                    ? 'orangered'
                    : window.location.pathname === '/Practical1'
                    ? 'orangered'
                    : window.location.pathname === '/Practical2'
                    ? 'orangered'
                    : window.location.pathname === '/Practical3'
                    ? 'orangered'
                    : window.location.pathname === '/Totalpractical'
                    ? 'orangered'
                    : window.location.pathname === '/Fulldigital'
                    ? 'orangered'
                    : '#000'
              }}
            >
              <i>
                <span style={{ color: 'orangered' }}>Digital </span>Organon
              </i>{' '}
              <i className='fa fa-chevron-down'></i>
            </a>
            <ul>
              <li>
                <a
                  href='/digitalorganon'
                  style={{
                    color: window.location.pathname === '/digitalorganon' ? 'orangered' : '#000'
                  }}
                >
                  Dr. Hahnemann’s Digital <br />
                  Organon of Medicine
                </a>
              </li>
              <li>
                <a
                  href='/Theory1'
                  style={{
                    color: window.location.pathname === '/Theory1' ? 'orangered' : '#000'
                  }}
                >
                  Theory Part – 1 (§ 1 to 70)
                </a>
              </li>
              <li>
                <a
                  href='/Practical1'
                  style={{
                    color: window.location.pathname === '/Practical1' ? 'orangered' : '#000'
                  }}
                >
                  Practical Part – 1 (§ 71 to 104)
                </a>
              </li>
              <li>
                <a
                  href='/Practical2'
                  style={{
                    color: window.location.pathname === '/Practical2' ? 'orangered' : '#000'
                  }}
                >
                  Practical Part – 2 (§ 105 to 145)
                </a>
              </li>
              <li>
                <a
                  href='/Practical3'
                  style={{
                    color: window.location.pathname === '/Practical3' ? 'orangered' : '#000'
                  }}
                >
                  Practical Part – 3 (§ 146 to 294)
                </a>
              </li>
              <li>
                <a
                  href='/Totalpractical'
                  style={{
                    color: window.location.pathname === '/Totalpractical' ? 'orangered' : '#000'
                  }}
                >
                  Total Practical Part (§ 71 to 294)
                </a>
              </li>
              <li>
                <a
                  href='/Fulldigital'
                  style={{
                    color: window.location.pathname === '/Fulldigital' ? 'orangered' : '#000'
                  }}
                >
                  Full Digital Organon (§ 1 to 294)
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href='#'
              style={{
                color:
                  window.location.pathname === '/accesstosuccess'
                    ? 'orangered'
                    : window.location.pathname === '/Basic'
                    ? 'orangered'
                    : window.location.pathname === '/Complete'
                    ? 'orangered'
                    : window.location.pathname === '/Advanced'
                    ? 'orangered'
                    : window.location.pathname === '/Prescription'
                    ? 'orangered'
                    : window.location.pathname === '/Prescription'
                    ? 'orangered'
                    : window.location.pathname === '/Acute'
                    ? 'orangered'
                    : window.location.pathname === '/Total'
                    ? 'orangered'
                    : '#000'
              }}
            >
              <i style={{ fontStyle: 'normal' }}>Access to Success</i> <i className='fa fa-chevron-down'></i>
            </a>
            <ul>
              <li>
                <a
                  href='/accesstosuccess'
                  style={{
                    color: window.location.pathname === '/accesstosuccess' ? 'orangered' : '#000'
                  }}
                >
                  Organon – Access to Success
                  <br /> Course Details
                </a>
              </li>
              <li>
                <a
                  href='/Basic'
                  style={{
                    color: window.location.pathname === '/Basic' ? 'orangered' : '#000'
                  }}
                >
                  Basic Access
                </a>
              </li>
              <li>
                <a
                  href='/Complete'
                  style={{
                    color: window.location.pathname === '/Complete' ? 'orangered' : '#000'
                  }}
                >
                  Complete Access
                </a>
              </li>
              <li>
                <a
                  href='/Advanced'
                  style={{
                    color: window.location.pathname === '/Advanced' ? 'orangered' : '#000'
                  }}
                >
                  Advanced Access
                </a>
              </li>
              <li>
                <a
                  href='/Prescription'
                  style={{
                    color: window.location.pathname === '/Prescription' ? 'orangered' : '#000'
                  }}
                >
                  Prescription Access
                </a>
              </li>
              {/* <li>
                <a
                  href="/Basic"
                  style={{
                    color:
                      window.location.pathname === "/Basic"
                        ? "orangered"
                        : "#000",
                  }}
                >
                  Remedy Reaction Access
                </a>
              </li> */}
              <li>
                <a
                  href='/Acute'
                  style={{
                    color: window.location.pathname === '/Acute' ? 'orangered' : '#000'
                  }}
                >
                  Acute Access
                </a>
              </li>
              <li>
                <a
                  href='/Total'
                  style={{
                    color: window.location.pathname === '/Total' ? 'orangered' : '#000'
                  }}
                >
                  Total Access
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a
              href='#'
              style={{
                color:
                  window.location.pathname === '/beahomeopath'
                    ? 'orangered'
                    : window.location.pathname === '/Dangerousfuture'
                    ? 'orangered'
                    : window.location.pathname === '/Femalehomeopathy'
                    ? 'orangered'
                    : window.location.pathname === '/beahomeopath'
                    ? 'orangered'
                    : window.location.pathname === '/beahomeopath'
                    ? 'orangered'
                    : '#000'
              }}
            >
              <i style={{ fontStyle: 'normal' }}>Be a Homeopath</i> <i className='fa fa-chevron-down'></i>
            </a>
            <ul>
              <li>
                <a
                  href='/beahomeopath'
                  style={{
                    color: window.location.pathname === '/beahomeopath' ? 'orangered' : '#000'
                  }}
                >
                  Who Wants to be a Homeopath OR Kaun Banega Homeopath ?
                </a>
              </li>
              <li>
                <a
                  href='/Dangerousfuture'
                  style={{
                    color: window.location.pathname === '/Dangerousfuture' ? 'orangered' : '#000'
                  }}
                >
                  Dangerous Future of Allopathic (GP) Practice for a Qualified Homeopath
                </a>
              </li>
              <li>
                <a
                  href='/Femalehomeopathy'
                  style={{
                    color: window.location.pathname === '/Femalehomeopathy' ? 'orangered' : '#000'
                  }}
                >
                  Female & Homeopathic Career – Responsibilities, Opportunities & Management
                </a>
              </li>
              {/* <li>
                <a
                  href="/beahomeopath"
                  style={{
                    color:
                      window.location.pathname === "/beahomeopath"
                        ? "orangered"
                        : "#000",
                  }}
                >
                  Answers of Clinical FAQs asked by Patients
                </a>
              </li>
              <li>
                <a
                  href="/beahomeopath"
                  style={{
                    color:
                      window.location.pathname === "/beahomeopath"
                        ? "orangered"
                        : "#000",
                  }}
                >
                  Symptomatology – A Homeopathic Perspective
                </a>
              </li> */}
            </ul>
          </li>
          <li>
            <a
              href='#'
              style={{
                color:
                  window.location.pathname === '/shah'
                    ? 'orangered'
                    : window.location.pathname === '/drkrutikshah'
                    ? 'orangered'
                    : window.location.pathname === '/team'
                    ? 'orangered'
                    : window.location.pathname === '/felicitations'
                    ? 'orangered'
                    : window.location.pathname === '/scholarships'
                    ? 'orangered'
                    : window.location.pathname === '/ceremony'
                    ? 'orangered'
                    : '#000'
              }}
            >
              <i style={{ fontStyle: 'normal' }}>About</i> <i className='fa fa-chevron-down'></i>
            </a>
            <ul>
              <li>
                <a
                  href='/shah'
                  style={{
                    color: window.location.pathname === '/shah' ? 'orangered' : '#000'
                  }}
                >
                  SHAH
                </a>
              </li>
              <li>
                <a
                  href='/drkrutikshah'
                  style={{
                    color: window.location.pathname === '/drkrutikshah' ? 'orangered' : '#000'
                  }}
                >
                  Dr. Krutik Shah
                </a>
              </li>
              <li>
                <a
                  href='/team'
                  style={{
                    color: window.location.pathname === '/team' ? 'orangered' : '#000'
                  }}
                >
                  Team @ SHAH
                </a>
              </li>
              <li>
                <a
                  href='/scholarships'
                  style={{
                    color: window.location.pathname === '/scholarships' ? 'orangered' : '#000'
                  }}
                >
                  <i style={{ fontStyle: 'normal' }}>Scholarships @ SHAH</i>
                </a>
              </li>
              <li>
                <a
                  href='/ceremony'
                  style={{
                    color: window.location.pathname === '/ceremony' ? 'orangered' : '#000'
                  }}
                >
                  Online Inaugural Ceremony
                </a>
              </li>
              <li>
                <a
                  href='/felicitations'
                  style={{
                    color: window.location.pathname === '/felicitations' ? 'orangered' : '#000'
                  }}
                >
                  Post Inaugural Felicitations
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a
              href='/FreeTalks'
              style={{
                color: window.location.pathname === '/FreeTalks' ? 'orangered' : '#000'
              }}
            >
              <i style={{ fontStyle: 'normal' }}>FreeTalks</i>
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}
