import React, { useState, useEffect } from 'react'
import axios from 'axios'
export default function CardComponent(props) {
  const [Chapters, setChapters] = useState([])

  const [Lessons, setLessons] = useState('')
  const [alert, setAlert] = useState(false)
  useEffect(() => {
    getLessons()

    getChapters()
  }, [])

  async function getChapters() {
    await axios
      .post('/api/course/all-chapters', {
        id: props.chapters,
        body: 'front-end-api'
      })
      .then(res => {
        //   console.log(res)
        setChapters(res.data)
      })
      .catch(e => alert(e))
  }

  async function getLessons() {
    await axios
      .get('/api/course/all-course-lessons/' + props.chapters + '?type=front-end-api')
      .then(res => {
        let v = 0

        for (let i = 0; res.data.length > i; i++) {
          const scan = res.data[i]
          v = v + scan.length
        }
        setLessons(v)
      })
      .catch(e => console.log(e))
  }

  return (
    <>
      {alert === true ? (
        <div className='alert-box-apps'>
          <div className='alert-container'>
            <div className='title'>Coming Soon</div>
            <div className='paragraph'>This course is coming soon </div>
            <div className='button' onClick={() => setAlert(false)}>
              Close
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        className='card'
        onClick={() =>
          props.published === true
            ? props.web === true
              ? (window.location.href = `/${props.slug}`)
              : (window.location.href = `/${props.slug}`)
            : setAlert(true)
        }
      >
        <div className='image'>
          <img src={props.Img} />
        </div>
        <div className='title-card' style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
          <span style={{ color: 'black', fontSize: '15px' }}>
            <b>{props.nameOfCourse}</b>
          </span>
          <span>{props?.one_line}</span>
        </div>
        <div className='contents'>
          {Chapters?.length} Chapters | {Lessons} Lessons |{props?.TotalDuration}
        </div>
      </div>
    </>
  )
}
