import React, { Suspense, lazy } from 'react'

import '../styles/nav.scss'

import useWindowDimesions from '../components/useWindowDimesions'
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'

const Footer = lazy(() => import('../components/Footer'))

const Hero = lazy(() => import('../components/Hero'))

const MobileNavBar = lazy(() => import('../components/MobileNavBar'))

const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))

// const ScholarSlider = lazy(() => import('../components/ScholarSlider'))

const ScholarshipShah = lazy(() => import('./ScholarshipShah'))

const Scholar = () => {
  // const [open, isOpen] = useState(false)
  const { width } = useWindowDimesions()
  // const pages = ['Home', 'About', 'Courses', 'News &  Media', 'Why Study at SHAH?']

  if (width < 999) {
    return (
      <>
        <Helmet>
          <title>Organon with SHAH | Samuel Hahnemann Academy for Homeopathy - Scholarships</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organon with SHAH',
              name: 'Organon with SHAH | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <MobileNavBar />
          <Hero title='Scholarships @ SHAH' img='/images/WhyStudy@SHAH.jpg' />
          <ScholarshipShah />

          <Footer />
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organon with SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Organon with SHAH | Samuel Hahnemann Academy for Homeopathy - Scholarships</title>
          <meta
            name='description'
            content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organon with SHAH',
              name: 'Organon with SHAH | Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/'
              // Add more structured data properties
            })}
          </script>
        </Helmet>
        <Suspense fallback={<SplashScreen />}>
          <div className='desktop'>
            <DesktopNavBar />
            <Hero title='Scholarships @ SHAH' img='/images/WhyStudy@SHAH.jpg' />
            {/* nil */}
            {/* <ScholarSlider /> */}
            <ScholarshipShah />
            <Footer />
          </div>
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organon with SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  }
}

export default Scholar
