import React, { useEffect, useState } from 'react'
import { callApi } from '../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const viewTransactions = {
  ...defaults.methods.GET,
  uri: '/api/order/buy-course-list'
}

const viewTransactionsPdf = {
  ...defaults.methods.GET,
  uri: '/api/user/generate-invoice/:id'
}

export default function Transactions() {
  const [responseData, setResponseData] = useState([])

  // const id = localStorage.getItem('userId')
  const id = localStorage.getItem('mongoId')

  const transactionsData = async () => {
    if (id) {
      await callApi({ uriEndPoint: viewTransactions, query: { studentid: id } })
        .then(response => {
          setResponseData(response?.data)
        })
        .catch(e => console.log(e))
    }
  }

  const handleDownloadClick = async id => {
    await callApi({ uriEndPoint: viewTransactionsPdf, pathParams: { id: id } })
      .then(res => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}` + `/${res.data}`
        setTimeout(() => {
          window.open(url, '_blank')
        }, 2000)
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    transactionsData()
  }, [])

  return (
    <>
      <div className='one-one'>
        <h3 className='table-header'>My Transactions</h3>

        <div className='container transactions-container'>
          <table className='rwd-table'>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Payment</th>
                <th>Download</th>
              </tr>
              {responseData?.map((item, index) => (
                <tr key={index}>
                  <td data-th='Name'>{item.coursename}</td>
                  <td data-th='Date'>{item.date}</td>
                  <td data-th='Payment'>₹ {item.amount}</td>
                  <td data-th='Download'>
                    <button className='download-button' onClick={() => handleDownloadClick(item.studentid)}>
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
