import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './index.scss'
import { useParams } from 'react-router-dom'
import DangerousHTML from 'react-dangerous-html'
import { ShowMyChapters, ShowMyCoursePageLessons, ShowMyCoursePageQuiz } from '../../components/ShowMyChapters'
import GridComponent from './GridComponent'
import AppChapterDropdown from './AppChapterDropdown'
import { app } from '../../config/firebase'

function OpenRazorPay(pay, coursename) {
  const test = process.env.REACT_APP_RAZOR_PAY_KEY
  // const prod = 'rzp_live_lzls0OUbHfd9IC'

  var options = {
    key: `${test}`, // Enter the Key ID generated from the Dashboard
    amount: `${pay * 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: 'INR',
    name: 'E-Learning @ SHAH ',
    description: `For buying this ${coursename} course`,
    handler: () => {
      alert('Payment success')
      localStorage.setItem('coursename', coursename.toString())
      window.location.href = '/purchased-course'
    },

    notes: {
      address: 'Samuel Hahnemann Academy for Homeopathy - SHAH, Anand - 388001 , Gujarat, India'
    },
    theme: {
      color: '#FF5722'
    }
  }

  const rzp1 = new window.Razorpay(options)
  rzp1.open()
}

function OpenRazorPayU(pay, coursename) {
  const test = process.env.REACT_APP_RAZOR_PAY_KEY
  // const prod = 'rzp_live_lzls0OUbHfd9IC'

  var options = {
    key: `${test}`, // Enter the Key ID generated from the Dashboard
    amount: `${pay * 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: 'USD',
    name: 'E-Learning @ SHAH ',
    description: `For buying this ${coursename} course`,
    handler: () => {
      localStorage.setItem('coursename', coursename.toString())
      window.location.href = '/purchased-course'
    },

    notes: {
      address: 'Samuel Hahnemann Academy for Homeopathy - SHAH, Anand - 388001 , Gujarat, India'
    },
    theme: {
      color: '#FF5722'
    }
  }

  const rzp1 = new window.Razorpay(options)
  rzp1.open()
}
export default function AppCoursePage() {
  const [category, setCategory] = useState('')
  const [categoryError, setCategoryError] = useState(false)
  const [tab, setTab] = useState('Overview')
  const [Data, setData] = useState([])

  const [modalIsOpen, setIsOpen] = useState(false)
  const [checkedBuyCourse, setCheckedBuyCourse] = useState(null)
  let { slug } = useParams()

  useEffect(() => {
    FetchData()
    getData()
  }, [])

  async function getData() {
    // const id = localStorage.getItem('userId') + ''
    const id = localStorage.getItem('mongoId') + ''

    const buyCourseSlugData = localStorage.getItem('buyCourses')

    console.log('this is app course page buyCourseSlugData', buyCourseSlugData)
    await app
      .firestore()
      .collection('users')
      .doc(id)
      .collection('course')
      .get()
      .then(querySnapshot => {
        const tempDoc = querySnapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })
        let checkValue = tempDoc.some(item => item.slug === slug)

        setCheckedBuyCourse(checkValue)
      })
  }
  async function FetchData() {
    console.log(`🚀  ~ file: AppCoursePage.js:66 ~ AppCoursePage ~ AppCoursePage:`)
    await axios
      .get('/api/course?type=front-end-api')
      .then(res => {
        const FilterCourse = res.data.courses.filter(r => r.slug === slug)
        setData(FilterCourse)
      })
      .catch(e => alert(e))
  }

  function PayButton(payment, paymentD, coursename) {
    const fireid = '122323232'
    // const fireid = localStorage.getItem('loginUser');
    if (category === 'Indian Students Only') {
      OpenRazorPay(payment, coursename, fireid)
    }
    if (category === 'International Students & Homeopathic Practitioner') {
      OpenRazorPayU(paymentD, coursename, fireid)
    }
    if (category === 'error') {
      setCategoryError(true)
      setCategory('error')
    }
    if (category === '') {
      setCategoryError(true)
      setCategory('error')
    }
  }

  function openModal(e) {
    localStorage.setItem('overview', e)
    setIsOpen(true)
  }

  return Data.map(res => (
    <>
      {categoryError === false ? (
        <></>
      ) : (
        <div className='alert-box-app'>
          <div className='alert-container'>
            <div className='title'>Choose your category</div>
            <div className='paragraph'>Please choose a category to buy this course.</div>
            <div className='button' onClick={() => setCategoryError(false)}>
              Ok
            </div>
          </div>
        </div>
      )}
      <div className='app-course-detail'>
        <div className='course-video'>
          <div className='back-btn' style={{ display: 'none' }} onClick={() => window.history.back()}>
            <i className='fa fa-arrow-left' aria-hidden='true' />
          </div>
          <div className='video' style={{ backgroundColor: '#000' }} onClick={() => openModal(res.overview)}>
            {modalIsOpen === false ? (
              <>
                <div className='black-screen' />
                <img
                  src={
                    res.courseOverviewThumb
                      ? res.courseOverviewThumb
                      : 'https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg'
                  }
                />
              </>
            ) : (
              <video
                autoPlay={true}
                controls
                controlsList='nodownload'
                style={{
                  height: 'auto',
                  width: '100%',
                  backgroundColor: '#000',
                  paddingTop: '2.3em',
                  paddingBottom: '2.3em'
                }}
              >
                <source
                  src={
                    res.awsoverview
                      ? res.awsoverview
                      : 'https://shahwebsite.s3.ap-south-1.amazonaws.com/Theory+Part+-+1/1.mp4'
                  }
                  type='video/mp4'
                ></source>
              </video>
            )}
          </div>
        </div>
        <div className='app-course-detail-content'>
          <div className='navigation-app-course-detail'>
            <div className='tabs'>
              <div className={tab === 'Overview' ? 'tab active-tab' : 'tab'} onClick={() => setTab('Overview')}>
                Overview
                {tab === 'Overview' ? <div className='border'></div> : <></>}
              </div>
              <div className={tab === 'Curriculum' ? 'tab active-tab' : 'tab'} onClick={() => setTab('Curriculum')}>
                Curriculum {tab === 'Curriculum' ? <div className='border'></div> : <></>}
              </div>
              <div className={tab === 'Reviews' ? 'tab active-tab' : 'tab'} onClick={() => setTab('Reviews')}>
                Reviews {tab === 'Reviews' ? <div className='border'></div> : <></>}
              </div>
            </div>
          </div>

          {tab === 'Overview' ? (
            <>
              <div className='app-course-title'>{res.nameOfCourse}</div>
              <div className='app-course-index-contents'>
                <ShowMyChapters id={res._id} /> Chapters | <ShowMyCoursePageLessons id={res._id} /> Lessons |{' '}
                <ShowMyCoursePageQuiz id={res._id} /> Quiz | {res.notes} E-Learning Notes
              </div>
              <div className='pricing'>
                <div
                  className={
                    category === 'error'
                      ? 'price-box error-pricing'
                      : category === 'Indian Students Only'
                      ? 'price-box active-pricing'
                      : 'price-box'
                  }
                  onClick={() => setCategory('Indian Students Only')}
                >
                  <div className='title'>Indian Students</div>
                  <div className='price-tabel'>
                    <div className='actual-price'>₹{res.SellingPrice}/-</div>
                    <div className='market-price'>₹{res.RegularPrice}/-</div>
                  </div>
                </div>
                <div
                  className={
                    category === 'error'
                      ? 'price-box error-pricing'
                      : category === 'International Students & Homeopathic Practitioner'
                      ? 'price-box active-pricing'
                      : 'price-box'
                  }
                  onClick={() => setCategory('International Students & Homeopathic Practitioner')}
                >
                  <div className='title' style={{ fontSize: '10px' }}>
                    Homeopathic Practitioners &amp; International Students
                  </div>
                  <div className='price-tabel'>
                    <div className='actual-price'>${res.DollarSellingPrice}/-</div>
                    <div className='market-price'>${res.DollarRegularPrice}/-</div>
                  </div>
                </div>
              </div>
              <div className='app-line' />
              <div className='app-course-title'>
                <div className='title'>Overview</div>
                <div className='contents'>
                  <DangerousHTML html={res.description} />
                </div>
              </div>
              <div className='download-app-curriculum'>
                <a href='/' className='title' download>
                  Download Curriculum
                </a>
              </div>
              <div className='app-course-title feature-course-app'>
                <div className='title'>Course Feature</div>
                <div className='contents'>
                  <ul>
                    <li>Course Certification : Fellowship</li>
                    <li>Final Exam : FAQ No. 14 & 15</li>
                    <li>Badge of Honour : {res.BadgeHonor}</li>
                    <li>Live with Dr.Shah : {res.liveShah}</li>
                    <li>Watch Course : {res.WatchCourse}</li>
                    <li>Course Access: {res.CourseAccess}</li>
                    <li>Chat with Mentor : {res.ChatwithCoach}</li>
                    <li>Total Duration : {res.TotalDuration}</li>
                    <li>Language : {res.Language}</li>
                    <li>Skill level: {res.SkillLevel}</li>
                  </ul>
                </div>
              </div>
              <div className='app-tutor'>
                <div className='instructor'>
                  <div className='image'>
                    <img src='/drkrutikshah.png' />
                  </div>
                  <div className='contents'>
                    <div className='title'>Dr. Krutik Shah</div>
                    <div className='sub-title'>Mentor</div>
                  </div>
                </div>
                <div className='instructor'>
                  <div className='image'>
                    <img src={res.tutorImg} />
                  </div>
                  <div className='contents'>
                    <div className='title'>{res.tutorName}</div>
                    <div className='sub-title'>Mentor</div>
                  </div>
                </div>
              </div>
              <div className='app-course-title' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='title' style={{ fontSize: '1.3rem' }}>
                  Related Course
                </div>
                <GridComponent
                  name={res.nameOfCourse}
                  url='/shahlearnings/m/kbh'
                  title='Kaun Banega Homeopath'
                  category={res.category}
                />
              </div>
              <div className='app-course-buy-button'>
                <div
                  className='btn'
                  onClick={() => PayButton(res.SellingPrice, res.DollarSellingPrice, res.nameOfCourse)}
                >
                  Buy this Course
                </div>
              </div>
            </>
          ) : tab === 'Curriculum' ? (
            <>
              <div style={{ height: '100vh' }}>
                <AppChapterDropdown
                  slug={slug}
                  checkedBuyCourse={checkedBuyCourse}
                  courseId={res._id}
                  myChapters={<ShowMyChapters id={res._id} />}
                  myLessons={<ShowMyCoursePageLessons id={res._id} />}
                  totalTime={res.TotalDuration}
                />
              </div>
              <div className='app-course-buy-button'>
                <div
                  className='btn'
                  onClick={() => PayButton(res.SellingPrice, res.DollarSellingPrice, res.nameOfCourse)}
                >
                  Buy this Course
                </div>
              </div>
            </>
          ) : tab === 'Reviews' ? (
            <>
              <div style={{ height: '100vh' }}>
                <div className='app-course-title app-index-review'>
                  <div className='review-card'>
                    <div className='review-card-container'>
                      <div className='top-row'>
                        <div className='image'></div>
                        <div className='author'>
                          <div className='author-name'>Danish</div>
                          <div className='author-category'>Student</div>
                        </div>
                        <div className='rating'>
                          5<i className='fa fa-star' aria-hidden='true'></i>
                        </div>
                      </div>
                      <div className='bottom'>
                        <p>
                          One advanced diverted domestic repeated bringing you old. Possible procured her trifling
                          laughter thoughts property she met way.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='app-course-buy-button'>
                <div
                  className='btn'
                  onClick={() => PayButton(res.SellingPrice, res.DollarSellingPrice, res.nameOfCourse)}
                >
                  Buy this Course
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  ))
}
