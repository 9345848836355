import React from 'react'
import '../styles/splash.scss'

export default function SplashScreen() {
  return (
    <div className='SplashScreen'>
      <img src='/shahlogo.svg' />
      <div data-load='loading'></div>
    </div>
  )
}
