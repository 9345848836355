import React from 'react'
import './index.scss'
import GridComponent from '../GridComponent'
export default function DigitalOrganonApp() {
  return (
    <>
      <div className='app-course-index'>
        <div className='app-nav-course-index'>
          <div className='back-btn' onClick={() => window.history.back()}>
            <i className='fa fa-arrow-left' aria-hidden='true'></i>
          </div>
          <div className='course-app-title'>
            <div>Dr. Hahnemann Digital Organon of Medicine</div>
          </div>
        </div>

        <div className='video-shah-app'>
          <iframe
            width={780}
            height={440}
            src='https://www.youtube.com/embed/T1zLhbdAZZ4'
            title='YouTube video player'
            frameBorder={0}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
          <div
            className='web-course'
            onClick={() => (window.location.href = 'https://www.samuelhahnemann.org/digitalorganon')}
          >
            Read More About this Course Series
          </div>
        </div>

        <GridComponent title='Dr. Hahnemann Digital Organon of Medicine' category='digitalorganon' />
      </div>
    </>
  )
}
