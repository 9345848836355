import React, { Suspense, lazy, useEffect, useState } from 'react'
import useWindowDimesions from '../components/useWindowDimesions'
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'

const Footer = lazy(() => import('../components/Footer'))

const MobileNavBar = lazy(() => import('../components/MobileNavBar'))

const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))

const AllChapterPage = lazy(() => import('./AllChapter'))

import { callApi } from '../utils/api-utils'
import { useParams } from 'react-router-dom'
import { ChapterView } from './AllChapter/view'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    }
  }
}
// 60968a377e0921220d7cfdb8
const viewChapter = {
  ...defaults.methods.GET,
  uri: '/api/course/chapter-next-previous/:id'
}

const ViewChapterPage = () => {
  let { id } = useParams()
  const { width } = useWindowDimesions()
  const [viewChapterData, setViewChapterData] = useState(null)

  const allChapterApiCall = async () => {
    try {
      await callApi({
        uriEndPoint: viewChapter,
        pathParams: { id: id }
      }).then(res => {
        if (res) setViewChapterData(res)
      })
    } catch (error) {
      console.log(`🚀  ~ file: chapter-view.js:46 ~ allChapterApiCall ~ error:`, error)
    }
  }

  useEffect(() => {
    allChapterApiCall()
  }, [])

  if (viewChapterData)
    if (width < 799) {
      return (
        <>
          <Helmet>
            <title>E-Learning @ SHAH - Organon with SHAH</title>
            <meta
              name='description'
              content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
            />
            {/* Add more meta tags as needed */}
            <script type='application/ld+json'>
              {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'E-Learning @ SHAH',
                name: 'E-Learning @ SHAH - Free Webinar',
                url: 'https://www.samuelhahnemann.org/'
                // Add more structured data properties
              })}
            </script>
          </Helmet>
          <Suspense fallback={<SplashScreen />}>
            <MobileNavBar />
            <AllChapterPage />
            <Footer />
          </Suspense>
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'E-Learning @ SHAH',
              headline: 'E-Learning on My Website',
              datePublished: '2020-08-29',
              description:
                'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
              image: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 800,
                height: 600
              },
              author: {
                '@type': 'Person',
                name: 'Dr.Krutik Shah'
              },
              publisher: {
                '@type': 'Organization',
                name: 'Dr.Krutik Shah',
                logo: {
                  '@type': 'ImageObject',
                  url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                  width: 200,
                  height: 100
                }
              }
            })}
          </script>
        </>
      )
    } else {
      return (
        <>
          <Helmet>
            <title>E-Learning @ SHAH - Organon with SHAH</title>
            <meta
              name='description'
              content='Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy'
            />
            {/* Add more meta tags as needed */}
            <script type='application/ld+json'>
              {JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'E-Learning @ SHAH',
                name: 'E-Learning @ SHAH - Free Webinar',
                url: 'https://www.samuelhahnemann.org/'
                // Add more structured data properties
              })}
            </script>
          </Helmet>
          <Suspense fallback={<SplashScreen />}>
            <div className='desktop'>
              <DesktopNavBar />
              {/* <AllChapterPage /> */}
              <ChapterView viewChapterData={viewChapterData} />
              <div>
                <Footer />
              </div>
            </div>
          </Suspense>
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'E-Learning @ SHAH',
              headline: 'E-Learning on My Website',
              datePublished: '2020-08-29',
              description:
                'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
              image: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 800,
                height: 600
              },
              author: {
                '@type': 'Person',
                name: 'Dr.Krutik Shah'
              },
              publisher: {
                '@type': 'Organization',
                name: 'Dr.Krutik Shah',
                logo: {
                  '@type': 'ImageObject',
                  url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                  width: 200,
                  height: 100
                }
              }
            })}
          </script>
        </>
      )
    }
}

export default ViewChapterPage
