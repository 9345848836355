import moment from 'moment-timezone'
import React from 'react'

const CoachChat = ({
  sendMsg,
  chatViewData,
  chatValue,
  setChatValue,
  replyMsgData,
  fileAdd,
  myDivRef,
  messageReply,
  handleFileSelect,
  setFileAdd,
  fileInputRef,
  setReplyMsgData,
  handleFileChange,
  getMessages,
  spinner,
  width,
  closeChat,
  chatCloseState
}) => {
  const onsubmit = e => {
    e.preventDefault()
  }

  return (
    <div className={width < 992 ? (!chatCloseState ? 'chat-container' : 'chat-container-false') : 'chat-container'}>
      <div
        className='chat'
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '600px',
          backgroundColor: width < 992 ? 'white' : '#f4f4f4'
        }}
      >
        {Object.values(chatViewData).length !== 0 && (
          <div className='chat-header'>
            <h3 style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {chatViewData?.student_id?.legal_name?.toLocaleUpperCase() ||
                chatViewData?.first_name?.toLocaleUpperCase()}
              {width < 992 && (
                <i
                  class='fa fa-chevron-circle-left'
                  onClick={() => closeChat()}
                  style={{ cursor: 'pointer', color: 'white' }}
                ></i>
              )}
            </h3>
          </div>
        )}
        {spinner ? (
          <div
            style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src='/shahlogo.svg' style={{ width: '300px', height: '100px' }} />
          </div>
        ) : (
          Object.values(chatViewData).length !== 0 && (
            <div
              className='chat-messages'
              style={{
                minHeight:
                  width < 992
                    ? '448px'
                    : !fileAdd && !replyMsgData
                    ? '647px'
                    : fileAdd && replyMsgData
                    ? '548px'
                    : '574px',
                backgroundColor: '#0c1419'
              }}
              ref={myDivRef}
            >
              {getMessages?.messages?.map(i => {
                if (i?.file && i?.reply_msg) {
                  return (
                    <div
                      className={
                        i?.sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                          ? 'message sent'
                          : 'message received'
                      }
                      style={{
                        display: 'flex',
                        // backgroundColor:
                        //   i?.id === (getMessages?.staff_id?._id || getMessages?.staff_id) ? '#1d5c4b' : '#202c32',
                        flexDirection: 'column'
                      }}
                    >
                      <div
                        key={i._id}
                        className={
                          i?.sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                            ? 'message received'
                            : 'message sent'
                        }
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                          borderLeft:
                            i?.sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                              ? '5px solid blue'
                              : '5px solid red',
                          minWidth: '100px',
                          marginBottom: '0'
                        }}
                        onDoubleClick={() => messageReply(i)}
                      >
                        <p
                          className='sent-p'
                          style={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px', margin: '0' }}
                        >
                          {i?.reply_sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                            ? 'You'
                            : chatViewData?.first_name.toLocaleUpperCase()}
                        </p>
                        <p
                          className='sent-p'
                          style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px', marginTop: '2px' }}
                        >
                          {i?.message ? i?.message : i?.file?.split('/')[3]}
                        </p>
                      </div>
                      <p
                        className='sent-p'
                        style={{ display: 'flex', flexWrap: 'wrap', fontSize: '16px', marginTop: '8px' }}
                        onDoubleClick={() => messageReply(i)}
                      >
                        {i?.reply_msg ? i?.reply_msg : null}
                      </p>
                    </div>
                  )
                }
                if (!i?.file && i?.reply_msg) {
                  return (
                    <div
                      className={
                        i?.sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                          ? 'message sent'
                          : 'message received'
                      }
                      style={{
                        display: 'flex',
                        // backgroundColor:
                        //   i?.id === (getMessages?.staff_id?._id || getMessages?.staff_id) ? '#1d5c4b' : '#202c32',
                        flexDirection: 'column'
                      }}
                    >
                      <div
                        key={i._id}
                        className={
                          i?.sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                            ? 'message received'
                            : 'message sent'
                        }
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                          borderLeft:
                            i?.sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                              ? '5px solid blue'
                              : '5px solid red',
                          minWidth: '100px',
                          marginBottom: '0'
                        }}
                        onDoubleClick={() => messageReply(i)}
                      >
                        <p
                          className='sent-p'
                          style={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px', margin: '0' }}
                        >
                          {i?.reply_sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                            ? 'You'
                            : chatViewData?.first_name.toLocaleUpperCase()}
                        </p>
                        <p
                          className='sent-p'
                          style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px', marginTop: '2px' }}
                        >
                          {i?.reply_msg ? i?.message : null}
                        </p>
                      </div>
                      <p
                        className='sent-p'
                        style={{ display: 'flex', flexWrap: 'wrap', fontSize: '16px', marginTop: '8px' }}
                        onDoubleClick={() => messageReply(i)}
                      >
                        {i?.reply_msg ? i?.reply_msg : null}
                      </p>
                      <p
                        className='sent-p'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          margin: '0',
                          fontSize: '8px',
                          alignSelf: 'flex-end'
                        }}
                      >
                        {i?.createdAt ? moment(i?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null}
                      </p>
                    </div>
                  )
                }
                if (!i?.file && i?.message) {
                  return (
                    <div
                      key={i._id}
                      className={
                        i?.sender_id === (getMessages?.student_id?._id || getMessages?.student_id)
                          ? 'message sent'
                          : 'message received'
                      }
                      style={{ display: 'flex', flexDirection: 'column', minWidth: '100px', alignItems: 'start' }}
                      onDoubleClick={() => messageReply(i)}
                    >
                      <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', margin: '0' }}>
                        {i?.message ? i.message : i.message}
                      </p>
                      <p
                        className='sent-p'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          margin: '0',
                          fontSize: '8px',
                          alignSelf: 'flex-end'
                        }}
                      >
                        {i?.createdAt ? moment(i?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null}
                      </p>
                    </div>
                  )
                }
                if (i?.file && i?.message) {
                  return (
                    <div
                      key={i._id}
                      className={
                        i?.sender_id !== (getMessages?.staff_id?._id || getMessages?.staff_id)
                          ? 'message sent-pdf'
                          : 'message received-pdf'
                      }
                      style={{ display: 'flex', flexDirection: 'column' }}
                      onDoubleClick={() => messageReply(i)}
                    >
                      <p
                        className='sent-p'
                        style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '2px', marginTop: '0' }}
                      >
                        {i?.message ? i.message : i.message}
                      </p>

                      <a
                        href={i?.sender_id === (getMessages?.staff_id?._id || getMessages?.staff_id) ? null : i?.file}
                        target={
                          i?.sender_id === (getMessages?.staff_id?._id || getMessages?.staff_id) ? '_self' : '_blank'
                        }
                        rel='noreferrer'
                      >
                        <p
                          className='sent-p'
                          style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', margin: '0' }}
                        >
                          <i
                            class='fa fa-file-pdf-o'
                            style={{ fontSize: '25px', color: 'red', marginRight: '10px' }}
                          ></i>
                          {i?.file ? i?.file?.split('/')[3] : i.file}
                          {i?.sender_id === (getMessages?.staff_id?._id || getMessages?.staff_id) && (
                            <a href={i?.file} download rel='noreferrer' style={{ color: 'green' }}>
                              <i class='fa fa-download' style={{ fontSize: '15px', marginLeft: '10px' }}></i>
                            </a>
                          )}
                        </p>
                        <p
                          className='sent-p'
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            margin: '0',
                            fontSize: '8px',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                          }}
                        >
                          {i?.createdAt ? moment(i?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null}
                        </p>
                      </a>
                    </div>
                  )
                }
                if (i?.file && !i?.message) {
                  return (
                    <div
                      key={i._id}
                      className={
                        i?.sender_id !== (getMessages?.staff_id?._id || getMessages?.staff_id)
                          ? 'message sent-pdf'
                          : 'message received-pdf'
                      }
                      style={{ display: 'flex' }}
                      onDoubleClick={() => messageReply(i)}
                    >
                      <a
                        href={
                          i?.sender_id !== (getMessages?.student_id?._id || getMessages?.student_id) ? null : i?.file
                        }
                        target={
                          i?.sender_id !== (getMessages?.student_id?._id || getMessages?.student_id)
                            ? '_self'
                            : '_blank'
                        }
                        style={{ textDecoration: 'none' }}
                        rel='noreferrer'
                      >
                        <p
                          className='sent-p'
                          style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: '0' }}
                        >
                          <i
                            class='fa fa-file-pdf-o'
                            style={{ fontSize: '25px', color: 'red', marginRight: '10px' }}
                          ></i>
                          {i?.file ? i?.file?.split('/')[3] : i.file}
                          {i?.sender_id !== (getMessages?.student_id?._id || getMessages?.student_id) && (
                            <a href={i?.file} download rel='noreferrer' style={{ color: 'green' }}>
                              <i class='fa fa-download' style={{ fontSize: '15px', marginLeft: '10px' }}></i>
                            </a>
                          )}
                        </p>
                      </a>
                      <p
                        className='sent-p'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          margin: '0',
                          fontSize: '8px',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                          marginTop: '20px'
                        }}
                      >
                        {i?.createdAt ? moment(i?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null}
                      </p>
                    </div>
                  )
                }
              })}
            </div>
          )
        )}

        {Object.values(chatViewData).length !== 0 && (
          <form onSubmit={onsubmit} style={{}}>
            <div
              className='message-input'
              style={{
                position: 'relative',
                width: '100%',
                backgroundColor: '#0c1419',
                padding: '0'
              }}
            >
              <div className='custom-input' style={{ width: '100%' }}>
                {fileAdd && (
                  <div
                    className='attachment-preview'
                    style={{
                      backgroundColor: '#1d5c4b'
                    }}
                  >
                    <div
                      style={{
                        padding: '9px',
                        position: 'relative',
                        borderRadius: '5px',
                        backgroundColor: '#1d5c4b',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <p
                        className='sent-p'
                        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', margin: '0' }}
                      >
                        <i class='fa fa-file-pdf' style={{ fontSize: '25px', color: 'red' }}></i>

                        <div style={{ color: 'white' }}>{fileAdd?.name}</div>
                      </p>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '30px',
                          backgroundColor: '#0c1419',
                          borderRadius: '100%',
                          cursor: 'pointer',
                          height: '30px'
                        }}
                        onClick={() => {
                          setFileAdd(null)
                          if (fileInputRef.current) {
                            fileInputRef.current.value = ''
                          }
                        }}
                      >
                        <i class='fa fa-times-circle' style={{ color: 'white' }}></i>
                      </div>
                    </div>
                  </div>
                )}

                {replyMsgData && (
                  <div className='replay-preview' style={{ transform: 'translate-middle', backgroundColor: '#202c32' }}>
                    <div
                      style={{
                        padding: '9px',
                        position: 'relative',
                        borderRadius: '5px',
                        backgroundColor: '#202c32',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderLeft:
                          replyMsgData?.sender_id !== chatViewData._id ? '5px solid  #4dcf9c' : '5px solid  orangered'
                      }}
                    >
                      <p
                        className='sent-p'
                        style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', margin: '0' }}
                      >
                        <div
                          style={{
                            fontSize: '12px',
                            color: replyMsgData?.sender_id !== chatViewData._id ? 'green' : 'orangered',
                            marginBottom: '8px'
                          }}
                        >
                          {replyMsgData?.sender_id === chatViewData._id
                            ? chatViewData?.first_name.toLocaleUpperCase()
                            : 'You'}
                        </div>
                        <div style={{ fontSize: '10px' }}>
                          {!replyMsgData?.reply_msg
                            ? replyMsgData?.message
                              ? replyMsgData?.message
                              : replyMsgData.file?.split('/')[3]
                            : replyMsgData?.reply_msg}
                        </div>
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '30px',
                          backgroundColor: '#0c1419',
                          borderRadius: '100%',
                          cursor: 'pointer',
                          height: '30px'
                        }}
                        onClick={() => {
                          setReplyMsgData(null)
                        }}
                      >
                        <i class='fa fa-times-circle' style={{ color: 'white' }}></i>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#252b40',
                    padding: width < 992 ? '3px' : '0px'
                  }}
                >
                  <input
                    type='text'
                    value={chatValue}
                    style={{ marginRight: '0', outline: 'none', width: '100%' }}
                    placeholder={`Message to ${chatViewData?.first_name}`}
                    onChange={e => setChatValue(e.target.value)}
                  />
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <div className='attachment'>
                      <input
                        type='file'
                        ref={fileInputRef || ''}
                        accept='.pdf, .csv,'
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />

                      <i
                        class='fa fa-paperclip'
                        onClick={handleFileSelect}
                        style={{ cursor: 'pointer', color: 'white' }}
                        htmlFor='image'
                      ></i>
                    </div>
                    <button
                      onClick={() => sendMsg()}
                      type='submit'
                      style={{
                        marginTop: '0px',
                        width: width < 992 ? '60px' : '230px',
                        padding: width < 992 ? '12px 30px 12px 18px' : '12px 30px 12px 30px'
                      }}
                    >
                      <i class='fa fa-paper-plane'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        {Object.values(chatViewData).length === 0 && <div className='no-data-found'>No Data Found</div>}
      </div>
    </div>
  )
}

export default CoachChat
