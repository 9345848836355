/**
 * Utility methods to be used for invoking API methods
 */

import Axios from 'axios'

import authConfig from '../config/auth'
import queryString from 'querystring'
import { refreshToken } from '../services/login'

// ** Moment
import moment from 'moment'

const showLogs = false

const checkTokenExpired = async () => {
  let userData = localStorage.getItem('userData')
  let loginData = JSON.parse(userData)
  let date = moment().toDate()

  if (loginData?.tokenTime > date.getTime() / 1000) {
    return localStorage.getItem(authConfig.storageTokenKeyName)
  } else if (loginData?.refreshTokenTime > date.getTime() / 1000) {
    const newRefreshToken = await refreshToken({ refresh_token: loginData.refreshToken })

    localStorage.setItem('userData', JSON.stringify(newRefreshToken.userData))
    localStorage.setItem(authConfig.storageTokenKeyName, newRefreshToken.userData.token)

    localStorage.setItem('userId', newRefreshToken.userData.id)

    localStorage.setItem('referral_code', newRefreshToken.userData.referral_code)
    // const stringifyDataBuyCourse = JSON.stringify(newRefreshToken.userData.course_purchase)
    // await localStorage.setItem('buyCourses', stringifyDataBuyCourse)
    const stringifyDataStudent = JSON.stringify(newRefreshToken.userData.personData)
    await localStorage.setItem('profileInformation', stringifyDataStudent)
    await localStorage.setItem('studentStore', stringifyDataStudent)

    return newRefreshToken.userData.token
  } else {
    localStorage.removeItem('loginUser')
    localStorage.removeItem('studentStore')
    localStorage.removeItem('profileInformation')
    localStorage.removeItem('buyCourses')
    localStorage.removeItem('userId')
    localStorage.removeItem('mongoId')
    localStorage.removeItem('referral_code')
    localStorage.removeItem('userData')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('saveCurrentChat')
    localStorage.removeItem('quiz')
    window.location.href = '/'

    return null
  }
}

export const getDefaultHeaders = async () => {
  if (localStorage.accessToken) {
    return {
      Authorization: 'Bearer ' + (await checkTokenExpired()),
      'Content-Type': 'application/json'
    }
  } else {
    return { 'Content-Type': 'application/json' }
  }
}

export const makeUrl = ({ uri = '', pathParams, query }, host) => {
  return `${host || `${process.env.REACT_APP_API_ENDPOINT}`}${uri
    .split('/')
    .map(param => (param.charAt(0) === ':' ? encodeURI(pathParams[param.slice(1)]) : param))
    .join('/')}${query ? `?${queryString.stringify(query)}` : ''}`
}

const callAxios = async ({ uriEndPoint, pathParams, query, body, apiHostUrl }) => {
  showLogs &&
    console.log('Call AXIOS ==>', {
      method: uriEndPoint.method,
      url: makeUrl({ ...uriEndPoint, pathParams, query }, apiHostUrl),
      headers: {
        ...(await getDefaultHeaders()),
        ...uriEndPoint.headerProps
      },
      data: body || {}
    })

  return Axios({
    method: uriEndPoint.method,
    url: makeUrl({ ...uriEndPoint, pathParams, query }, apiHostUrl),
    headers: {
      ...(await getDefaultHeaders()),
      ...uriEndPoint.headerProps
    },
    data: body || {}
  })
}

export const callApi = props => {
  const { uriEndPoint = { uri: '', method: '', headerProps: {} }, pathParams, query, body, apiHostUrl } = props

  return new Promise((resolve, reject) => {
    callAxios({
      uriEndPoint,
      pathParams,
      query,
      body,
      apiHostUrl
    })
      .then(response => {
        showLogs && console.log('callApi RES ==>', response.data)
        resolve(response.data)
      })
      .catch(err => {
        showLogs && console.log('callApi ERR ==>', err)
        reject(err.response?.data ?? err)
      })
  })
}
