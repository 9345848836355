import React from 'react'
import './footershah.scss'

export default function Footer2() {
  return (
    <div>
      <div className='footer-grid-container'>
        <div className='top-section'>
          <div className='other-page'>
            <h3 style={{}}>E-Learning @ SHAH</h3>
            <ul>
              {/* <li onClick={() => (window.location.href = '/')}>Home</li> */}
              <li onClick={() => (window.location.href = '/faqs')}>FAQs</li>
              <li onClick={() => (window.location.href = '/shah')}>About SHAH </li>
              <li onClick={() => (window.location.href = '/team')}>Team @ SHAH</li>
              {/* <li>
                <a href="https://drkrutikshah.com/" target="__blank">
                  About The Instructor
                </a>
              </li> */}
              <li onClick={() => (window.location.href = '/contact')}>Contact</li>
            </ul>
          </div>
          <div className='quick-links'>
            <h3 style={{}}>Links</h3>
            <ul>
              <li onClick={() => (window.location.href = '/terms-conditions')}> Refund Policy</li>
              <li onClick={() => (window.location.href = '/terms-conditions')}>Cookies</li>

              <li onClick={() => (window.location.href = '/terms-conditions')}>Privacy Policy</li>
              <li onClick={() => (window.location.href = '/Disclaimer')}>Disclaimer</li>
            </ul>
          </div>
          <div className='ImportantCourses'>
            <h3 style={{}}>Proud to be a Homeopath!</h3>
            <ul>
              <li onClick={() => (window.location.href = '/ceremony')}>Online Inaugural Ceremony</li>
              <li onClick={() => (window.location.href = '/digitalorganon')}>Dr. Hahnemann’s Digital Organon</li>
              <li onClick={() => (window.location.href = '/accesstosuccess')}>Organon – Access to Success</li>

              <li onClick={() => (window.location.href = '/beahomeopath')}>Be a Homeopath</li>
            </ul>
          </div>
          <div className='AboutKrutikShah'>
            <h3 style={{}}>About Dr. Krutik Shah</h3>
            <ul>
              <li>
                <a href='/drkrutikshah'>First Homeopathic Centurian for Webinars in the world</a>
              </li>
              {/* <li>
                <a
                  href="/pdf/SHAH.pdf"
                  // target="__blank"
                  download
                >
                  Download E-Learning Curriculum
                </a>
              </li> */}

              <li>
                <a href='/felicitations' target='__blank'>
                  Post Inaugural Felicitations to Dr. Krutik Shah
                </a>
              </li>
              <li>
                <a href='https://www.drkrutikshah.com/amenities-infrastructure.php' target='__blank'>
                  Visit Our Homeopathic Hospital
                </a>
              </li>
              <li onClick={() => (window.location.href = '/scholarships')}>Scholarships @ SHAH</li>
            </ul>
          </div>
        </div>
        <div
          className='middle-section'
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}
        >
          {/* <div className='download'>
            <div className='content'>
              <h2>Download the App for Quick Support</h2>
              <p>Turnaround times are lower on our app. Download the app and get quick answers to your queries.</p>
            </div>
          </div> */}
          <div className='buttons' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              className='image1'
              onClick={() => window.open('https://apps.apple.com/in/app/shah-courses/id6470359344', '_blank')}
            >
              <img src='/images/AppStore.png' style={{ cursor: 'pointer' }} alt='' />
            </div>
            <div
              className='image2'
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.shahapp', '_blank')}
            >
              <img style={{ cursor: 'pointer' }} src='/images/PlayStore.png' alt='' />
            </div>
          </div>
        </div>
        <div className='last-section'>
          {/* <div className="logo">
            <img
              onClick={() => (window.location.href = "/")}
              style={{ cursor: "pointer" }}
              src="/images/academy.png"
              alt=""
            />
          </div> */}
          {/* <div className='copyright'>
            <small>
              © {new Date().getFullYear().toString()} Samuel Hanemann Academy for Homeopathy – SHAH | All Rights
              Reserved.
            </small>
          </div> */}
          <div className='footer-link-special'>
            <p className='link-1'>
              <a href='https://www.samuelhahnemann.in' target='__blank'>
                www.samuelhahnemann.in
              </a>
            </p>
            <span className='hr-line'></span>
            <p className='link-2'>
              <a href='https://drkrutikshah.com' target='__blank'>
                www.drkrutikshah.com
              </a>
            </p>
          </div>
          {/* <div className='follow-us'>
            <div className='title'>
              <span>Follow us : </span>
              <a href='https://www.facebook.com/samuelhahnemannacademy/' target='__blank'>
                <i className='fa fa-facebook'></i>
              </a>
              <a href='https://www.linkedin.com/company/shahacademy/' target='__blank'>
                <i className='fa fa-linkedin'></i>
              </a>
              <a href='https://www.instagram.com/samuelhahnemannacademy' target='__blank'>
                <i className='fa fa-instagram'></i>
              </a>
              <a href='https://www.youtube.com/@samuelhahnemannacademy' target='__blank'>
                <i className='fa fa-youtube'></i>
              </a>
            </div>
          </div> */}
          {/* <p className='' style={{ color: 'whitesmoke' }}>
            <i className='fa fa-apple' aria-hidden='true'></i> <b style={{ margin: '0px 5px' }}>|</b>{' '}
            <i className='fa fa-android' aria-hidden='true'></i>
            <b style={{ marginLeft: '15px' }}>App. Coming soon!</b>
          </p> */}
        </div>
        <div className='footer-line'></div>
        <div className='copyright-section'>
          <div className='copyright'>
            <small>
              © {new Date().getFullYear().toString()} Samuel Hahnemann Academy for Homeopathy – SHAH | All Rights
              Reserved.
            </small>
          </div>
          <div className='footer-icon'>
            <section class='social'>
              <ul class='social-set'>
                <li>
                  <a
                    class='sociali sociali-fa-facebook'
                    href='https://www.facebook.com/samuelhahnemannacademy'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Facebook'
                  >
                    <i class='fa fa-facebook fa-fw' title='Facebook'></i>
                  </a>
                </li>
                <li>
                  <a
                    class='sociali sociali-fa-linkedin'
                    href='https://www.linkedin.com/company/samuelhahnemannacademy/'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='LinkedIn'
                  >
                    <i class='fa fa-linkedin fa-fw' title='LinkedIn'></i>
                  </a>
                </li>
                <li>
                  <a
                    class='sociali sociali-fa-instagram'
                    href='https://www.instagram.com/samuelhahnemannacademy/'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Instagram'
                  >
                    <i class='fa fa-instagram fa-fw' title='Instagram'></i>
                  </a>
                </li>
                <li>
                  <a
                    class='sociali sociali-fa-youtube'
                    href='https://www.youtube.com/@samuelhahnemannacademy'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='YouTube'
                  >
                    <i class='fa fa-youtube fa-fw' title='YouTube'></i>
                  </a>
                </li>
              </ul>
            </section>
          </div>

          {/* <div className='buttons'>
            <div className='image1'>
              <img src='/images/AppStore.png' style={{ cursor: 'pointer' }} alt='' />
            </div>
            <div
              className='image2'
              onClick={() =>
                (window.location.href = 'https://play.google.com/store/apps/details?id=com.shah.education')
              }
            >
              <img style={{ cursor: 'pointer' }} src='/images/PlayStore.png' alt='' />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
