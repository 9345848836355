import React, { useState, useEffect } from 'react'

import '../styles/Settings.scss'
import home from '../img/home .png'
import setting from '../img/settings.png'
import Chat from '../img/chat3.png'
import life from '../img/lifesaver.png'
import catalog from '../img/com.png'
import logout from '../img/logout.png'
import useWindowDimensions from '../components/useWindowDimesions'

import UserSettingsName from '../components/UserSettingsName'
import PasswordChange from '../components/PasswordChange'
import CoursePurchased from '../components/CoursePurchased'
import Transactions from '../components/Transactions'
import QuizCertificate from '../components/QuizCertificate'
import axios from 'axios'

export default function Profile() {
  const [activeNavItem, setActiveNavItem] = useState('home')
  const mongoId = localStorage.getItem('mongoId')
  const token = localStorage.getItem('accessToken')

  const { width } = useWindowDimensions()

  // const [certificate, setCertificate] = useState('Download Certificate')
  // const [url, setURL] = useState('')
  // const id = localStorage.getItem('loginUser')

  const [activeData, setActiveData] = useState('personalInformation')

  // const [value, setValue] = useState(false)

  // const [name, setName] = useState('')
  // const [phone, setPhone] = useState('')
  const [type] = useState('')
  const [purchased, setPurchased] = useState(false)

  const [courseData, setCourseData] = useState([])
  let config = {
    headers: {
      Authorization: 'Bearer' + ' ' + token
    },
    params: {
      studentid: mongoId
    }
  }

  useEffect(() => {
    getData()

    if (type === 'Reported') {
      localStorage.removeItem('loginUser')
      localStorage.removeItem('studentStore')
      localStorage.removeItem('profileInformation')
      localStorage.removeItem('buyCourses')
      localStorage.removeItem('userId')
      localStorage.removeItem('mongoId')
      localStorage.removeItem('referral_code')
      localStorage.removeItem('saveCurrentChat')
      localStorage.removeItem('quiz')
    }
  }, [type])

  function Result(item) {
    setActiveData(item)
  }

  async function getData() {
    try {
      const response = await axios.get('/api/order/buy-course-list', config)

      const buyCoursesData = response.data.data
      setCourseData(buyCoursesData)
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('loginUser')
        localStorage.removeItem('studentStore')
        localStorage.removeItem('profileInformation')
        localStorage.removeItem('buyCourses')
        localStorage.removeItem('userId')
        localStorage.removeItem('mongoId')
        localStorage.removeItem('referral_code')
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('saveCurrentChat')
        localStorage.removeItem('quiz')
        window.location.href = '/'
      }
      console.log('Error fetching buy course list:', error)
    }
  }

  return (
    <>
      <>
        {purchased === false ? (
          <>
            <div className='profile-view'>
              <div className='full-setting'>
                <div className='side'>
                  <ul>
                    <li
                      onClick={() => {
                        window.location.href = '/'
                      }}
                    >
                      <img src={home} />
                      <a>Home</a>
                    </li>
                    <li
                      onClick={() => {
                        setActiveNavItem('purchased')
                        window.location.href = '/purchased-course'
                      }}
                      className={activeNavItem === 'purchased' ? 'active' : ''}
                    >
                      <img src={life} />
                      Purchased Courses
                    </li>
                    <li
                      onClick={() => {
                        setActiveNavItem('browse')
                        window.location.href = '/browse-course'
                      }}
                      className={activeNavItem === 'browse' ? 'active' : ''}
                    >
                      <img src={catalog} />
                      Browse Course
                    </li>
                    <li
                      onClick={() => {
                        setActiveNavItem('chat')
                        window.location.href = '/chat'
                      }}
                      style={{ color: window?.location?.href?.split('/')[3] === 'chat' ? 'orangered' : '' }}
                      className={activeNavItem === 'settings' ? 'active' : ''}
                    >
                      <img src={Chat} />
                      Chat
                    </li>
                    <li
                      onClick={() => {
                        setActiveNavItem('settings')
                        window.location.href = '/profile'
                      }}
                      style={{ color: window?.location?.href?.split('/')[3] === 'profile' ? 'orangered' : '' }}
                      className={activeNavItem === 'settings' ? 'active' : ''}
                    >
                      <img src={setting} />
                      Settings
                    </li>
                    <li
                      onClick={() => {
                        localStorage.removeItem('loginUser')
                        localStorage.removeItem('studentStore')
                        localStorage.removeItem('profileInformation')
                        localStorage.removeItem('buyCourses')
                        localStorage.removeItem('userId')
                        localStorage.removeItem('mongoId')
                        localStorage.removeItem('referral_code')
                        localStorage.removeItem('quiz')
                        window.location.href = '/'
                      }}
                    >
                      <img src={logout} />
                      Logout
                    </li>
                  </ul>
                </div>

                <div className='both'>
                  <div className='nav1' style={{ padding: '20px 15px' }}>
                    Settings
                  </div>
                  <div className='both_inner'>
                    <div className='in-side'>
                      <h4>Account</h4>

                      <div className='list'>
                        <div className='click-btn'>
                          <ul
                            style={{
                              paddingLeft: '0',
                              listStyleType: 'none',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '10px',
                              padding: '0 0.5rem'
                            }}
                          >
                            <li
                              style={{
                                backgroundColor:
                                  activeData === 'personalInformation' ? '#e76100' : 'rgba(31, 41, 50, 0.03)',
                                color: activeData === 'personalInformation' ? '#fff' : '#4f4f4f',
                                padding: '1rem'
                              }}
                              onClick={() => Result('personalInformation')}
                            >
                              {' '}
                              Personal Information
                            </li>
                            <li
                              style={{
                                backgroundColor: activeData === 'password' ? '#e76100' : 'rgba(31, 41, 50, 0.03)',
                                color: activeData === 'password' ? '#fff' : '#4f4f4f',
                                padding: '1rem'
                              }}
                              onClick={() => Result('password')}
                            >
                              {' '}
                              Password
                            </li>
                            <li
                              style={{
                                backgroundColor: activeData === 'transactions' ? '#e76100' : 'rgba(31, 41, 50, 0.03)',
                                color: activeData === 'transactions' ? '#fff' : '#4f4f4f',
                                padding: '1rem'
                              }}
                              onClick={() => Result('transactions')}
                            >
                              {' '}
                              Transactions
                            </li>
                            <li
                              style={{
                                backgroundColor:
                                  activeData === 'quiz_certificate' ? '#e76100' : 'rgba(31, 41, 50, 0.03)',
                                color: activeData === 'quiz_certificate' ? '#fff' : '#4f4f4f',
                                padding: '1rem'
                              }}
                              onClick={() => Result('quiz_certificate')}
                            >
                              {' '}
                              Quiz Certificate
                            </li>
                          </ul>
                        </div>
                        {/* <div
                          style={{
                            backgroundColor: activeData === 'password' ? '#e76100' : 'rgba(31, 41, 50, 0.03)',
                            color: activeData === 'password' ? '#fff' : '#4f4f4f'
                          }}
                        ></div>
                        <div
                          className='click-btn'
                          style={{
                            backgroundColor: activeData === 'transactions' ? '#e76100' : 'rgba(31, 41, 50, 0.03)',
                            color: activeData === 'transactions' ? '#fff' : '#4f4f4f'
                          }}
                        ></div> */}
                      </div>
                    </div>
                    <div className='in-box' style={{ marginTop: width <= 992 ? '30px' : '0px' }}>
                      <div className='nxt'>
                        {activeData === 'personalInformation' ? (
                          <UserSettingsName />
                        ) : activeData === 'password' ? (
                          <PasswordChange />
                        ) : activeData === 'transactions' ? (
                          <Transactions />
                        ) : activeData === 'quiz_certificate' ? (
                          <QuizCertificate />
                        ) : (
                          <div className='one-three'></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='profile-view'>
              <div className='full-setting'>
                <div className='nav1'>Purchased Course</div>
                <div className='side' style={{ paddingLeft: 23 }}>
                  <ul>
                    <li
                      onClick={() => {
                        setActiveNavItem('home')
                        window.location.href = '/'
                      }}
                      className={activeNavItem === 'home' ? 'active' : ''}
                    >
                      <img src={home} />
                      Home
                    </li>
                    <li
                      onClick={() => {
                        setActiveNavItem('purchased')
                        purchased === true ? setPurchased(false) : setPurchased(false)
                      }}
                      className={activeNavItem === 'purchased' ? 'active' : ''}
                    >
                      <img src={life} />
                      Purchased Courses
                    </li>
                    <li
                      onClick={() => {
                        setActiveNavItem('browse')
                        window.location.href = '/browse-course'
                      }}
                      className={activeNavItem === 'browse' ? 'active' : ''}
                    >
                      <img src={catalog} />
                      Browse Course
                    </li>
                    <li
                      onClick={() => {
                        setActiveNavItem('settings')
                        window.location.href = '/settings'
                      }}
                      className={activeNavItem === 'settings' ? 'active' : ''}
                    >
                      <img src={setting} />
                      Settings
                    </li>
                    <li
                      onClick={() => {
                        setActiveNavItem('settings')
                        window.location.href = '/settings'
                      }}
                      className={activeNavItem === 'settings' ? 'active' : ''}
                    >
                      <img src={setting} />
                      Settings
                    </li>
                    <li
                      onClick={() => {
                        localStorage.removeItem('loginUser')
                        localStorage.removeItem('studentStore')
                        localStorage.removeItem('buyCourses')
                        localStorage.removeItem('profileInformation')
                        localStorage.removeItem('userId')
                        localStorage.removeItem('mongoId')
                        localStorage.removeItem('referral_code')
                        localStorage.removeItem('quiz')
                        window.location.href = '/'
                      }}
                    >
                      <img src={logout} />
                      Logout
                    </li>
                  </ul>
                </div>

                <>
                  <div className='both' style={{ background: '#fff' }}>
                    {/* {localStorage.getItem("coursename") ? (
                          <CoursePurchased
                            value={localStorage.getItem("coursename")}
                          />
                        ) : (
                          "No Course Available"
                        )
                        } */}

                    {courseData?.map(res => (
                      <>
                        <CoursePurchased value={res.coursename} />
                      </>
                    ))}
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </>
    </>
  )
}
