import React from 'react'
import './shopingCard.scss'

function Card(props) {
  const { image, header } = props
  return (
    <div className='shopingCard-main'>
      <div className='frame'>
        <div className='image'>
          <div className='image-container'>
            <img src={image} alt='' />
          </div>
        </div>
        <div className='content-section'>
          <div className='sticker'>
            <div className='content'>
              READ <br /> MORE
            </div>
          </div>
          <div className='content'>
            <div className='header'>{header}</div>
            {/* <div className="sub-content">
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts.
            </div> */}
          </div>
          <div className='last-section'>
            <div className='lesson'>
              <span id='book'>
                <a className='fa fa-book'></a>
              </span>
              <span>9 Lesson</span>
            </div>
            <div className='student'>
              <span id='children'>
                <a className='fa fa-bookmark'></a>
              </span>
              <span>25 Chapters</span>
            </div>
            <div className='review-section'>
              <span id='children'>
                <a className='fa fa-star'></a>
              </span>
              <span>10 Review</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
