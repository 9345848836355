/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import play from '../../img/play-button.png'

import useWindowDimensions from '../../components/useWindowDimesions'

export const ChapterView = ({ viewChapterData }) => {
  const { width } = useWindowDimensions()
  const [dropdown, setDropdown] = useState(false)
  return (
    <>
      <div className={width > 999 ? 'desktop-hero' : 'mobile-hero'}>
        <img src='/Attend-a-Webinar.jpg' alt='courses' />

        <div className='content'>
          <div className='title'>
            <>Organon with SHAH</>
          </div>

          <div className='breadcrumb'>
            <span onClick={() => (window.location.href = '/')}>Home</span>
            <i className='fa fa-chevron-right'></i>
            <span>Organon with SHAH</span>
          </div>
        </div>
      </div>
      <span
        style={{
          marginRight: '1em',
          color: 'orangered',
          fontWeight: 'bold',
          cursor: 'pointer',
          position: 'absolute',
          right: 0
        }}
        onClick={() => (dropdown === false ? setDropdown(true) : setDropdown(false))}
      >
        {dropdown === false ? 'Expand' : 'Collapse'} all sections
      </span>{' '}
      <div className='topics'>
        <GetChapters
          drop={dropdown}
          id={viewChapterData._id}
          chapterName={viewChapterData.chapterName}
          clinicalPonits={viewChapterData.clinicalPonits}
        >
          <GetLessons
            chapterid={viewChapterData._id}
            chapter_id={''}
            slug={''}
            checkedBuyCourse={''}
            clinicalPonits={''}
            lessons={viewChapterData.lesson}
          />

          <GetQuiz quiz={viewChapterData.quiz} />
        </GetChapters>
      </div>
    </>
  )
}

function GetQuiz(props) {
  return (
    <>
      {props?.quiz.map((item, index) => {
        if (item !== null && item?.questions?.length !== 0 && typeof item === 'object' && item !== null)
          return (
            <li className='quiz' style={{ fontWeight: 'bold', color: 'orangered' }} key={index + 'thisIndex'}>
              <i style={{ color: 'orangered' }}>
                <img src={play} />
              </i>
              Clinical Quiz
              {/* <span className="que">{res.questions.length} questions</span>
          <span className="min">{NumberExtractor(res.time)}min</span> */}
              <div className='lock'>
                <img src='/padlock.png' />
              </div>
            </li>
          )
      })}
    </>
  )
}

function GetLessons(props) {
  const checkedBuyCourse = props?.checkedBuyCourse

  const chapter_id = props?.chapter_id

  return (
    <>
      {props?.lessons.map((res, index) => {
        return (
          <li className='lessonName' key={index + 'Lessons'}>
            <i>
              <img src={play} />
            </i>
            <span style={{ width: '80%' }}>{res?.lessonName}</span>{' '}
            <div style={{ position: 'absolute', right: 0, marginRight: checkedBuyCourse === true ? 110 : 83 }}>
              {res.lessonTime}
            </div>
            <div className='lock'>
              {checkedBuyCourse === true ? (
                ''
              ) : chapter_id === props.chapterid ? (
                <img style={{ display: res.lock === true ? 'block' : 'none' }} src='/padlock.png' />
              ) : chapter_id === undefined ? (
                ''
              ) : (
                <img style={{ display: res.lock === true ? 'block' : 'none' }} src='/padlock.png' />
              )}
              {props.clinicalPonits === '0' ? (
                ''
              ) : chapter_id === props.chapterid ? (
                checkedBuyCourse === true ? (
                  <a style={{ textDecoration: 'none' }} href={'/view-course/' + chapter_id + '-' + props.slug}>
                    Preview
                  </a>
                ) : res.lock === false ? (
                  <a style={{ textDecoration: 'none' }} href={'/view-course/' + props.slug}>
                    Preview
                  </a>
                ) : chapter_id === undefined ? (
                  <a style={{ textDecoration: 'none' }} href={'/view-course/' + props.slug}>
                    Preview
                  </a>
                ) : (
                  ''
                )
              ) : checkedBuyCourse && chapter_id === undefined ? (
                <a style={{ textDecoration: 'none' }} href={'/view-course/' + props.slug}>
                  Preview
                </a>
              ) : (
                <img style={{ display: res.lock === true ? 'block' : 'none' }} src='/padlock.png' />
              )}
            </div>
          </li>
        )
      })}
    </>
  )
}

function GetChapters(props) {
  const [dropdown, setDropdown] = useState(false)
  useEffect(() => {
    if (props.drop === false) {
      setDropdown(false)
    } else if (props.drop === true) {
      setDropdown(true)
    }
  }, [props.drop])

  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => (dropdown === false ? setDropdown(true) : setDropdown(false))}
        className='click'
      >
        <span>{props?.chapterName?.split('.')[0]}.</span>
        <span style={{ width: '80%', marginLeft: '5px' }}>
          {' '}
          {props.chapterName ? props.chapterName.slice(3, props.chapterName.length) : 'Oath'}
        </span>
        <span
          style={{
            right: 0,
            position: 'absolute',
            marginRight: 23,
            fontSize: 14
          }}
        >
          {/* <GetLessonNumber chapterid={props.id} /> */}
          {props.clinicalPonits === 0 ? (
            <></>
          ) : (
            <>
              {props.clinicalPonit && ' |'} {props.clinicalPonits}
              {props.clinicalPonits && ' Keynotes'}
            </>
          )}
        </span>

        <div
          onClick={() => (dropdown === false ? setDropdown(true) : setDropdown(false))}
          className={dropdown === false ? 'dropdown' : 'dropdowns'}
        >
          <img src='/next.png' />
        </div>
      </div>

      <ul style={{ display: dropdown === false ? 'none' : 'block' }} className='ul'>
        {props.children}
      </ul>
    </>
  )
}
