import React, { useState } from 'react'
// import android from '../img/android.svg'
// import ios from '../img/apple-logo.svg'
import Cancel from '../img/cancel.png'
import Drawer from '@material-ui/core/Drawer'
export default function MobileNavBar(props) {
  const [open, isOpen] = useState(false)
  const [opneNav, setOpneNav] = useState('')

  const openCloseNav = value => {
    if (opneNav === value) {
      setOpneNav('')
    } else {
      setOpneNav(value)
    }
  }

  const user = localStorage.getItem('mongoId')
  // const user = localStorage.getItem('userId')
  return (
    <>
      {/* <marquee
        behavior='scroll'
        direction='left'
        style={{ fontFamily: " 'Jost',sans-serif", color: 'orangered', zIndex: '99999' }}
      >
        USE CODE <b className='blink-hard'>SHAH30</b> To Avail <b className='blink-hard'>30%</b>Discount on your courses
        on the occasion of Diwali and New Year Celebrations!
      </marquee> */}
      <marquee
        behavior='scroll'
        direction='left'
        style={{ fontFamily: " 'Jost',sans-serif", color: 'orangered', zIndex: '99999' }}
      >
        It is the only e-learning program in the world giving exclusive education in the subject of Organon of Medicine
        and Homeopathic Philosophy.
      </marquee>

      <div className='navbar'>
        <div className='container'>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <button
              onClick={() => isOpen(true)}
              className='menu-hamburger'
              style={{ alignSelf: 'center', marginLeft: '10px', marginTop: '0' }}
            >
              <span className='elementskit-menu-hamburger-icon'></span>
              <span className='elementskit-menu-hamburger-icon'></span>
              <span className='elementskit-menu-hamburger-icon'></span>
            </button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                onClick={() => (window.location.href = '/')}
                style={{ cursor: 'pointer', marginTop: '0' }}
                src='/shahlogo.svg'
                alt='shahedu.in'
              />
            </div>

            <div className='follow-us-m' style={{ marginTop: '0' }}>
              <div className='title'>
                <a href='tel:+917567756443' target='__blank' style={{ cursor: 'pointer' }}>
                  <i className='fa fa-phone'></i>
                </a>
                <a href='mailto:organon@samuelhahnemann.org' target='__blank' style={{ cursor: 'pointer' }}>
                  <i className='fa fa-envelope'></i>
                </a>
              </div>
            </div>
          </div>

          {props.shahlearnings ? (
            <></>
          ) : (
            <div className='main-bar'>
              <Drawer anchor='left' open={open} onClose={() => isOpen(false)}>
                <div className='drawers' style={{ width: 308 }}>
                  <div className='logobtn'>
                    <img src='/shahlogo.svg' alt='shahedu' />
                  </div>
                  <div className='btn-close'>
                    <button onClick={() => isOpen(false)} className='close-hamburger' type='button'>
                      <img src={Cancel} alt='cancel' />
                    </button>
                  </div>

                  <ul className='pages-list'>
                    <li>
                      {' '}
                      <a
                        href='/'
                        style={{
                          color: window.location.pathname === '/' ? 'orangered' : '#000'
                        }}
                      >
                        Home
                      </a>
                    </li>

                    <li>
                      <a
                        href='#'
                        style={{
                          color:
                            opneNav === 'Digital-Organon'
                              ? 'orangered'
                              : window.location.pathname === '/digitalorganon'
                              ? 'orangered'
                              : window.location.pathname === '/Theory1'
                              ? 'orangered'
                              : window.location.pathname === '/Practical1'
                              ? 'orangered'
                              : window.location.pathname === '/Practical2'
                              ? 'orangered'
                              : window.location.pathname === '/Practical3'
                              ? 'orangered'
                              : window.location.pathname === '/Totalpractical'
                              ? 'orangered'
                              : window.location.pathname === '/Fulldigital'
                              ? 'orangered'
                              : '#000'
                        }}
                        onClick={() => openCloseNav('Digital-Organon')}
                      >
                        <i>
                          <span>Digital</span> <span> Organon</span>
                        </i>
                        <i className='fa fa-chevron-down'></i>
                      </a>
                      <ul style={{ display: opneNav === 'Digital-Organon' ? 'block' : 'none' }}>
                        <li>
                          <a
                            href='/digitalorganon'
                            style={{
                              color: window.location.pathname === '/digitalorganon' ? 'orangered' : '#000'
                            }}
                          >
                            Dr. Hahnemann’s Digital Organon of Medicine
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Theory1'
                            style={{
                              color: window.location.pathname === '/Theory1' ? 'orangered' : '#000'
                            }}
                          >
                            Theory Part – 1 (§ 1 to 70)
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Practical1'
                            style={{
                              color: window.location.pathname === '/Practical1' ? 'orangered' : '#000'
                            }}
                          >
                            Practical Part – 1 (§ 71 to 104)
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Practical2'
                            style={{
                              color: window.location.pathname === '/Practical2' ? 'orangered' : '#000'
                            }}
                          >
                            Practical Part – 2 (§ 105 to 145)
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Practical3'
                            style={{
                              color: window.location.pathname === '/Practical3' ? 'orangered' : '#000'
                            }}
                          >
                            Practical Part – 3 (§ 146 to 294)
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Totalpractical'
                            style={{
                              color: window.location.pathname === '/Totalpractical' ? 'orangered' : '#000'
                            }}
                          >
                            Total Practical Part (§ 71 to 294)
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Fulldigital'
                            style={{
                              color: window.location.pathname === '/Fulldigital' ? 'orangered' : '#000'
                            }}
                          >
                            Full Digital Organon (§ 1 to 294)
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a
                        href='#'
                        style={{
                          color:
                            opneNav === 'Access-to-Success'
                              ? 'orangered'
                              : window.location.pathname === '/accesstosuccess'
                              ? 'orangered'
                              : window.location.pathname === '/Basic'
                              ? 'orangered'
                              : window.location.pathname === '/Complete'
                              ? 'orangered'
                              : window.location.pathname === '/Advanced'
                              ? 'orangered'
                              : window.location.pathname === '/Prescription'
                              ? 'orangered'
                              : window.location.pathname === '/Acute'
                              ? 'orangered'
                              : window.location.pathname === '/Total'
                              ? 'orangered'
                              : '#000'
                        }}
                        onClick={() => openCloseNav('Access-to-Success')}
                      >
                        <span> Access to Success</span>
                        <i className='fa fa-chevron-down'></i>
                      </a>
                      <ul style={{ display: opneNav === 'Access-to-Success' ? 'block' : 'none' }}>
                        <li>
                          <a
                            href='/accesstosuccess'
                            style={{
                              color: window.location.pathname === '/accesstosuccess' ? 'orangered' : '#000'
                            }}
                          >
                            Organon – Access to Success Course Details
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Basic'
                            style={{
                              color: window.location.pathname === '/Basic' ? 'orangered' : '#000'
                            }}
                          >
                            Basic Access
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Complete'
                            style={{
                              color: window.location.pathname === '/Complete' ? 'orangered' : '#000'
                            }}
                          >
                            Complete Access
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Advanced'
                            style={{
                              color: window.location.pathname === '/Advanced' ? 'orangered' : '#000'
                            }}
                          >
                            Advanced Access
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Prescription'
                            style={{
                              color: window.location.pathname === '/Prescription' ? 'orangered' : '#000'
                            }}
                          >
                            Prescription Access
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Acute'
                            style={{
                              color: window.location.pathname === '/Acute' ? 'orangered' : '#000'
                            }}
                          >
                            Acute Access
                          </a>
                        </li>

                        <li>
                          <a
                            href='/Total'
                            style={{
                              color: window.location.pathname === '/Total' ? 'orangered' : '#000'
                            }}
                          >
                            Total Access
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a
                        href='#'
                        style={{
                          color:
                            opneNav === 'Beahomeopath'
                              ? 'orangered'
                              : window.location.pathname === '/beahomeopath'
                              ? 'orangered'
                              : window.location.pathname === '/Dangerousfuture'
                              ? 'orangered'
                              : window.location.pathname === '/Femalehomeopathy'
                              ? 'orangered'
                              : window.location.pathname === '/beahomeopath'
                              ? 'orangered'
                              : window.location.pathname === '/beahomeopath'
                              ? 'orangered'
                              : '#000'
                        }}
                        onClick={() => openCloseNav('Beahomeopath')}
                      >
                        <span> Be a Homeopath</span>
                        <i className='fa fa-chevron-down'></i>
                      </a>
                      <ul style={{ display: opneNav === 'Beahomeopath' ? 'block' : 'none' }}>
                        <li>
                          <a
                            href='/beahomeopath'
                            style={{
                              color: window.location.pathname === '/beahomeopath' ? 'orangered' : '#000'
                            }}
                          >
                            Who Wants to be a Homeopath OR Kaun Banega Homeopath ?
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Dangerousfuture'
                            style={{
                              color: window.location.pathname === '/Dangerousfuture' ? 'orangered' : '#000'
                            }}
                          >
                            Dangerous Future of Allopathic (GP) Practice for a Qualified Homeopath
                          </a>
                        </li>
                        <li>
                          <a
                            href='/Femalehomeopathy'
                            style={{
                              color: window.location.pathname === '/Femalehomeopathy' ? 'orangered' : '#000'
                            }}
                          >
                            Female & Homeopathic Career – Responsibilities, Opportunities & Management
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a
                        href='#'
                        style={{
                          color:
                            opneNav === 'About'
                              ? 'orangered'
                              : window.location.pathname === '/shah'
                              ? 'orangered'
                              : window.location.pathname === '/drkrutikshah'
                              ? 'orangered'
                              : window.location.pathname === '/team'
                              ? 'orangered'
                              : window.location.pathname === '/scholarships'
                              ? 'orangered'
                              : window.location.pathname === '/felicitations'
                              ? 'orangered'
                              : window.location.pathname === '/ceremony'
                              ? 'orangered'
                              : '#000'
                        }}
                        onClick={() => openCloseNav('About')}
                      >
                        <span>About</span>
                        <i className='fa fa-chevron-down'></i>
                      </a>
                      <ul style={{ display: opneNav === 'About' ? 'block' : 'none' }}>
                        <li>
                          <a
                            href='/shah'
                            style={{
                              color: window.location.pathname === '/shah' ? 'orangered' : '#000'
                            }}
                          >
                            SHAH
                          </a>
                        </li>
                        <li>
                          <a
                            href='/drkrutikshah'
                            style={{
                              color: window.location.pathname === '/drkrutikshah' ? 'orangered' : '#000'
                            }}
                          >
                            Dr. Krutik Shah
                          </a>
                        </li>
                        <li>
                          <a
                            href='/team'
                            style={{
                              color: window.location.pathname === '/team' ? 'orangered' : '#000'
                            }}
                          >
                            Team @ SHAH
                          </a>
                        </li>
                        <li>
                          <a
                            href='/scholarships'
                            style={{
                              color: window.location.pathname === '/scholarships' ? 'orangered' : '#000'
                            }}
                          >
                            Scholarships @ SHAH
                          </a>
                        </li>
                        <li>
                          <a
                            href='/inaugural/'
                            style={{
                              color: window.location.pathname === '/inaugural/' ? 'orangered' : '#000'
                            }}
                          >
                            Online Inaugural Ceremony
                          </a>
                        </li>
                        <li>
                          <a
                            href='/felicitations'
                            style={{
                              color: window.location.pathname === '/felicitations' ? 'orangered' : '#000'
                            }}
                          >
                            Post Inaugural Felicitations
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <a href='/synopsis' style={{ color: window.location.pathname === '/synopsis' && 'orangered' }}>
                        FreeTalks
                      </a>
                    </li>
                    <div
                      className=''
                      style={{ marginTop: '15px', marginBottom: '25px', display: 'flex', flexDirection: 'column' }}
                    >
                      <div
                        className='authpages'
                        style={{ margin: '0', backgroundColor: '#031633', marginBottom: '10px' }}
                      >
                        <a
                          href='/freewebinar'
                          style={{
                            textDecoration: 'none',
                            color: 'white',
                            // padding: '5px 0px',
                            fontWeight: 'bolder'
                          }}
                        >
                          Free Webinars
                        </a>
                      </div>
                      <div className='authpages' style={{ margin: '0', backgroundColor: '#031633' }}>
                        <a href='/paidwebinar' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bolder' }}>
                          Paid Webinars
                        </a>
                      </div>
                      <div
                        className='authpages'
                        style={{ marginTop: '10px', marginLeft: '0', backgroundColor: '#031633' }}
                      >
                        <a href='/qawithshah' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bolder' }}>
                          Organon with SHAH
                        </a>
                      </div>
                    </div>
                  </ul>

                  <div
                    className='authpages'
                    onClick={() => (user ? (window.location.href = '/profile') : (window.location.href = '/signup'))}
                  >
                    {user ? 'My Account' : 'Join Now'}
                  </div>
                </div>
              </Drawer>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
