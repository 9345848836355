import React, { useState, useEffect } from 'react'
import axios from 'axios'

export default function ShowMyLessons(props) {
  const [lessons, setLessons] = useState([])

  // console.log(`🚀  ~ file: ShowMyQuiz.js:7 ~ ShowMyLessons ~ lessons:`, lessons.flat().length)

  const result = []
  useEffect(() => {
    if (props.Chapters) {
      GetQuiz()
    }
  }, [props.Chapters])

  async function GetQuiz() {
    await axios
      .get('/api/course/quiz?type=front-end-api')
      .then(res => {
        const p = []

        for (let i = 0; props.Chapters.length > i; i++) {
          const FilterLessons = res.data.courses.filter(r => r.chapterid === props.Chapters[i]._id)

          if (p.length === 0) {
            for (let i = 0; FilterLessons.length > i; i++) {
              p.push(FilterLessons[i])
            }
          } else {
            const b = []
            for (let i = 0; FilterLessons.length > i; i++) {
              b.push(FilterLessons[i])
            }

            var arr3 = [...p, ...b]
            result.push(arr3)
          }
        }

        setLessons(result)
      })
      .catch(e => console.log(e))
  }

  return <> {lessons[0]?.length} </>
}
