import React, { useEffect, useState } from 'react'
import '../styles/login.scss'
import { Helmet } from 'react-helmet'
import TextField from '@material-ui/core/TextField'
import useWindowDimesions from '../components/useWindowDimesions'
import axios from 'axios'
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, Button } from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

export default function Forget() {
  const { width } = useWindowDimesions()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const [screen, setScreen] = useState(1)
  const [ConfirmOTP, setConfirmOTP] = useState('')
  const [Passwords, setPasswords] = useState('')
  const [otpSelect, setOtpSelect] = useState('')

  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  async function PasswordReset(e) {
    e.preventDefault()
    await axios
      .get(`/api/mail/update-password`, { headers: { otpSelect, email, phone: `${email}/true` } })
      .then(() => {
        setScreen(2)
      })
      .catch(e => {
        setLoading(false)
        alert(e)
      })
  }

  async function SecondNext(e) {
    e.preventDefault()
    setScreen(3)
  }

  async function ThirdNext(e) {
    e.preventDefault()
    setLoading(true)

    let body = { password_otp: ConfirmOTP, email: email, password: Passwords }
    await axios
      .post(`/api/mail/password-otp-match`, body)
      .then(res => {
        if (res.data.statusCode === 201) {
          alert(res.data.message)
          window.location.href = '/login'
        } else {
          alert(res.data.message)
          setLoading(false)
        }
      })
      .catch(e => {
        setLoading(false)
        alert(e)
      })
  }
  const [seconds, setSeconds] = useState(60)

  useEffect(() => {
    if (screen === 2) {
      if (seconds > 0) {
        const timer = setTimeout(() => setSeconds(seconds - 1), 1000)
        return () => clearTimeout(timer)
      }
    }
  }, [seconds, screen])

  const reCount = async () => {
    setSeconds(60)
    await axios
      .get(`/api/mail/update-password`, { headers: { otpSelect, email } })
      .then(() => {
        setScreen(2)
      })
      .catch(e => {
        setLoading(false)
        alert(e)
      })
  }

  return (
    <div className='signup' style={{ height: '100vh', width: '100vw', backgroundColor: '#252d4a' }}>
      <Helmet>
        <title> Forget Password | Samuel Hahnemann Academy for Homeopathy – India's Only Homeopathy E-Learning</title>
      </Helmet>
      <div className='signup-form' style={{ paddingBottom: width < 999 ? 30 : 56, height: 'auto' }}>
        <img className='logo' src='/shahlogo.svg' alt='drkrutikshah.online' style={{ marginBottom: 0 }} />

        <div className='extra'>
          <div className='left-line'></div>
          <div className='title'>Forgot</div>
          <div className='right-line'></div>
        </div>

        {screen === 1 ? (
          <form onSubmit={PasswordReset}>
            <TextField
              required
              id='outlined-required'
              className='email'
              label='Email address'
              //   defaultValue="Hello World"
              value={email}
              onChange={e => setEmail(e.target.value)}
              size='small'
              variant='outlined'
            />
            <FormControl>
              <Typography>Select OTP</Typography>
              <RadioGroup
                row
                aria-label='controlled'
                name='controlled'
                value={otpSelect}
                onChange={e => setOtpSelect(e.target.value)}
              >
                <FormControlLabel value='email' control={<Radio />} label='Email' />
                <FormControlLabel value='sms' control={<Radio />} label='SMS' />
              </RadioGroup>
            </FormControl>

            <button style={{ cursor: 'pointer' }} type='submit'>
              {loading === true ? (
                <div className='loadingio-spinner-ellipsis-wqipn74m1df'>
                  <div className='ldio-r4q1jc8bpcd'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                'Next'
              )}
            </button>
          </form>
        ) : screen === 2 ? (
          <form onSubmit={SecondNext}>
            <TextField
              required
              id='outlined-required'
              className='email'
              label='OTP Code'
              //   defaultValue="Hello World"
              value={ConfirmOTP}
              onChange={e => setConfirmOTP(e.target.value)}
              size='small'
              variant='outlined'
            />

            <button style={{ cursor: 'pointer' }} type='submit'>
              {loading === true ? (
                <div className='loadingio-spinner-ellipsis-wqipn74m1df'>
                  <div className='ldio-r4q1jc8bpcd'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                ' Confirm OTP'
              )}
            </button>
            <div style={{ marginTop: '10px' }}>
              <Button
                variant='contained'
                disabled={seconds !== 0 ? true : false}
                style={{ marginLeft: '10px' }}
                onClick={() => reCount()}
              >
                Resend {seconds !== 0 && 'in'} {seconds}
              </Button>
            </div>
          </form>
        ) : screen === 3 ? (
          <form onSubmit={ThirdNext}>
            <TextField
              required
              id='outlined-required'
              className='email'
              label='New Password'
              //   defaultValue="Hello World"
              value={Passwords}
              onChange={e => setPasswords(e.target.value)}
              size='small'
              variant='outlined'
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleTogglePasswordVisibility} edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <button style={{ cursor: 'pointer' }} type='submit'>
              {loading === true ? (
                <div className='loadingio-spinner-ellipsis-wqipn74m1df'>
                  <div className='ldio-r4q1jc8bpcd'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                'Reset Password'
              )}
            </button>
          </form>
        ) : null}

        <div
          className='extra-option'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className='options' style={{ marginLeft: -4 }}>
            <a
              href='/'
              style={{
                textDecoration: 'none',
                color: '#000',
                fontSize: 17
              }}
            >
              {' '}
              <i className='fa fa-chevron-left' style={{ marginRight: 4 }}></i>
              Back to Home
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
