import React, { useState, useEffect, Suspense, lazy } from 'react'

import '../styles/coursepage.scss'

import useWindowDimesions from '../components/useWindowDimesions'

import DangerousHTML from 'react-dangerous-html'

// import Footer from '../components/Footer'
const Footer = lazy(() => import('../components/Footer'))
// import Hero from '../components/Hero'
const Hero = lazy(() => import('../components/Hero'))
// import DesktopNavBar from '../components/DesktopNavBar'
const DesktopNavBar = lazy(() => import('../components/DesktopNavBar'))
// import MobileNavBar from '../components/MobileNavBar'
const MobileNavBar = lazy(() => import('../components/MobileNavBar'))

// import ChapterDropdown from '../components/ChapterDropdown'
const ChapterDropdown = lazy(() => import('../components/ChapterDropdown'))
// import RelatedCourses from '../components/RelatedCourses'
const RelatedCourses = lazy(() => import('../components/RelatedCourses'))
import axios from 'axios'

import { ShowMyChapters, ShowMyCoursePageLessons, ShowMyCoursePageQuiz } from '../components/ShowMyChapters'

import Modal from 'react-modal'
// import VideoOverview from '../components/VideoOverview'
const VideoOverview = lazy(() => import('../components/VideoOverview'))

import { useParams } from 'react-router-dom'
import SplashScreen from './SplashScreen'
import { Helmet } from 'react-helmet'
// const customStyles = {}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function OpenRazorPay(pay, coursename) {
  const test = process.env.REACT_APP_RAZOR_PAY_KEY
  // const prod = 'rzp_live_lzls0OUbHfd9IC'

  var options = {
    key: `${test}`, // Enter the Key ID generated from the Dashboard
    amount: `${pay * 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: 'INR',
    name: 'E-Learning @ SHAH ',
    description: `For buying this ${coursename} course`,
    handler: () => {
      alert('Payment success')
      localStorage.setItem('coursename', coursename.toString())
      window.location.href = '/purchased-course'
    },

    notes: {
      address: 'Samuel Hahnemann Academy for Homeopathy - SHAH, Anand - 388001 , Gujarat, India'
    },
    theme: {
      color: '#FF5722'
    }
  }

  const rzp1 = new window.Razorpay(options)
  rzp1.open()
}

function OpenRazorPayU(pay, coursename) {
  const test = process.env.REACT_APP_RAZOR_PAY_KEY
  // const prod = 'rzp_live_lzls0OUbHfd9IC'

  var options = {
    key: `${test}`, // Enter the Key ID generated from the Dashboard
    amount: `${pay * 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: 'USD',
    name: 'E-Learning @ SHAH ',
    description: `For buying this ${coursename} course`,
    handler: () => {
      localStorage.setItem('coursename', coursename.toString())
      window.location.href = '/purchased-course'
    },

    notes: {
      address: 'Samuel Hahnemann Academy for Homeopathy - SHAH, Anand - 388001 , Gujarat, India'
    },
    theme: {
      color: '#FF5722'
    }
  }

  const rzp1 = new window.Razorpay(options)
  rzp1.open()
}
const CoursePage = () => {
  const { width } = useWindowDimesions()
  const [Data, setData] = useState([])
  let { slug } = useParams()
  // const slug = props.match.params.slug;

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal(e) {
    localStorage.setItem('overview', e)
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const [selectCategory, setSelectCategory] = useState('')
  const [categoryerror, setCategoryError] = useState(false)

  // const user = localStorage.getItem('userId')
  const user = localStorage.getItem('mongoId')

  // function Payment(res) {
  //   return activeCourseSelection
  //     ? OpenRazorPay(res.SellingPrice, res.nameOfCourse)
  //     : OpenRazorPayU(res.SellingPrice, res.nameOfCourse)
  // }

  // const redi = activeCourseSelection ? '/login' : '/practitioner-signup'

  useEffect(() => {
    FetchData()
  }, [])

  async function FetchData() {
    await axios
      .get('/api/course?type=front-end-api')
      .then(res => {
        const FilterCourse = res.data.courses.filter(r => r.slug === slug)
        setData(FilterCourse)
      })
      .catch(e => alert(e))
  }

  function PayButton(payment, paymentD, coursename) {
    const fireid = localStorage.getItem('loginUser')
    if (selectCategory === 'Indian Students Only') {
      OpenRazorPay(payment, coursename, fireid)
    }
    if (selectCategory === 'International Students & Homeopathic Practitioner') {
      OpenRazorPayU(paymentD, coursename, fireid)
    }
    if (selectCategory === '') {
      setCategoryError(true)
      alert('Choose your category')
    }
  }

  if (width < 999) {
    return (
      <>
        <Helmet>
          <title>Course Page | E-Learning @ SHAH</title>
          <meta
            name='description'
            content='Course Page - Explore E-Learning @ SHAH, Samuel Hahnemann Academy for Homeopathy.'
          />
          <meta
            name='keywords'
            content='Be a Homeopath, E-Learning, Homeopathy, Samuel Hahnemann Academy, SHAH,Free Webinar,Paid Webinar,Paid Webinar,Browse Course, Contact,Course Page'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/',
              description: 'E-Learning @ SHAH - Explore the world of Homeopathy with Samuel Hahnemann Academy.',
              keywords:
                'E-Learning, Homeopathy, Samuel Hahnemann Academy, SHAH,Free Webinar,Paid Webinar,Browse Course, Contact, Course Page'
              // Add more structured data properties as needed
            })}
          </script>
        </Helmet>

        <Suspense fallback={<SplashScreen />}>
          {modalIsOpen ? (
            <>
              <div className='blur-overview'>
                <p onClick={closeModal}>
                  <i className='fa fa-times' aria-hidden='true'></i> Cancel
                </p>
                <div className='mpop-up-overview'>
                  <VideoOverview />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <MobileNavBar />
          {Data.map(res => (
            <>
              <Hero title={res.nameOfCourse} img={res.banners} divide={res.divide} />
              <div className='mobile-course-page'>
                <div className='first-sidebar'>
                  <div className='videoImage course-preview' style={{ opacity: 0, pointerEvents: 'none' }}>
                    <img
                      className='background'
                      src='https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg'
                      alt='UX and UI designers discussing project'
                      loading='lazy'
                      srcSet='https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg 1920w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-300x200.jpg 300w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-1024x683.jpg 1024w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-768x512.jpg 768w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-1536x1024.jpg 1536w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-600x400.jpg 600w'
                      sizes='(max-width: 1920px) 100vw, 1920px'
                    />
                    <img className='play' src='/play-button.svg' />

                    <p
                      style={{
                        fontFamily: "'Jost', sans-serif",
                        color: '#fff'
                      }}
                    >
                      Course Preview
                    </p>
                  </div>

                  <div className='course-overview'>
                    <div className='videoImage'>
                      <img
                        className='background'
                        src={
                          res.courseOverviewThumb
                            ? res.courseOverviewThumb
                            : 'https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg'
                        }
                        alt='UX and UI designers discussing project'
                        loading='lazy'
                        sizes='(max-width: 1920px) 100vw, 1920px'
                      />
                      <img onClick={() => openModal(res.overview)} className='play' src='/play-button.svg' />

                      <p
                        style={{
                          fontFamily: "'Jost', sans-serif",
                          color: '#fff'
                        }}
                      >
                        Course Overview
                      </p>
                    </div>

                    <div className='course-price'>
                      <div className='select-category'>
                        <select
                          onChange={e => {
                            setSelectCategory(e.target.value)
                            setCategoryError(false)
                          }}
                          value={selectCategory}
                          style={{ fontSize: 16, fontWeight: 'bold' }}
                        >
                          <option value=''>Choose your category </option>
                          <option value='Indian Students Only'>Indian Students Only</option>
                          <option value='International Students & Homeopathic Practitioner'>
                            Homeopathic Practitioners & International Students{' '}
                          </option>
                        </select>
                      </div>
                      {categoryerror ? (
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: 16,
                            color: 'red',
                            position: 'absolute',
                            width: '100%',
                            marginTop: -1
                          }}
                        >
                          Choose your category
                        </p>
                      ) : (
                        <></>
                      )}
                      <div style={{ marginTop: 23 }} className='price-for-indian-students'>
                        Indian Students : <b>₹{res.SellingPrice}/-</b>
                        <br />
                        Regular Price :
                        <b style={{ fontSize: 12, textDecoration: 'line-through' }}>₹{res.RegularPrice}/-</b>
                        <p style={{ fontSize: 13 }}>
                          (Promoting Make in India & Digital India Programs of Government of India)
                        </p>
                        {/* <br /> */}
                        <div
                          style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: 'black',
                            marginBottom: 12,
                            opacity: 0.6
                          }}
                        ></div>
                        <span>
                          Homeopathic Practitioners & <br /> International Students:
                          <br />
                          <b>${res.DollarSellingPrice}/-</b>
                          <br />
                          Regular Price :
                          <b
                            style={{
                              fontSize: 12,
                              textDecoration: 'line-through'
                            }}
                          >
                            ${res.DollarRegularPrice}/-
                          </b>
                        </span>
                      </div>

                      {/* {activeCourseSelection?

<>

<div style={{marginTop:23}} className="price-for-indian-students">
Selling Price : <b>₹{res.SellingPrice}/-</b>

<div className="price-for-indian-students" style={{color:'grey'}}>
Regular Price :  <strike>₹{res.SellingPrice}/-</strike>
</div>
<br/>
<p>“Make in India” Mission & <br/>Digital India Programme</p>
</div>



</>

:
<div className="price-for-indian-students">
Price : <b>${res.SellingPrice}/-</b>
<br/>
<p>For International Students, <br/>National Homeopaths <br/> & International Homeopaths only</p>
</div>} */}
                    </div>

                    {/* <p style={{position:'absolute', marginTop:'33%', fontFamily:"'Jost', sans-serif", opacity:0.5, fontSize:14}}>( Rs 7,979 INR approx )</p> */}

                    {/* <button onClick={()=>user?OpenRazorPay(res.SellingPrice,res.nameOfCourse):window.location.href="/login"} className="button-purchase-course">
Buy this course </button> */}

                    <button
                      onClick={() =>
                        user
                          ? PayButton(res.SellingPrice, res.DollarSellingPrice, res.nameOfCourse)
                          : (window.location.href = '/signup')
                      }
                      className='button-purchase-course'
                    >
                      Buy this Course{' '}
                    </button>

                    <div className='course-information'>
                      <h3>
                        {' '}
                        <i style={{ marginLeft: 35 }} className='fa fa-cc-visa' aria-hidden='true'></i>{' '}
                        <i className='fa fa-paypal' aria-hidden='true'></i>{' '}
                        <i className='fa fa-cc-mastercard' aria-hidden='true'></i>{' '}
                        <i className='fa fa-google-wallet' aria-hidden='true'></i>{' '}
                        <i className='fa fa-cc-amex' aria-hidden='true'></i>{' '}
                        <i className='fa fa-cc-discover' aria-hidden='true'></i> <br /> No Cost EMI options available{' '}
                        <br /> All prices are inclusive of taxes
                      </h3>
                    </div>
                  </div>
                </div>

                <div className='sec-sidebar extra-box-coursepage'>
                  <h2>Benefits</h2>
                  <ul>
                    <li>100% Money Return in 5 days. No Question Asked</li>
                    <li>
                      You will receive course completion Certificate and Badge of Honour by courier at your doorstep
                      worldwide.
                    </li>
                  </ul>
                </div>

                <div className='sec-sidebar'>
                  <h2>Course Features</h2>
                  {/* ___ */}
                  <div className='course-feature-list'>
                    <ul>
                      <li>
                        <label>Chapters</label>
                        <span>
                          <ShowMyChapters id={res._id} />
                        </span>
                      </li>

                      <li>
                        <label>Lessons</label>
                        <span>
                          <ShowMyCoursePageLessons id={res._id} />
                        </span>
                      </li>

                      <li>
                        <label>Quiz</label>
                        <span>
                          <ShowMyCoursePageQuiz id={res._id} />
                        </span>
                      </li>
                      {res.clinicalPoints?.toLowerCase() === 'yes' ? (
                        <li className='lp-course-info language'>
                          <label>Total Clinical Points</label>
                          <span>12</span>
                        </li>
                      ) : (
                        <></>
                      )}

                      <li className='lp-course-info language'>
                        <label>Course Certification </label>
                        <span>Fellowship</span>
                      </li>

                      <li className='lp-course-info language' style={{ paddingBottom: 25 }}>
                        <label>Final Exam </label>
                        <span
                          className='seefaq'
                          style={{ cursor: 'pointer' }}
                          onClick={() => (window.location.href = '/faqs')}
                        >
                          FAQ No. 14 & 15
                        </span>
                      </li>

                      <li className='lp-course-info language'>
                        <label>Badge of Honour </label>
                        <span>{res.BadgeHonor}</span>
                      </li>

                      <li className='lp-course-info language'>
                        <label>Live with Dr.Shah </label>
                        <span>{res.liveShah}</span>
                      </li>

                      <li className='lp-course-info language'>
                        <label>Watch Course</label>
                        <span>{res.WatchCourse}</span>
                      </li>

                      <li className='lp-course-info language'>
                        <label>Course Access</label>
                        <span>{res.CourseAccess}</span>
                      </li>

                      <li className='lp-course-info language'>
                        <label>Total E-learning Notes</label>
                        <span>{res.notes}</span>
                      </li>

                      <li className='lp-course-info language'>
                        <label>Chat with Mentor</label>
                        <span>{res.ChatwithCoach}</span>
                      </li>

                      <li className='lp-course-info language'>
                        <label>Total Duration</label>
                        <span>{res.TotalDuration}</span>
                      </li>

                      <li className='lp-course-info language'>
                        <label>Language</label>
                        <span>{res.Language} </span>
                      </li>

                      <li className='certi'>
                        <label>Skill level</label>
                        <span style={{ fontSize: 12 }}>{res.SkillLevel}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ))}
          <Footer />
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Course Page | E-Learning @ SHAH</title>
          <meta
            name='description'
            content='Course Page - Explore E-Learning @ SHAH, Samuel Hahnemann Academy for Homeopathy.'
          />
          <meta
            name='keywords'
            content='Be a Homeopath, E-Learning, Homeopathy, Samuel Hahnemann Academy, SHAH,Free Webinar,Paid Webinar,Paid Webinar,Browse Course, Contact,Course Page'
          />
          {/* Add more meta tags as needed */}
          <script type='application/ld+json'>
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Samuel Hahnemann Academy for Homeopathy',
              url: 'https://www.samuelhahnemann.org/',
              description: 'E-Learning @ SHAH - Explore the world of Homeopathy with Samuel Hahnemann Academy.',
              keywords:
                'E-Learning, Homeopathy, Samuel Hahnemann Academy, SHAH,Free Webinar,Paid Webinar,Browse Course, Contact, Course Page'
              // Add more structured data properties as needed
            })}
          </script>
        </Helmet>

        <Suspense fallback={<SplashScreen />}>
          {modalIsOpen ? (
            <>
              <div className='blur-overview'>
                <p onClick={closeModal}>
                  <i className='fa fa-times' aria-hidden='true'></i> Cancel
                </p>
                <div className='pop-up-overview'>
                  <VideoOverview />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className='desktop'>
            <DesktopNavBar />
            {Data.map(res => (
              <>
                <Hero title={res.nameOfCourse} img={res.banners} divide={res.divide} />
                <div className='full-page'>
                  <div className='forNav' style={{ opacity: 0, marginTop: -53 }}></div>
                  {/* 
<div className="vedio"><img src={res.Img} /></div> */}

                  <div className='first-sidebar'>
                    <div className='videoImage course-preview' style={{ opacity: 0, pointerEvents: 'none' }}>
                      <img
                        className='background'
                        src='https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg'
                        alt='UX and UI designers discussing project'
                        loading='lazy'
                        srcSet='https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg 1920w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-300x200.jpg 300w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-1024x683.jpg 1024w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-768x512.jpg 768w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-1536x1024.jpg 1536w, https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784-600x400.jpg 600w'
                        sizes='(max-width: 1920px) 100vw, 1920px'
                      />
                      <img className='play' src='/play-button.svg' />

                      <p
                        style={{
                          fontFamily: "'Jost', sans-serif",
                          color: '#fff'
                        }}
                      >
                        Course Preview
                      </p>
                    </div>

                    <div className='course-overview'>
                      <div className='videoImage'>
                        <img
                          className='background'
                          src={
                            res.courseOverviewThumb
                              ? res.courseOverviewThumb
                              : 'https://templatekit.jegtheme.com/edukasi/wp-content/uploads/sites/20/2020/12/ux-and-ui-designers-discussing-project-e1607398336784.jpg'
                          }
                          alt='UX and UI designers discussing project'
                          loading='lazy'
                          sizes='(max-width: 1920px) 100vw, 1920px'
                        />
                        <img onClick={() => openModal(res.overview)} className='play' src='/play-button.svg' />

                        <p
                          style={{
                            fontFamily: "'Jost', sans-serif",
                            color: '#fff'
                          }}
                        >
                          Course Overview
                        </p>
                      </div>

                      <div className='course-price'>
                        <div className='select-category'>
                          <select
                            onChange={e => {
                              setSelectCategory(e.target.value)
                              setCategoryError(false)
                            }}
                            value={selectCategory}
                            style={{ fontSize: 16, fontWeight: 'bold' }}
                          >
                            <option value=''>Choose your category</option>
                            <option value='Indian Students Only'>Indian Students Only</option>
                            <option value='International Students & Homeopathic Practitioner'>
                              Homeopathic Practitioners & International Students{' '}
                            </option>
                          </select>
                        </div>
                        {categoryerror ? (
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: 16,
                              color: 'red',
                              position: 'absolute',
                              width: '100%',
                              marginTop: -1
                            }}
                          >
                            Choose your category
                          </p>
                        ) : (
                          <></>
                        )}
                        <div style={{ marginTop: 23 }} className='price-for-indian-students'>
                          Indian Students : <b>₹{res.SellingPrice}/-</b>
                          <br />
                          Regular Price :
                          <b
                            style={{
                              fontSize: 12,
                              textDecoration: 'line-through'
                            }}
                          >
                            ₹{res.RegularPrice}/-
                          </b>
                          <p style={{ fontSize: 13 }}>
                            (Promoting Make in India & Digital India Programs of Government of India)
                          </p>
                          {/* <br /> */}
                          <div
                            style={{
                              width: '100%',
                              height: 1,
                              backgroundColor: 'black',
                              marginBottom: 12,
                              opacity: 0.6
                            }}
                          ></div>
                          <span>
                            Homeopathic Practitioners & <br /> International Students:
                            <br />
                            <b>${res.DollarSellingPrice}/-</b>
                            <br />
                            Regular Price :
                            <b
                              style={{
                                fontSize: 12,
                                textDecoration: 'line-through'
                              }}
                            >
                              ${res.DollarRegularPrice}/-
                            </b>
                          </span>
                        </div>

                        {/* {activeCourseSelection?

<>

<div style={{marginTop:23}} className="price-for-indian-students">
Selling Price : <b>₹{res.SellingPrice}/-</b>

<div className="price-for-indian-students" style={{color:'grey'}}>
Regular Price :  <strike>₹{res.SellingPrice}/-</strike>
</div>
<br/>
<p>“Make in India” Mission & <br/>Digital India Programme</p>
</div>



</>

:
<div className="price-for-indian-students">
Price : <b>${res.SellingPrice}/-</b>
<br/>
<p>For International Students, <br/>National Homeopaths <br/> & International Homeopaths only</p>
</div>} */}
                      </div>

                      {/* <p style={{position:'absolute', marginTop:'33%', fontFamily:"'Jost', sans-serif", opacity:0.5, fontSize:14}}>( Rs 7,979 INR approx )</p> */}

                      {/* <button onClick={()=>user?OpenRazorPay(res.SellingPrice,res.nameOfCourse):window.location.href="/login"} className="button-purchase-course">
Buy this course </button> */}

                      <button
                        onClick={() =>
                          user
                            ? PayButton(res.SellingPrice, res.DollarSellingPrice, res.nameOfCourse)
                            : (window.location.href = '/signup')
                        }
                        className='button-purchase-course'
                      >
                        Buy this Course{' '}
                      </button>

                      <div className='course-information'>
                        <h3>
                          {' '}
                          <i style={{ marginLeft: 35 }} className='fa fa-cc-visa' aria-hidden='true'></i>{' '}
                          <i className='fa fa-paypal' aria-hidden='true'></i>{' '}
                          <i className='fa fa-cc-mastercard' aria-hidden='true'></i>{' '}
                          <i className='fa fa-google-wallet' aria-hidden='true'></i>{' '}
                          <i className='fa fa-cc-amex' aria-hidden='true'></i>{' '}
                          <i className='fa fa-cc-discover' aria-hidden='true'></i> <br /> No Cost EMI options available{' '}
                          <br /> All prices are inclusive of taxes
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h3
                    style={{
                      marginLeft: '3%',
                      fontFamily: "'Montserrat', sans-serif",
                      fontSize: 20,
                      fontWeight: '600'
                    }}
                  >
                    Overview
                  </h3>

                  <div className='para-information overview'>
                    {/*  */}

                    <div className='combine' style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className='info'>
                        {/* <ContentExtractor html={res.decription}/> */}
                        <div className='content-source'>
                          {/* {res.description} */}
                          <DangerousHTML html={res.description} />
                        </div>
                      </div>
                    </div>

                    {/*  */}
                  </div>

                  <div className='sec-sidebar extra-box-coursepage'>
                    <h2>Benefits</h2>
                    <ul>
                      <li>100% Money Return in 5 days. No Question Asked</li>
                      <li>
                        You will receive course completion Certificate and Badge of Honour by courier at your doorstep
                        worldwide.
                      </li>
                    </ul>
                  </div>

                  <div className='sec-sidebar'>
                    <h2>Course Features</h2>
                    {/* ___ */}
                    <div className='course-feature-list'>
                      <ul>
                        <li>
                          <label>Chapters</label>
                          <span>
                            <ShowMyChapters id={res._id} />
                          </span>
                        </li>

                        <li>
                          <label>Lessons</label>
                          <span>
                            <ShowMyCoursePageLessons id={res._id} />
                          </span>
                        </li>

                        <li>
                          <label>Quiz</label>
                          <span>
                            <ShowMyCoursePageQuiz id={res._id} />
                          </span>
                        </li>
                        {res.clinicalPoints?.toLowerCase() === 'yes' ? (
                          <li className='lp-course-info language'>
                            <label>Total Clinical Points</label>
                            <span>12</span>
                          </li>
                        ) : (
                          <></>
                        )}

                        <li className='lp-course-info language'>
                          <label>Course Certification </label>
                          <span>Fellowship</span>
                        </li>

                        <li className='lp-course-info language' style={{ paddingBottom: 25 }}>
                          <label>Final Exam </label>
                          <span
                            className='seefaq'
                            style={{ cursor: 'pointer' }}
                            onClick={() => (window.location.href = '/faqs')}
                          >
                            FAQ No. 14 & 15
                          </span>
                        </li>

                        <li className='lp-course-info language'>
                          <label>Badge of Honour </label>
                          <span>{res.BadgeHonor}</span>
                        </li>

                        <li className='lp-course-info language'>
                          <label>Live with Dr.Shah </label>
                          <span>{res.liveShah}</span>
                        </li>

                        <li className='lp-course-info language'>
                          <label>Watch Course</label>
                          <span>{res.WatchCourse}</span>
                        </li>

                        <li className='lp-course-info language'>
                          <label>Course Access</label>
                          <span>{res.CourseAccess}</span>
                        </li>

                        <li className='lp-course-info language'>
                          <label>Total E-learning Notes</label>
                          <span>{res.notes}</span>
                        </li>

                        <li className='lp-course-info language'>
                          <label>Chat with Mentor</label>
                          <span>{res.ChatwithCoach}</span>
                        </li>

                        <li className='lp-course-info language'>
                          <label>Total Duration</label>
                          <span>{res.TotalDuration}</span>
                        </li>
                        <li className='lp-course-info language'>
                          <label>Internal Recorded Webinar</label>
                          <span>Yes</span>
                        </li>

                        <li className='lp-course-info language'>
                          <label>Reward on early completion</label>
                          <span>Yes</span>
                        </li>
                        <li className='lp-course-info language'>
                          <label>Language</label>
                          <span>{res.Language} </span>
                        </li>

                        <li className='certi'>
                          <label>Skill level</label>
                          <span style={{ fontSize: 12 }}>{res.SkillLevel}</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className='course'>
                    <h3
                      style={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: 20,
                        fontWeight: '600'
                      }}
                    >
                      Course Curriculum
                    </h3>
                    <div className='topics'>
                      <ChapterDropdown
                        slug={slug}
                        courseId={res._id}
                        myChapters={<ShowMyChapters id={res._id} />}
                        myLessons={<ShowMyCoursePageLessons id={res._id} />}
                        totalTime={res.TotalDuration}
                      />
                    </div>
                  </div>

                  <div className='instructor'>
                    <h3
                      style={{
                        marginBottom: 5,
                        fontFamily: "'Jost', sans-serif"
                      }}
                    >
                      Mentor
                    </h3>
                    <div className='author-reviewcontent'>
                      <div className='author'>
                        <img src='/drkrutikshah.png' />
                      </div>
                      <div className='reviewcontent'>
                        <div className='star-user'>
                          <div className='stars'></div>

                          <div className='user'></div>
                        </div>

                        <div className='author-data'>
                          <h3> Dr. Krutik Shah, M.D. (Organon of Medicine)</h3>
                          <div className='author-bio'>
                            Dr. Krutik Shah apart from receiving many national and international honours in his
                            homeopathic career, believes to be the best when he is known as “Proud Child of Dr. Samuel
                            Hahnemann.” His life and work is dedicated to the promote teachings of Dr. Samuel Hahnemann
                            in a very religious way. He always believes “Original Homeopathy is Simple Homeopathy.”
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className='sec-sidebar'
                    style={{
                      top: 0,
                      position: 'absolute',
                      paddingBottom: 15,
                      marginTop: '122em',
                      borderColor: 'orangered'
                    }}
                  >
                    <h2>
                      Save{' '}
                      <span
                        style={{
                          color: 'orangered',
                          fontFamily: "'Jost', sans-serif"
                        }}
                      >
                        60%
                      </span>{' '}
                      on Course fees if you are group of more 30 students.{' '}
                    </h2>
                    {/* ___ */}

                    <p
                      style={{
                        textAlign: 'center',
                        marginLeft: 12,
                        fontFamily: "'Jost', sans-serif"
                      }}
                    >
                      {' '}
                      Get a group access by filling the form.
                    </p>

                    <a
                      style={{
                        textAlign: 'center',
                        marginLeft: '24%',
                        fontFamily: "'Jost', sans-serif",
                        paddingBottom: 4,
                        color: 'orangered',
                        textDecoration: 'none'
                      }}
                      target='__blank'
                      href='https://5ucgovoefff.typeform.com/to/IoScLGOg'
                    >
                      Get Group Access Now
                    </a>

                    <div className='course-feature-list'></div>

                    {/* ________ */}
                  </div>

                  <div style={{ marginTop: 43 }} className='instructor'>
                    <h3
                      style={{
                        marginBottom: 5,
                        fontFamily: "'Jost', sans-serif"
                      }}
                    >
                      Mentor
                    </h3>
                    <div className='author-reviewcontent'>
                      <div className='author'>
                        <img src={res.tutorImg} />
                      </div>
                      <div className='reviewcontent'>
                        <div className='star-user'>
                          <div className='stars'></div>

                          <div className='user'></div>
                        </div>

                        <div className='author-data'>
                          <h3>{res.tutorName}</h3>
                          <div className='author-bio'>{res.tutorBio}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='form' style={{ display: 'none' }}>
                    <h3>Add a review</h3>
                    {/*  */}

                    <div className='label-input'>
                      <label>Name</label>
                      <input placeholder='Your Name' />
                      <br />

                      <label>Email</label>
                      <input placeholder='your@mail.com' />
                      <br />

                      <label>Website</label>
                      <input placeholder='Your Website' />

                      <button onClick={() => alert('Your review successfully')} className='submit-btn'>
                        Submit Review
                      </button>
                    </div>
                  </div>
                </div>
                <div className='related'>
                  <RelatedCourses name={res.nameOfCourse} category={res.category} style={'related'} />
                </div>
                <div style={{ marginLeft: -44, marginBottom: 23 }}>{/* <CTAPage/> */}</div>

                {/* <Reviews /> */}
              </>
            ))}

            <div style={{ marginTop: 413 }}>
              <Footer />
            </div>
          </div>
        </Suspense>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'E-Learning @ SHAH',
            headline: 'E-Learning on My Website',
            datePublished: '2020-08-29',
            description:
              'Welcome to the world of Original Homeopathy and I am sure that it will bea unique experience for you. It is the only e-learning program in the world giving exclusive education on the subject of Organon of Medicine and Homeopathic Philosophy',
            image: {
              '@type': 'ImageObject',
              url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
              width: 800,
              height: 600
            },
            author: {
              '@type': 'Person',
              name: 'Dr.Krutik Shah'
            },
            publisher: {
              '@type': 'Organization',
              name: 'Dr.Krutik Shah',
              logo: {
                '@type': 'ImageObject',
                url: 'https://www.samuelhahnemann.org/ms-icon-150x150.png',
                width: 200,
                height: 100
              }
            }
          })}
        </script>
      </>
    )
  }
}

export default CoursePage
