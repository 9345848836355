import React, { useEffect, useState } from 'react'
import useWindowDimesions from './useWindowDimesions'
// import { courses } from '../data/coursedata'
import '../styles/coursegrid.scss'

import CourseCard from './CourseCard'
import ChapterCard from './ChapterPurchasedCard'
import axios from 'axios'
import DangerousHTML from 'react-dangerous-html'
import SplashScreen from '../Pages/SplashScreen'

const CoursePurchased = props => {
  const { width } = useWindowDimesions()
  const [Data, setData] = useState([])

  const [wait, setWait] = useState(true)

  useEffect(() => {
    FetchData()
  }, [props.value])

  async function FetchData() {
    console.log(`🚀  ~ file: CoursePurchased.js:12 ~ CoursePurchased ~ CoursePurchased:`)
    await axios
      .get('/api/course?type=front-end-api')
      .then(async res => {
        let array = []

        res.data.courses.map(i => {
          if (props?.chapterid) {
            if (i.nameOfCourse === props?.value) {
              array.push({
                ...i,
                chapterid: props?.chapterid,
                chaptername: props?.chaptername,
                timeInChapter: props?.timeInChapter,
                chapter_thumbnail: props?.chapter_thumbnail || null
              })
            }
          } else {
            if (i.nameOfCourse.trim() === props?.value.trim()) {
              array.push(i)
            }
          }
        })

        setData(array)
        setWait(false)
      })

      .catch(e => {
        console.log(e)
        setWait(false)
      })
  }

  if (wait) {
    return <SplashScreen />
  }
  if (width > 999) {
    return (
      <>
        <div className='courses'>
          {Data.map(res => {
            if (res?.chapterid) {
              return (
                <React.Fragment key={res._id}>
                  <ChapterCard
                    TotalDuration={res.TotalDuration}
                    published={res.published}
                    purchased={true}
                    title={res.nameOfCourse}
                    SellingPrice={res.SellingPrice}
                    description={<DangerousHTML html={res.description} />}
                    slug={res.slug}
                    img={res.Img}
                    chapter_id={res.chapterid}
                    chaptername={res.chaptername}
                    chapter_thumbnail={res?.chapter_thumbnail || null}
                    chapters={res._id}
                    lessons={res.lessons}
                    rating={res.rating}
                    timeInChapter={res.timeInChapter || null}
                    key={res.id}
                  />
                </React.Fragment>
              )
            } else {
              return (
                <React.Fragment key={res._id}>
                  <CourseCard
                    TotalDuration={res.TotalDuration}
                    published={res.published}
                    purchased={true}
                    title={res.nameOfCourse}
                    SellingPrice={res.SellingPrice}
                    description={<DangerousHTML html={res.description} />}
                    slug={res.slug}
                    chapter_thumbnail={res?.chapter_thumbnail || null}
                    img={res.Img}
                    chapter_id={res.chapterid}
                    chapters={res._id}
                    lessons={res.lessons}
                    rating={res.rating}
                    key={res.id}
                  />
                </React.Fragment>
              )
            }
          })}
        </div>
      </>
    )
  } else {
    return (
      <div className='mobile-grid'>
        <div className='courses'>
          {Data.map(res => {
            if (res?.chapterid) {
              return (
                <React.Fragment key={res._id}>
                  <ChapterCard
                    TotalDuration={res.TotalDuration}
                    published={res.published}
                    purchased={true}
                    title={res.nameOfCourse}
                    SellingPrice={res.SellingPrice}
                    description={<DangerousHTML html={res.description} />}
                    slug={res.slug}
                    img={res.Img}
                    chapter_id={res.chapterid}
                    chaptername={res.chaptername}
                    chapter_thumbnail={res?.chapter_thumbnail || null}
                    chapters={res._id}
                    lessons={res.lessons}
                    rating={res.rating}
                    key={res.id}
                  />
                </React.Fragment>
              )
            } else
              return (
                <React.Fragment key={res._id}>
                  <CourseCard
                    TotalDuration={res.TotalDuration}
                    published={res.published}
                    purchased={true}
                    title={res.nameOfCourse}
                    SellingPrice={res.SellingPrice}
                    description={<DangerousHTML html={res.description} />}
                    slug={res.slug}
                    img={res.Img}
                    chapters={res._id}
                    chapter_thumbnail={res?.chapter_thumbnail || null}
                    lessons={res.lessons}
                    rating={res.rating}
                    key={res.id}
                  />
                </React.Fragment>
              )
          })}
          {/* {courses.map(res => (
            <CourseCard
              title={res.name}
              description={res.description}
              slug={res.slug}
              img={res.img}
              chapters={res.chapters}
              lessons={res.lessons}
              key={res.id}
            />
          ))} */}
        </div>
      </div>
    )
  }
}

export default CoursePurchased
