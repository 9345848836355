import React, { useMemo } from 'react'
// import { callApi } from '../../utils/api-utils'
import './quizview.scss'

// const defaults = {
//   methods: {
//     GET: {
//       method: 'GET'
//     },
//     POST: {
//       method: 'POST'
//     },
//     PUT: {
//       method: 'PUT'
//     },
//     DELETE: {
//       method: 'DELETE'
//     },
//     PATCH: {
//       method: 'PATCH'
//     }
//   }
// }

// const viewTransactionsPdf = {
//   ...defaults.methods.GET,
//   uri: '/api/course/certificate'
// }

export default function QuizViewCertificate({ isOpen, data, onClose }) {
  // const id = localStorage.getItem('userId')
  // const id = localStorage.getItem('mongoId')
  // const [isModalOpen, setIsModalOpen] = useState(false)

  // const toggleModal = () => {
  //   setIsModalOpen(!isModalOpen)
  // }

  // const handleDownloadClick = async id => {
  //   await callApi({ uriEndPoint: viewTransactionsPdf, pathParams: { id: id } })
  //     .then(res => {
  //       const url = `${process.env.REACT_APP_API_ENDPOINT}` + `/${res.data}`
  //       setTimeout(() => {
  //         window.open(url, '_blank')
  //       }, 2000)
  //     })
  //     .catch(e => console.log(e))
  // }

  const totalQuiz = useMemo(() => {
    if (data) {
      const valueDefined = data
        .map(i =>
          i?.quiz?.length && i.quiz[0]?.outOf
            ? Number(i.quiz[0]?.outOf)
            : i?.quiz[0]?.questions?.length
            ? i?.quiz[0]?.questions?.length
            : null
        )
        .filter(value => value !== null)

      return valueDefined.reduce((acc, currentValue) => acc + currentValue, 0)
    }
    return 0 // Default value if 'data' is null or undefined
  }, [data])

  const totalQuizAns = useMemo(() => {
    if (data) {
      const valueDefined = data
        .map(i => (i?.quiz?.length ? Number(i.quiz[0]?.yourScore) : null))
        .filter(value => value !== null)

      return valueDefined.reduce((acc, currentValue) => acc + currentValue, 0)
    }
    return 0 // Default value if 'data' is null or undefined
  }, [data])

  function calculatePercentage(part, whole) {
    if (part === 0 && whole === 0) {
      return 0
    }
    return (part / whole) * 100
  }

  if (!isOpen) return null
  return (
    <>
      <div className='model-container'>
        <div className='modal-content'>
          <h2 style={{ color: 'orangered' }}>Your Clinical Quiz Result Summary</h2>
          <span className='close-button' onClick={onClose}>
            &times;
          </span>
          <table className='data-table'>
            <thead>
              <tr>
                <th style={{ textAlignLast: 'center' }}>Clinical Quiz Chapter Name</th>
                <th>Scores</th>
                <th style={{ textAlignLast: 'center' }}>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>{row.chapterName}</td>
                      <td>
                        {row.quiz[0]?.outOf
                          ? row.quiz[0]?.yourScore + '/' + row.quiz[0]?.outOf
                          : row?.quiz[0]?.questions?.length
                          ? '0' + '/' + row?.quiz[0]?.questions?.length
                          : '-'}
                      </td>

                      <td>
                        {row.quiz[0]?.yourScore
                          ? calculatePercentage(Number(row.quiz[0]?.yourScore), Number(row.quiz[0]?.outOf)).toFixed(2)
                          : '0'}
                        %
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <p style={{ backgroundColor: 'yellow', padding: '1rem', border: '1px solid orangered' }}>
            Your Clinical Quiz Final Result:{' '}
            <b>
              <span style={{ color: 'orangered' }}>{calculatePercentage(totalQuizAns, totalQuiz).toFixed(2)} % </span>
            </b>
          </p>
          <span style={{ fontSize: '12px' }}>
            <b>Minimum 80% Total Score is must to avail 20% benefit in your next course purchase.</b>
          </span>
        </div>
      </div>
    </>
  )
}
