import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { Provider } from 'react-redux'
import SimpleReactLightbox from 'simple-react-lightbox'

import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import axios from 'axios'
// import WhatsApp from './components/WhatsApp'

import ReactGA from 'react-ga4'
import Popup from './components/selectStudent'

ReactGA.initialize(process.env.REACT_APP_GID)
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT

const TRACKING_ID = process.env.REACT_APP_GID // YOUR_OWN_TRACKING_ID

ReactGA.initialize(TRACKING_ID)

const root = ReactDOM.createRoot(document.getElementById('root'))
const showPopup = !document.cookie.includes('selectedOption')

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <SimpleReactLightbox>
        {showPopup ? <Popup /> : null}
        {/* <WhatsApp /> */}
        <App />
      </SimpleReactLightbox>
    </React.StrictMode>
  </Provider>
)
// ReactDOM.render(
//  ,
//   document.getElementById('root')
// )
// "proxy": "http://127.0.0.1:3030",
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
