import React, { useEffect, useRef } from 'react'

const VideoPlayer = ({ otp, playbackInfo }) => {
  const playerRef = useRef(null)

  useEffect(() => {
    if (window.VdoPlayer) {
      return loadPlayer()
    }

    const playerScript = document.createElement('script')
    playerScript.src = 'https://player.vdocipher.com/playerAssets/1.6.10/vdo.js'
    document.body.appendChild(playerScript)

    playerScript.addEventListener('load', () => {
      return loadPlayer()
    })
  }, [])

  const loadPlayer = () => {
    window.playerContainer = playerRef.current
    new window.VdoPlayer({
      otp: otp,
      playbackInfo: playbackInfo,
      theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
      container: playerRef.current
    })
  }

  return <div className='player' style={{ height: '100%', width: '100%' }} ref={playerRef}></div>
}

export default VideoPlayer
