import React, { useState, useEffect, useRef } from 'react'

import '../styles/Settings.scss'
import home from '../img/home .png'
import setting from '../img/settings.png'
import Chat from '../img/chat3.png'
import life from '../img/lifesaver.png'
import catalog from '../img/com.png'
import logout from '../img/logout.png'
import useWindowDimensions from '../components/useWindowDimesions'
// import CoursePurchased from '../components/CoursePurchased'

// const ShahChat = lazy(() => import('../components/ShahChat'))
import CoachChat from '../components/CoachChat'

import { useSocket } from '../hooks/useSocket'
import axios from 'axios'
import { callApi } from '../utils/api-utils'
import S3FileUpload from 'react-s3'
import moment from 'moment-timezone'
// import SplashScreen from './SplashScreen'

const config = {
  bucketName: 'chatshah',
  region: 'ap-south-1',
  accessKeyId: 'AKIAZTNEOKHH6A6TMGGH',
  secretAccessKey: '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'
}
const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const coachListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/staff/staff-list/:id'
}
const StaffChatEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/chat/chat/:staff_id/:student_id'
}

function Profile() {
  const [activeNavItem, setActiveNavItem] = useState('home')
  const socketReceived = useSocket(process.env.REACT_APP_API_ENDPOINT)
  const socketSend = useSocket(process.env.REACT_APP_API_ENDPOINT_ADMIN_SOCKET)
  const currentUserId = localStorage.getItem('mongoId')

  const { width } = useWindowDimensions()

  const [replyMsgData, setReplyMsgData] = useState(null)

  const [hasFocused, setHasFocused] = useState(false)
  const [fileAdd, setFileAdd] = useState(null)
  const myDivRef = useRef(null)
  const fileInputRef = useRef(null)

  const [getMessages, setGetMessages] = useState([])

  const [chatViewData, setChatViewData] = useState({})
  const [chatValue, setChatValue] = useState('')
  const [chatArray, setChatArray] = useState([])
  const [chatCloseState, setChatCloseState] = useState(true)

  const [extra, setExtra] = useState(0)
  const [spinner, setSpinner] = useState(false)
  const handleFileSelect = () => {
    if (fileAdd) {
      // If a file is already selected, and the user clicks the paperclip icon,
      // clear the selected file.
      setFileAdd(null)

      // Also, reset the file input value to allow selecting the same file again.
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    } else {
      // If no file is selected, trigger the file input by clicking it.
      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }
  }

  const chatApiFunction = async body => {
    if (socketSend) {
      socketSend.emit('frontend_event', body)
    }
    let newMessage = body.messages
    const newData = getMessages
    let newChange = []

    if ((newData?.staff_id?._id || newData?.staff_id) === chatViewData._id) {
      newChange = [...newMessage, ...newData.messages]
    } else {
      newChange = [...newData.messages]
    }

    newData.messages = newChange
    setGetMessages(newData)
    setExtra(extra + 1)

    scrollToBottom()
  }

  const viewChat = async i => {
    setChatViewData(chatArray[i])
    setChatCloseState(false)
    setSpinner(true)
    await callApi({
      uriEndPoint: StaffChatEndPoint,
      pathParams: { staff_id: chatArray[i]._id, student_id: currentUserId }
    })
      .then(res => {
        if (res?.data) {
          let data = res?.data

          if (data) {
            let value = res?.data?.messages?.reverse()
            data.messages = value || []
          }
          setGetMessages(data)
          localStorage.setItem('saveCurrentChat', JSON.stringify(data))
        } else {
          setGetMessages([])
        }
        setSpinner(false)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const sendMsg = async () => {
    if (replyMsgData) {
      const body = {
        student_id: currentUserId,
        staff_id: chatViewData._id,
        messages: [
          {
            sender_id: currentUserId,
            reply_sender_id: replyMsgData.sender_id,
            message: !replyMsgData?.reply_msg ? replyMsgData.message : replyMsgData?.reply_msg,
            file: replyMsgData.file,
            reply_id: replyMsgData.sender_id,
            reply_msg: chatValue,
            createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
          }
        ]
      }

      await chatApiFunction(body)

      setReplyMsgData(null)
      setChatValue('')
    } else if (fileAdd) {
      S3FileUpload.uploadFile(fileAdd, config).then(async data => {
        const body = {
          student_id: currentUserId,
          staff_id: chatViewData._id,
          messages: [
            {
              sender_id: currentUserId,
              message: chatValue,
              file: data.location,
              reply_id: null,
              reply_sender_id: null,
              reply_msg: null,
              createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
            }
          ]
        }

        await chatApiFunction(body)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
        setFileAdd(null)
        setChatValue('')
      })
    } else {
      if (chatValue) {
        const body = {
          student_id: currentUserId,
          staff_id: chatViewData._id,
          messages: [
            {
              sender_id: currentUserId,
              message: chatValue,
              file: null,
              reply_id: null,
              reply_msg: null,
              reply_sender_id: null,
              createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
            }
          ]
        }

        await chatApiFunction(body)
        setFileAdd(null)
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }

        setChatValue('')
      }
    }
  }

  const scrollToBottom = () => {
    if (!hasFocused) {
      // Focus on the element without scrolling if it's the first time
      if (myDivRef.current) {
        myDivRef.current.focus()
      }
      setHasFocused(true) // Set the flag to indicate that it has focused
    } else {
      if (myDivRef.current) {
        myDivRef.current.scrollTop = myDivRef.current.scrollHeight
        myDivRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const messageReply = data => {
    setReplyMsgData(data)
  }

  const handleFileChange = e => {
    const selectedFile = e.target.files[0]

    setFileAdd(selectedFile)
  }

  const connectAdminSocket = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_ENDPOINT_ADMIN_SOCKET}/api/questions`)
      .then(() => {})
      .catch(() => {})
  }

  const callApiFunction = async () => {
    await callApi({ uriEndPoint: coachListEndPoint, pathParams: { id: currentUserId } })
      .then(res => {
        const array = []
        const value = res.data
        if (value) {
          for (let i = 0; i < value.length; i++) {
            const element = value[i].staffList
            for (let j = 0; j < element.length; j++) {
              array.push(element[j])
            }
          }

          setChatArray(array)
        } else {
          setChatArray([])
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApiFunction()
  }, [])

  useEffect(() => {
    socketReceived?.on('front_end', async message => {
      if (getMessages.length !== 0) {
        const newData = getMessages
        const getItem = JSON.parse(localStorage?.getItem('saveCurrentChat') || null)

        if (message.staff_id === getItem?.staff_id?._id) {
          let newChange = []

          if ((newData?.student_id?._id || newData?.student_id) === message?.student_id) {
            newChange = [...message.messages, ...newData.messages]
          } else {
            newChange = [...newData.messages]
          }

          newData.messages = newChange

          setGetMessages(newData)
        }
        setExtra(extra + 1)
        callApiFunction()
        scrollToBottom()
      }
    })

    setExtra(extra + 1)
  }, [socketReceived, getMessages])

  useEffect(() => {
    connectAdminSocket()
  }, [])

  return (
    <>
      <div className='profile-view'>
        <div className='full-setting'>
          <div className='side'>
            <ul>
              <li
                onClick={() => {
                  window.location.href = '/'
                }}
              >
                <img src={home} />
                <a>Home</a>
              </li>
              <li
                onClick={() => {
                  setActiveNavItem('purchased')
                  window.location.href = '/purchased-course'
                }}
                className={activeNavItem === 'purchased' ? 'active' : ''}
              >
                <img src={life} />
                Purchased Courses
              </li>
              <li
                onClick={() => {
                  setActiveNavItem('browse')
                  window.location.href = '/browse-course'
                }}
                className={activeNavItem === 'browse' ? 'active' : ''}
              >
                <img src={catalog} />
                Browse Course
              </li>
              <li
                onClick={() => {
                  setActiveNavItem('chat')
                  window.location.href = '/chat'
                }}
                style={{ color: window?.location?.href?.split('/')[3] === 'chat' ? 'orangered' : '' }}
                className={activeNavItem === 'chat' ? 'active' : ''}
              >
                <img src={Chat} />
                Chat
              </li>
              <li
                onClick={() => {
                  setActiveNavItem('settings')
                  window.location.href = '/profile'
                }}
                style={{ color: window?.location?.href?.split('/')[3] === 'profile' ? 'orangered' : '' }}
                className={activeNavItem === 'settings' ? 'active' : ''}
              >
                <img src={setting} />
                Settings
              </li>
              <li
                onClick={() => {
                  localStorage.removeItem('loginUser')
                  localStorage.removeItem('studentStore')
                  localStorage.removeItem('profileInformation')
                  localStorage.removeItem('buyCourses')
                  localStorage.removeItem('userId')
                  localStorage.removeItem('mongoId')
                  localStorage.removeItem('referral_code')
                  localStorage.removeItem('saveCurrentChat')
                  localStorage.removeItem('quiz')
                  window.location.href = '/'
                }}
              >
                <img src={logout} />
                Logout
              </li>
            </ul>
          </div>

          <div className='both'>
            <div className='nav1' style={{ padding: '20px 15px' }}>
              <h4>Chat</h4>
            </div>
            <div className='both_inner' style={{ paddingLeft: width < 992 ? '0' : '1rem' }}>
              <div className={width < 992 ? (chatCloseState ? 'in-side' : 'contact-list-false') : 'in-side'}>
                <div className='list'>
                  <div className='click-btn'>
                    <div className='sidebar-1'>
                      <ul className='contact-list'>
                        {chatArray.map((i, index) => {
                          return (
                            <li
                              className='contact'
                              style={{
                                margin: '0 0.5rem',
                                padding: '1rem',
                                backgroundColor: i?._id === chatViewData?._id ? ' #2e3650' : ''
                              }}
                              key={i._id}
                              onClick={() => viewChat(index)}
                            >
                              {i?.first_name?.toLocaleUpperCase()}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='in-box'>
                <div className='nxt'>
                  <CoachChat
                    chatCloseState={chatCloseState}
                    replyMsgData={replyMsgData}
                    chatArray={chatArray}
                    viewChat={viewChat}
                    sendMsg={sendMsg}
                    spinner={spinner}
                    fileAdd={fileAdd}
                    chatViewData={chatViewData}
                    chatValue={chatValue}
                    setChatValue={setChatValue}
                    myDivRef={myDivRef}
                    messageReply={messageReply}
                    handleFileSelect={handleFileSelect}
                    setFileAdd={setFileAdd}
                    fileInputRef={fileInputRef}
                    setReplyMsgData={setReplyMsgData}
                    handleFileChange={handleFileChange}
                    getMessages={getMessages}
                    width={width}
                    closeChat={() => setChatCloseState(!chatCloseState)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
