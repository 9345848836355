import React from 'react'
// import { useAuthState } from 'react-firebase-hooks/auth'
// import { app } from './config/firebase'
// import Error from './Pages/Error'
// import SplashScreen from './Pages/SplashScreen'
import Public from './routes/Public'
import UserRoute from './routes/Public/userRoute'
// import RouteModule from './routes/RouteModule';s
import { useEffect, useRef } from 'react'
import ReactGA from 'react-ga4'

const App = () => {
  // const [loading, error] = useAuthState(app.auth())
  const userData = localStorage.getItem('mongoId')

  const ref = useRef(null)

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      // Your callback function here
    })

    observer.observe(ref.current)
    // Send a custom event
    ReactGA.event({
      category: 'Home-Page',
      action: 'Home Page',
      label: 'Home-Page' // optional
    })

    return () => {
      observer.unobserve(ref.current)
      observer.disconnect()
    }
  }, [])

  if (userData) {
    return (
      <div ref={ref}>
        <UserRoute />
      </div>
    )
  }
  return (
    <div ref={ref}>
      <Public />
    </div>
  )
}

export default App
