import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CardComponent from './CardComponent'

// import OneLine from '../../json/card-data.json'

export default function GridComponent(props) {
  const [Data, setData] = useState([])

  useEffect(() => {
    FetchData()
  }, [])

  async function FetchData() {
    console.log(`🚀  ~ file: GridComponent.js:6 ~ GridComponent ~ GridComponent:`)
    await axios
      .get('/api/course?type=front-end-api')
      .then(res => {
        // setData(res.data.courses)
        // const filter = res.data.courses.filter(r=>r.nameOfCourse.toLowerCase().includes("hahnemann’s"))

        // console.log(filter)

        const datas = res.data.courses
        datas.sort(function (a, b) {
          let keyA = a.serial
          let keyB = b.serial

          if (keyA < keyB) {
            return -1
          }
          if (keyA > keyB) {
            return 1
          }
          return 0
        })

        setData(datas)
      })
      .catch(e => alert(e))
  }

  return (
    <>
      {props.name ? (
        <div
          className='content-backgrounds'
          style={{ marginTop: '0em', marginBottom: '0em', backgroundColor: '#f2f6f9' }}
        >
          {/* <div className='title'><div style={{width:'68%'}}>{props.title} <span onClick={()=>window.location.href=`${props.url}`}>Read More</span> </div></div> */}
          <div className='grid-card'>
            {Data.map(res => {
              res.nameOfCourse = res.nameOfCourse
                .replace('Part – A: Theory Part — 1 :', ':')
                .replace('Part – B: Practical Part — 1 :', ':')
                .replace('Part – B: Practical Part — 2:', ':')
                .replace('Part – B: Practical Part — 3 :', ':')
                .replace('Part – B: Total Practical Part :', ':')
                .replace('KBH : 1 - ', '')
                .replace('KBH : 2 - ', '')
              return (
                <React.Fragment key={res._id}>
                  {res.nameOfCourse !== props.name ? (
                    res.category === props.category ? (
                      <CardComponent
                        Img={res.Img}
                        one_line={res.one_line_sentence}
                        nameOfCourse={res.nameOfCourse}
                        TotalDuration={res.TotalDuration}
                        chapters={res._id}
                        lessons={res.lessons}
                        slug={res.slug}
                        published={res.published}
                        web={props.web}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      ) : (
        <div className='content-background'>
          <div className='title'>
            <div style={{ width: '68%' }}>
              {props.title} <span onClick={() => (window.location.href = `${props.url}`)}>Read More</span>{' '}
            </div>
          </div>
          <div className='grid-card'>
            {Data.map(res => {
              res.nameOfCourse = res.nameOfCourse
                .replace('Part – A: Theory Part — 1 :', ':')
                .replace('Part – B: Practical Part — 1 :', ':')
                .replace('Part – B: Practical Part — 2:', ':')
                .replace('Part – B: Practical Part — 3 :', ':')
                .replace('Part – B: Total Practical Part :', ':')
                .replace('KBH : 1 - ', '')
                .replace('KBH : 2 - ', '')
              return (
                <>
                  {res.category === props.category ? (
                    <CardComponent
                      Img={res.Img}
                      nameOfCourse={res.nameOfCourse}
                      TotalDuration={res.TotalDuration}
                      chapters={res._id}
                      lessons={res.lessons}
                      one_line={res.one_line_sentence}
                      slug={res.slug}
                      published={res.published}
                    />
                  ) : (
                    // <CourseCard
                    //   published={res.published}
                    //   title={res.nameOfCourse}
                    //   SellingPrice={res.SellingPrice}
                    //   description={<DangerousHTML html={res.description} />}
                    //   slug={res.slug}
                    //   img={res.Img}
                    //   chapters={res._id}
                    //   lessons={res.lessons}
                    //   key={res.id}
                    // />
                    <></>
                  )}
                </>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
