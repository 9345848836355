import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

// const config = {
//   apiKey: 'AIzaSyDBxEf8xe67HsPGYUSMegakOXjzRtaR2XM',
//   authDomain: 'shahlms.firebaseapp.com',
//   projectId: 'shahlms',
//   storageBucket: 'shahlms.appspot.com',
//   messagingSenderId: '247464246653',
//   appId: '1:247464246653:web:3ea0a90bcdc8bf9dabe014',
//   measurementId: 'G-9E80F20FX0'
// }

// const config = {
//   apiKey: 'AIzaSyDOelALGxfmXcpSkXpbfXKXC9Jaz2oLAlc',
//   authDomain: 'shahlmsnew.firebaseapp.com',
//   databaseURL: 'https://shahlmsnew-default-rtdb.firebaseio.com',
//   projectId: 'shahlmsnew',
//   storageBucket: 'shahlmsnew.appspot.com',
//   messagingSenderId: '599034660355',
//   appId: '1:599034660355:web:f380defc83a1005bd67036',
//   measurementId: 'G-D49R71PSBC'
// }

const config = {
  apiKey: 'AIzaSyBpF_cd89dMH5enxdjO_LKMrKUbx7mLprE',
  authDomain: 'demotesting-ef508.firebaseapp.com',
  projectId: 'demotesting-ef508',
  storageBucket: 'demotesting-ef508.appspot.com',
  messagingSenderId: '1007320401475',
  appId: '1:1007320401475:web:1de2e6d384d47d1a1e36f6',
  measurementId: 'G-ZKZNMQJ2FQ'
}

const app = firebase.initializeApp(config)
const storage = firebase.storage()

export { app, storage }
