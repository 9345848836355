import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import pic from '../img/user-profile.png'
import moment from 'moment-timezone'
import { callApi } from '../utils/api-utils'
import { RWebShare } from 'react-web-share'
// import { MenuItem, Select } from '@material-ui/core'
import S3FileUpload from 'react-s3'
window.Buffer = window.Buffer || require('buffer').Buffer

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const updateEndpoint = {
  ...defaults.methods.POST,
  uri: '/api/user/signup',
  headerProps: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }
}

export default function UserSettingsName() {
  const config = {
    bucketName: 'usershah',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZTNEOKHH6A6TMGGH',
    secretAccessKey: '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'
  }
  const [name, setName] = useState(null)
  const [phone, setPhone] = useState(null)
  const [phoneAlt, setPhoneAlt] = useState(null)
  const [email, setEmail] = useState(null)

  const [studentType, setStudentType] = useState('')

  const [date, setDate] = useState(null)
  const [picture, setPicture] = useState(null)
  const [imgData, setImgData] = useState(null)
  const [numberValidation, setNumberValidation] = useState(null)
  const [nameValidation, setNameValidation] = useState(null)
  const [genderValue, setGenderValue] = useState(null)

  const [academicYearPersuing, setAcademicYearPersuing] = useState(null)
  const [collageName, setCollageName] = useState(null)
  const [collegeCity, setCollegeCity] = useState(null)
  const [studentCity, setStudentCity] = useState(null)
  const [collageWebsite, setCollageWebsite] = useState(null)
  const [facultyDesignation, setFacultyDesignation] = useState(null)
  const [collegeState, setCollegeState] = useState(null)
  const [studentState, setStudentState] = useState(null)
  const [homePincode, setHomePincode] = useState(null)
  const [collagePincode, setCollagePincode] = useState(null)
  const [emailConfirm, setEmailConfirm] = useState(null)
  const [reference, setReference] = useState(null)
  const [collageAddress, setCollageAddress] = useState(null)
  const [studentAddress, setStudentAddress] = useState(null)
  const referralID = localStorage.getItem('referral_code')
  const [salutation, setSalutation] = useState('Dr')
  const [myPoints, setMyPoints] = useState(null)

  useEffect(() => {
    getData()
  }, [])

  const id = localStorage.getItem('mongoId')

  async function getData() {
    const userInformationData = localStorage.getItem('profileInformation')

    const parseSlugBuyData = JSON.parse(userInformationData)

    console.log(`🚀  ~ file: UserSettingsName.js:90 ~ getData ~ parseSlugBuyData:`, parseSlugBuyData)

    let phone = ''
    if (parseSlugBuyData.phone.startsWith('91')) {
      phone = parseSlugBuyData.phone.slice(2)
    } else {
      phone = parseSlugBuyData.phone
    }
    setSalutation(parseSlugBuyData?.salutation || parseSlugBuyData?.salutation || 'Dr')
    setName(parseSlugBuyData?.legalName || parseSlugBuyData?.legal_name || '')
    setEmail(parseSlugBuyData?.Email || parseSlugBuyData?.email || '')
    setEmailConfirm(parseSlugBuyData?.Email || parseSlugBuyData?.email || '')
    setPhone(parseSlugBuyData?.PhoneNumber || phone || '')
    setPhoneAlt(parseSlugBuyData?.AlternatePhone || parseSlugBuyData?.alternate_phone || '')
    setGenderValue(parseSlugBuyData?.gender?.toLowerCase() || '')
    setDate(parseSlugBuyData?.Dob || parseSlugBuyData?.dob || '')
    setStudentAddress(parseSlugBuyData?.customerAddress || parseSlugBuyData?.customer_address || '')
    setImgData(parseSlugBuyData?.profilepic || '')
    setPicture(parseSlugBuyData?.profile_pic ? parseSlugBuyData?.profile_pic : null)

    setAcademicYearPersuing(parseSlugBuyData?.academic_year_persuing || '')
    setMyPoints(parseSlugBuyData?.coin || null)
    if (parseSlugBuyData?.student_select === 'international_homeopathic_students') {
      setCollageWebsite(parseSlugBuyData?.website || parseSlugBuyData.collage_website || '')
      setCollageName(parseSlugBuyData?.homeopathAssociate || parseSlugBuyData?.homeopath_associate || '')
      setCollegeCity(parseSlugBuyData?.clinic_city || '')
      setCollagePincode(parseSlugBuyData?.clinics_postal_code || '')
      setCollegeState(parseSlugBuyData?.clinic_state || '')
      setCollageAddress(parseSlugBuyData?.clinicAddress || parseSlugBuyData?.clinic_address || '')
    } else if (parseSlugBuyData?.student_select === 'indian_homeopathic_practitioners') {
      setCollageWebsite(parseSlugBuyData?.collage_website || '')
      setCollageName(parseSlugBuyData?.homeopath_associate || '')
      setFacultyDesignation(parseSlugBuyData?.faculty_designation || '')
      setCollegeCity(parseSlugBuyData?.clinic_city || '')
      setCollagePincode(parseSlugBuyData?.clinics_postal_code || '')
      setCollegeState(parseSlugBuyData?.clinic_state || '')
      setCollageAddress(parseSlugBuyData?.clinic_address || '')
    } else {
      setHomePincode(parseSlugBuyData?.customer_pincode || '')
      setStudentCity(parseSlugBuyData?.customer_city || '')
      setStudentAddress(parseSlugBuyData?.customer_address || '')
      setStudentState(parseSlugBuyData?.customer_state || '')
      setCollageName(parseSlugBuyData?.college_name || '')
      setCollegeCity(parseSlugBuyData?.college_city || '')
      setCollageWebsite(parseSlugBuyData?.collage_website || '')
      setCollagePincode(parseSlugBuyData?.collage_pincode || '')
      setCollegeState(parseSlugBuyData?.college_state || '')
      setCollageAddress(parseSlugBuyData?.college_address || '')
    }
    setStudentType(parseSlugBuyData?.student_select || 'indian_students')
    setReference(parseSlugBuyData?.references || '')
  }

  var nameRegex = /^[a-zA-Z\s]*$/
  var phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10,15}$/

  async function UpdateOne(e) {
    e.preventDefault()

    const nameValid = nameRegex.test(name)

    const phoneValid = phoneRegex.test(phone)
    let altPhoneValid = phoneRegex.test(phoneAlt)
    if (phoneAlt == null || phoneAlt?.length == 0 || phoneAlt === '') {
      altPhoneValid = true
    }
    setNumberValidation(phoneValid)
    setNameValidation(nameValid)

    if (
      name?.length !== 0 &&
      phone?.length !== 0 &&
      nameValid === true &&
      phoneValid === true &&
      altPhoneValid === true &&
      email === emailConfirm
    ) {
      let body = {}

      if (studentType === 'international_homeopathic_students') {
        body = {
          id: id,
          legal_name: name,
          phone: phone,
          gender: genderValue,
          dob: date,
          alternate_phone: phoneAlt || '',
          collage_website: collageWebsite,
          clinic_address: collageAddress,
          profile_pic: picture,
          email: email,
          homeopath_associate: collageName,
          clinic_city: collegeCity,
          clinic_state: collegeState,
          clinics_postal_code: collagePincode,
          student_select: studentType,
          verified: true,
          salutation: salutation,
          references: reference || '',
          is_register: 'false'
        }
      } else if (studentType === 'indian_homeopathic_practitioners') {
        body = {
          id: id,
          legal_name: name,
          phone: phone,
          gender: genderValue,
          dob: date,
          alternate_phone: phoneAlt || '',
          collage_website: collageWebsite,
          clinic_address: collageAddress,
          profile_pic: picture,
          email: email,
          homeopath_associate: collageName || '',
          faculty_designation: facultyDesignation || '',
          clinic_city: collegeCity,
          clinic_state: collegeState,
          clinics_postal_code: collagePincode,
          student_select: studentType,
          verified: true,
          references: reference || '',
          is_register: false
        }
      } else {
        body = {
          id: id,
          legal_name: name,
          phone: phone,
          gender: genderValue,
          dob: date,
          alternate_phone: phoneAlt || '',
          customer_address: studentAddress,
          customer_city: studentCity,
          customer_state: studentState,
          customer_pincode: homePincode,
          profile_pic: picture,
          email: email,
          academic_year_persuing: academicYearPersuing,
          college_name: collageName,
          college_city: collegeCity,
          college_state: collegeState,
          college_address: collageAddress,
          collage_pincode: collagePincode,
          collage_website: collageWebsite,
          student_select: studentType,
          verified: true,
          references: reference || '',
          is_register: false
        }
      }

      try {
        await callApi({ uriEndPoint: updateEndpoint, body }).then(res => {
          console.log('res', res)

          if (res.statusCode === 201) {
            const stringifyDataStudent = JSON.stringify(res.data)
            localStorage.setItem('profileInformation', stringifyDataStudent)
            alert('User updated successfully')
          }
        })
      } catch (error) {
        alert('Form Validation Error ')
      }
    } else {
      alert('Form Validation Error ')
    }
  }

  const onChangePicture = e => {
    const selectedFile = e.target.files[0]

    if (selectedFile) {
      S3FileUpload.uploadFile(selectedFile, config)
        .then(data => {
          setPicture(data.location)
        })
        .catch(error => {
          console.error('Error creating folder:', error)
        })
    }
  }

  const NameOnchange = e => {
    if (nameValidation === false) {
      const nameValid = nameRegex.test(e)
      setNameValidation(nameValid)
    }
    setName(e)
  }

  const PhoneOnChange = e => {
    if (numberValidation === false) {
      const phoneValid = phoneRegex.test(e)
      setNumberValidation(phoneValid)
    }
    setPhone(e)
  }

  const AltPhoneOnChange = e => {
    if (numberValidation === false) {
      const phoneValid = phoneRegex.test(e)
      setNumberValidation(phoneValid)
    }
    setPhoneAlt(e)
  }

  const fileInputRef = useRef(null)

  const handleEditPhoto = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <>
      <div className='one-one'>
        <div className='user-setting-header'>
          <h4> Personal Information</h4>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <RWebShare
              data={{
                text: 'Yes I am proud to be a Homeopath',
                url: `https://www.samuelhahnemann.org/signup?referral_code=${referralID}`,
                title: 'samuelhahnemann'
              }}
              onClick={() => console.log('shared successfully!')}
            >
              <div>
                <i className='fa fa-share mobile-button' aria-hidden='true' style={{ marginRight: '10px' }}></i>

                <button className='share-button' style={{ margin: '0' }}>
                  <span>Share referral code</span>
                </button>
              </div>
            </RWebShare>
            {myPoints && <div style={{ margin: '0 1rem' }}>My Points {myPoints}</div>}
          </div>
        </div>

        <form onSubmit={UpdateOne} className='information-form'>
          <div className='pic-input' style={{ width: '100%' }}>
            <div className='photo'>
              <img src={picture === null ? imgData || pic : picture} alt='Profile' />
              <div className='edit-button' onClick={handleEditPhoto}>
                <i className='fa fa-pencil' aria-hidden='true'></i>
              </div>

              <input
                ref={fileInputRef}
                type='file'
                style={{ display: 'none' }}
                onChange={onChangePicture}
                placeholder='Image'
                accept='image/png,image/jpeg'
              />
            </div>

            <div className='full-name'>
              <div class='container' style={{ maxHeight: '600px', overflowY: 'auto', paddingRight: '1rem' }}>
                <div className='form-section'>
                  <div className='row input-field-row'>
                    {/* {studentType === 'international_homeopathic_students' && (
                      <div className='input-field-2 col-6'>
                        <span style={{ marginRight: 'auto', fontSize: '12px' }}> Salutation</span>
                        <Select
                          label='ISD Code'
                          value={salutation}
                          required
                          id='demo-simple-select-outlined'
                          labelId='demo-simple-select-outlined-label'
                          onChange={event => setSalutation(event.target.value)}
                        >
                          <MenuItem value='Dr'>Dr.</MenuItem>
                          <MenuItem value='Ms'>Ms.</MenuItem>
                          <MenuItem value='Mr'>Mr.</MenuItem>
                          <MenuItem value='Mr'>Mr.</MenuItem>
                          <MenuItem value='Mrs'>Mrs.</MenuItem>
                          <MenuItem value='Prof'>Prof.</MenuItem>
                        </Select>
                      </div>
                    )} */}
                    <div className='input-field col-6'>
                      <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                        Legal Name<span style={{ color: 'orangered' }}>*</span>
                      </span>
                      <input
                        value={name}
                        onChange={e => NameOnchange(e.target.value)}
                        maxLength={50}
                        placeholder='Your Full Name...'
                        required
                      ></input>
                      {nameValidation === false && (
                        <span
                          style={{
                            fontSize: '10px',
                            color: 'red',
                            marginTop: '-10px',
                            marginBottom: '5px',
                            textAlign: 'start',
                            marginRight: 'auto'
                          }}
                        >
                          Only Text Allow
                        </span>
                      )}
                    </div>

                    <div className='input-field col-6'>
                      <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                        Mobile Number with Country Code<span style={{ color: 'orangered' }}>*</span>
                      </span>
                      <input
                        value={phone}
                        input
                        maxLength={15}
                        type='tel'
                        onChange={e => PhoneOnChange(e.target.value)}
                        placeholder='Your Phone Number...'
                        required
                      ></input>
                      {numberValidation === false && (
                        <span
                          style={{
                            fontSize: '10px',
                            color: 'red',
                            marginTop: '-10px',
                            marginBottom: '5px',
                            textAlign: 'start',
                            marginRight: 'auto'
                          }}
                        >
                          Only Number Allow and maximum 10 Number Allow
                        </span>
                      )}
                    </div>
                    <div className='input-field col-6'>
                      <span style={{ marginRight: 'auto', fontSize: '12px' }}>Alternate Phone</span>
                      <input
                        value={phoneAlt}
                        input
                        maxLength={15}
                        type='tel'
                        onChange={e => AltPhoneOnChange(e.target.value)}
                        placeholder='Alternate Phone Number...'
                      ></input>
                      {numberValidation === false && (
                        <span
                          style={{
                            fontSize: '10px',
                            color: 'red',
                            marginTop: '-10px',
                            marginBottom: '5px',
                            textAlign: 'start',
                            marginRight: 'auto'
                          }}
                        >
                          Only Number Allow and maximum 10 Number Allow
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Mail */}
                  <div className='row'>
                    <div className='input-field col-6'>
                      <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                        Email<span style={{ color: 'orangered' }}>*</span>
                      </span>
                      <input
                        value={email}
                        disabled
                        type='email'
                        onChange={e => setEmail(e.target.value)}
                        placeholder='Your Email..'
                      ></input>
                    </div>
                    <div className='input-field col-6'>
                      <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                        Alternate Email<span style={{ color: 'orangered' }}>*</span>
                      </span>
                      <input
                        value={emailConfirm}
                        type='email'
                        onChange={e => setEmailConfirm(e.target.value)}
                        placeholder='Confirm Your Email..'
                        required
                      ></input>
                    </div>
                  </div>

                  <div style={{ margin: '1rem 0' }}>
                    <span style={{ fontFamily: "'Jost', sans-serif" }}>
                      (Please note that all your educational communication with SHAH will be directed to this email
                      address.){' '}
                    </span>
                  </div>

                  {/* BDAY AND GENDER */}
                  <div className='row'>
                    <div className='input-field col-6'>
                      <span style={{ marginRight: 'auto', fontSize: '12px' }}>Birth Date</span>
                      <input
                        value={date}
                        max={moment().format('YYYY-MM-DD')}
                        type='date'
                        onChange={e => setDate(e.target.value)}
                        placeholder='Date'
                      ></input>
                    </div>

                    <div className='input-field col-6'>
                      <span style={{ marginRight: 'auto', fontSize: '12px' }}>Gender</span>
                      <select
                        name='Gander'
                        value={genderValue}
                        id='Gander-select'
                        onChange={e => setGenderValue(e.target.value)}
                        style={{ width: '100%', padding: '8px', marginBottom: '15px', border: '1px solid #dbe2e8' }}
                      >
                        <option value=''>--Choose Gender--</option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </select>
                    </div>
                  </div>
                </div>

                {studentType === 'indian_students' && (
                  <div className='first-form-section'>
                    <fieldset style={{ border: '1px solid rgb(0,0,0,.25)', borderRadius: '5px', marginTop: '15px' }}>
                      <legend style={{ fontSize: '14px', color: 'orangered' }}>College Details </legend>
                      <div className='row'>
                        {/* Collage name */}
                        <div className='input-field col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Name<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collageName}
                            input
                            type='text'
                            onChange={e => setCollageName(e.target.value)}
                            placeholder='Your Collage Name...'
                            required
                          ></input>
                        </div>
                        {/* Academnic Year Pursuing */}
                        <div className='input-field col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Your Academic Year Pursuing<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={academicYearPersuing}
                            input
                            maxLength={60}
                            onChange={e => setAcademicYearPersuing(e.target.value)}
                            placeholder='Eg: B.H.M.S. 2nd Year OR M.D. - Part: 1...'
                            required
                          ></input>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='input-field col-6' style={{ width: '100%' }}>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Address<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <textarea
                            style={{ width: '100%', marginBottom: '15px', border: '1px solid #dbe2e8' }}
                            value={collageAddress}
                            rows={6}
                            onChange={e => setCollageAddress(e.target.value)}
                            placeholder='Samuel Hahnemann Academy for Homeopathy - SHAH, R.S.Platinum Complex, Beside Shubham Party Plot, Opp. Raghuvir City Center, Bhalej Road, Anand 388001, Gujarat, India.'
                            required
                          ></textarea>
                          <span style={{ fontSize: '10px', marginBottom: '15px' }}></span>
                        </div>
                      </div>
                      {/* Collage City */}
                      <div className='row'>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            City<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collegeCity}
                            type='text'
                            onChange={e => setCollegeCity(e.target.value)}
                            placeholder='Your City..'
                          ></input>
                        </div>

                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            State<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collegeState}
                            type='text'
                            onChange={e => setCollegeState(e.target.value)}
                            placeholder='Your State..'
                          ></input>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Pincode<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collagePincode}
                            type='text'
                            onChange={e => setCollagePincode(e.target.value)}
                            placeholder='Pincode..'
                          ></input>
                        </div>

                        <div className='input-field col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>College Website</span>
                          <input
                            value={collageWebsite}
                            type='text'
                            onChange={e => setCollageWebsite(e.target.value)}
                            placeholder='Your College Website..'
                          ></input>
                        </div>
                      </div>
                    </fieldset>
                    {/* Student City and State */}
                    <fieldset style={{ border: '1px solid rgb(0,0,0,.25)', borderRadius: '5px', marginTop: '15px' }}>
                      <legend style={{ fontSize: '14px', color: 'orangered' }}>Residential Details</legend>
                      <div className='row'>
                        <div className='input-field col-6' style={{ width: '100%' }}>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Address<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <textarea
                            style={{ width: '100%', marginBottom: '15px', border: '1px solid #dbe2e8' }}
                            value={studentAddress}
                            rows={6}
                            onChange={e => setStudentAddress(e.target.value)}
                            placeholder='Samuel Hahnemann Academy for Homeopathy - SHAH, R.S.Platinum Complex, Beside Shubham Party Plot, Opp. Raghuvir City Center, Bhalej Road, Anand 388001, Gujarat, India.'
                            required
                          ></textarea>
                          <span style={{ fontSize: '10px', marginBottom: '15px' }}></span>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            City<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={studentCity}
                            type='text'
                            onChange={e => setStudentCity(e.target.value)}
                            placeholder='Your City..'
                          ></input>
                        </div>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            State<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={studentState}
                            type='text'
                            onChange={e => setStudentState(e.target.value)}
                            placeholder='Your State..'
                          ></input>
                        </div>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Pincode<span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={homePincode}
                            type='text'
                            onChange={e => setHomePincode(e.target.value)}
                            placeholder='Pincode..'
                          ></input>
                        </div>
                      </div>
                      {/* Student Address */}
                    </fieldset>
                    <fieldset style={{ border: '1px solid rgb(0,0,0,.25)', borderRadius: '5px', marginTop: '15px' }}>
                      <legend style={{ fontSize: '14px', color: 'orangered' }}>
                        How did you hear about E-Learning @ SHAH:{' '}
                      </legend>
                      <div className='row'>
                        <div className='input-field col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>References</span>
                          <input
                            value={reference}
                            type='text'
                            onChange={e => setReference(e.target.value)}
                            placeholder='For Example - Webinar of Dr. Krutik Shah, Social Media etc..'
                          ></input>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}

                {studentType === 'international_homeopathic_students' && (
                  <div className='section-form-section'>
                    <div className='row'>
                      <div className='input-field col-6'>
                        <span style={{ marginRight: 'auto', fontSize: '12px' }}>Website</span>
                        <input
                          value={collageWebsite}
                          input
                          type='text'
                          onChange={e => setCollageWebsite(e.target.value)}
                          placeholder=''
                        ></input>
                      </div>

                      <div className='input-field col-6'>
                        <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                          Homeopathic Institution if associated with
                        </span>
                        <input
                          value={collageName}
                          input
                          type='text'
                          onChange={e => setCollageName(e.target.value)}
                          placeholder='Name of Homeopathic Institution if associated with ...'
                        ></input>
                      </div>
                    </div>
                    <fieldset style={{ border: '1px solid rgb(0,0,0,.25)', borderRadius: '5px' }}>
                      <legend style={{ fontSize: '14px', color: 'orangered' }}>Clinic Address</legend>
                      <div className='row'>
                        <div className='input-field col-6' style={{ width: '100%' }}>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Clinic Address
                            <span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <textarea
                            style={{ width: '100%', marginBottom: '15px', border: '1px solid #dbe2e8' }}
                            value={collageAddress}
                            rows={6}
                            onChange={e => setCollageAddress(e.target.value)}
                            placeholder='Samuel Hahnemann Academy for Homeopathy - SHAH, R.S.Platinum Complex, Beside Shubham Party Plot, Opp. Raghuvir City Center, Bhalej Road, Anand 388001, Gujarat, India.'
                            required
                          ></textarea>
                          <span style={{ fontSize: '10px', marginBottom: '15px' }}></span>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            City <span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collegeCity}
                            type='text'
                            onChange={e => setCollegeCity(e.target.value)}
                            placeholder='Your City..'
                            required
                          ></input>
                        </div>

                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            State <span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collegeState}
                            type='text'
                            onChange={e => setCollegeState(e.target.value)}
                            placeholder='Your State..'
                            required
                          ></input>
                        </div>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Postal Code <span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collagePincode}
                            type='text'
                            onChange={e => setCollagePincode(e.target.value)}
                            onInput={e => e.target.value == e.target.value.replace(/[^0-9]/g, '')}
                            placeholder='Postal Code'
                            required
                          ></input>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset style={{ border: '1px solid rgb(0,0,0,.25)', borderRadius: '5px', marginTop: '15px' }}>
                      <legend style={{ fontSize: '14px', color: 'orangered' }}>
                        How did you hear about E-Learning @ SHAH:{' '}
                      </legend>
                      <div className='row'>
                        <div className='input-field col-6'>
                          {/* <span style={{ marginRight: 'auto', fontSize: '12px' }}>References</span> */}
                          <input
                            value={reference}
                            type='text'
                            onChange={e => setReference(e.target.value)}
                            placeholder='For Example - Webinar of Dr. Krutik Shah, Social Media etc..'
                          ></input>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}

                {studentType === 'indian_homeopathic_practitioners' && (
                  <div className='Third-form-section'>
                    <div className='row'>
                      <div className='input-field col-6'>
                        <span style={{ marginRight: 'auto', fontSize: '12px' }}>College Name if associated with</span>
                        <input
                          value={collageName}
                          input
                          type='text'
                          onChange={e => setCollageName(e.target.value)}
                          placeholder='Your College Name...'
                        ></input>
                      </div>
                      <div className='input-field col-6'>
                        <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                          Faculty Designation in the College
                        </span>
                        <input
                          value={facultyDesignation}
                          type='text'
                          onChange={e => setFacultyDesignation(e.target.value)}
                          placeholder='For Example - Assistant Professor in Organon of Medicine'
                        ></input>
                      </div>
                    </div>
                    <div className='row'>
                      {/* <div className='input-field col-6'>
                        <span style={{ marginRight: 'auto', fontSize: '12px' }}>Website</span>
                        <input
                          value={collageWebsite}
                          input
                          type='tel'
                          onChange={e => setCollageWebsite(e.target.value)}
                          placeholder=''
                        ></input>
                      </div> */}
                      {/* <div className='input-field col-6' style={{ visibility: 'hidden' }}>
                        <span style={{ marginRight: 'auto', fontSize: '12px' }}>References</span>
                        <input
                          value={reference}
                          type='text'
                          onChange={e => setReference(e.target.value)}
                          placeholder='For Example - Webinar of Dr. Krutik Shah, Social Media etc..'
                        ></input>
                      </div> */}
                    </div>

                    <fieldset style={{ border: '1px solid rgb(0,0,0,.25)', borderRadius: '5px' }}>
                      <legend style={{ fontSize: '14px', color: 'orangered' }}>Clinic or Residential Address</legend>
                      <div className='row'>
                        <div className='input-field col-6' style={{ width: '100%' }}>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Address <span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <textarea
                            style={{ width: '100%', marginBottom: '15px', border: '1px solid #dbe2e8' }}
                            value={collageAddress}
                            rows={6}
                            onChange={e => setCollageAddress(e.target.value)}
                            placeholder='Samuel Hahnemann Academy for Homeopathy - SHAH, R.S.Platinum Complex, Beside Shubham Party Plot, Opp. Raghuvir City Center, Bhalej Road, Anand 388001, Gujarat, India.'
                            required
                          ></textarea>
                          <span style={{ fontSize: '10px', marginBottom: '15px' }}></span>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            City <span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collegeCity}
                            type='text'
                            onChange={e => setCollegeCity(e.target.value)}
                            placeholder='Your City..'
                            required
                          ></input>
                        </div>

                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            State <span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collegeState}
                            type='text'
                            onChange={e => setCollegeState(e.target.value)}
                            placeholder='Your State..'
                            required
                          ></input>
                        </div>
                        <div className='input-field-1 col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>
                            Pincode <span style={{ color: 'orangered' }}>*</span>
                          </span>
                          <input
                            value={collagePincode}
                            type='text'
                            onChange={e => setCollagePincode(e.target.value)}
                            placeholder='Pincode..'
                            required
                          ></input>
                        </div>
                        <div className='input-field col-6'>
                          <span style={{ marginRight: 'auto', fontSize: '12px' }}>Website</span>
                          <input
                            value={collageWebsite}
                            input
                            type='text'
                            onChange={e => setCollageWebsite(e.target.value)}
                            placeholder=''
                          ></input>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset style={{ border: '1px solid rgb(0,0,0,.25)', borderRadius: '5px', marginTop: '15px' }}>
                      <legend style={{ fontSize: '14px', color: 'orangered' }}>
                        How did you hear about E-Learning @ SHAH:{' '}
                      </legend>
                      <div className='row'>
                        <div className='input-field col-6'>
                          {/* <span style={{ marginRight: 'auto', fontSize: '12px' }}>References</span> */}
                          <input
                            value={reference}
                            type='text'
                            onChange={e => setReference(e.target.value)}
                            placeholder='For Example - Webinar of Dr. Krutik Shah, Social Media etc..'
                          ></input>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}
              </div>

              <div className='input-field-button'>
                <button type='submit'>Save</button>
                <div className='personal-note'>
                  Note: <span style={{ color: 'orangered', fontWeight: 'bolder' }}>* Mandatory fields</span>{' '}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
