import React, { useEffect, useState } from 'react'
import { callApi } from '../utils/api-utils'
import QuizViewCertificate from './ViewQuizResut'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const viewCourse = {
  ...defaults.methods.GET,
  uri: '/api/order/buy-course-list'
}

const viewTransactionsPdf = {
  ...defaults.methods.GET,
  uri: '/api/course/certificate'
}
const redeemPoints = {
  ...defaults.methods.GET,
  uri: '/api/order/redeem-coin'
}

function calculatePercentage(part, whole) {
  if (part === 0 && whole === 0) {
    return 0
  }
  return (part / whole) * 100
}

export default function QuizCertificate() {
  const [responseData, setResponseData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const id = localStorage.getItem('mongoId')

  const transactionsData = async () => {
    if (id) {
      await callApi({
        uriEndPoint: viewCourse,
        query: { studentid: id }
      })
        .then(response => {
          const filterData = response?.data.filter(i => i.chapterid === null) || []

          console.log(`🚀  ~ file: QuizCertificate.js:50 ~ transactionsData ~ filterData:`, filterData)

          setResponseData(filterData)
        })
        .catch(e => console.log(e))
    }
  }

  useEffect(() => {
    transactionsData()
  }, [])

  const [selectedRow, setSelectedRow] = useState(null)
  const viewQuizResultFunction = ({ item }) => {
    setSelectedRow(item.chapter)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleDownloadClick = async ({ item }) => {
    await callApi({ uriEndPoint: viewTransactionsPdf, query: { id: item.courseid } })
      .then(res => {
        if (res) {
          const url = `${process.env.REACT_APP_API_ENDPOINT}` + `/${res}`
          setTimeout(() => {
            window.open(url, '_blank')
          }, 2000)
        }
      })
      .catch(e => console.log(e))
  }
  const redeemMyPoint = async ({ item }) => {
    console.log(`🚀  ~ file: QuizCertificate.js:94 ~ redeemMyPoint ~ item:`, item)

    const valueDefined = item.chapter
      .map(i =>
        i?.quiz?.length && i.quiz[0]?.outOf
          ? Number(i.quiz[0]?.outOf)
          : i?.quiz[0]?.questions?.length
            ? i?.quiz[0]?.questions?.length
            : null
      )
      .filter(value => value !== null)

    const totalQuiz = valueDefined.reduce((acc, currentValue) => acc + currentValue, 0)

    const valueDefined1 = item.chapter
      .map(i => (i?.quiz?.length ? Number(i.quiz[0]?.yourScore) : null))
      .filter(value => value !== null)

    const totalQuizAns = valueDefined1.reduce((acc, currentValue) => acc + currentValue, 0)

    const myPercentage = calculatePercentage(totalQuizAns, totalQuiz).toFixed(2)

    if (Number(myPercentage) > 80) {
      await callApi({ uriEndPoint: redeemPoints, query: { is_redeem: true, order_id: item._id } })
        .then(res => {
          return res
        })
        .catch(e => console.log(e))
    }
  }
  const myPoint = item => {
    const valueDefined = item.chapter
      .map(i =>
        i?.quiz?.length && i.quiz[0]?.outOf
          ? Number(i.quiz[0]?.outOf)
          : i?.quiz[0]?.questions?.length
            ? i?.quiz[0]?.questions?.length
            : null
      )
      .filter(value => value !== null)

    const totalQuiz = valueDefined.reduce((acc, currentValue) => acc + currentValue, 0)

    const valueDefined1 = item.chapter
      .map(i => (i?.quiz?.length ? Number(i.quiz[0]?.yourScore) : null))
      .filter(value => value !== null)

    const totalQuizAns = valueDefined1.reduce((acc, currentValue) => acc + currentValue, 0)

    const myPercentage = calculatePercentage(totalQuizAns, totalQuiz).toFixed(2)
    if (Number(myPercentage) > 80) {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <div className='one-one'>
        <h3 className='table-header'>My Certificate</h3>

        <div className='container transactions-container'>
          <table className='rwd-table'>
            <tbody>
              <tr>
                <th>Name</th>

                <th>View</th>
                <th>Download</th>
                <th>Claim your Rewards</th>
              </tr>
              {responseData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td data-th='Name'>{item.coursename}</td>

                    <td data-th='Download'>
                      <button
                        className={'download-button'}
                        onClick={() => {
                          viewQuizResultFunction({ item })
                        }}
                      >
                        View
                      </button>
                    </td>
                    <td data-th='Download'>
                      <button
                        className='download-button'
                        style={{
                          backgroundColor: item.is_completed === false ? '#e7610040' : 'orangered',
                          cursor: item.is_completed === false ? 'default' : 'pointer'
                        }}
                        onClick={() => {
                          item.is_completed === false ? null : handleDownloadClick({ item })
                        }}
                      >
                        Download
                      </button>
                    </td>
                    <td data-th='Download'>
                      <button
                        className='download-button'
                        style={{
                          backgroundColor:
                            item.is_redeem === true ? '#428bca63' : myPoint(item) ? '#428bca63' : '#2788dce0',
                          cursor: item.is_redeem === true ? 'default' : myPoint(item) ? 'default' : 'pointer'
                        }}
                        onClick={() => {
                          item.is_redeem === true ? null : myPoint(item) ? null : redeemMyPoint({ item })
                        }}
                      >
                        Redeem Points
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <QuizViewCertificate isOpen={isModalOpen} data={selectedRow} onClose={closeModal} />
      </div>
    </>
  )
}
