import React, { useState } from 'react'
import { callApi } from '../utils/api-utils'

const defaults = {
  methods: {
    PATCH: {
      method: 'PATCH'
    },
    PUT: {
      method: 'PUT'
    },
    POST: {
      method: 'POST'
    }
  }
}

const updatePasswordEndpoint = {
  ...defaults.methods.POST,
  uri: '/api/user/update-user-password/:id'
}

const PasswordChange = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (newPassword.length !== 0 && newPassword.length !== 0 && newPassword === confirmPassword) {
      // const id = localStorage.getItem('userId') + ''
      const mongoId = localStorage.getItem('mongoId')
      try {
        await callApi({
          uriEndPoint: updatePasswordEndpoint,
          pathParams: { id: mongoId },
          body: { password: newPassword }
        }).then(res => {
          alert(res.message)
        })
      } catch (error) {
        alert('Form Validation Error ')
      }

      // await app
      //   .auth()
      //   .currentUser.updatePassword(newPassword)
      //   .then(() => {
      //     // alert("Password Updated")
      //     // setAgain("")
      //     // setNews("")

      //     app
      //       .firestore()
      //       .collection('users')
      //       .doc(id)
      //       .update({
      //         Password: confirmPassword
      //       })
      //       .then(() => {
      //         alert('Password Updated')
      //         setConfirmPassword('')
      //         setNewPassword('')
      //       })
      //   })
      // const body = { password: confirmPassword }
      // await callApi({ uriEndPoint: updatePasswordEndpoint, pathParams: { id: mongoId }, body })
      //   .then(res => {
      //     console.log('res', res)

      //     if (res.statusCode === 201) {
      //       const stringifyDataStudent = JSON.stringify(res.data)
      //       localStorage.setItem('profileInformation', stringifyDataStudent)
      //       console.log('User updated successfully')
      //     }
      //   })
      //   .catch(e => {
      //     console.log(`🚀  ~ file: PasswordChange.js:73 ~ awaitcallApi ~ e:`, e)
      //   })
    } else {
      alert('Passwords do not match. Please check your password.')
    }
  }

  return (
    <>
      <div className='one-two'>
        <form onSubmit={handleSubmit} className='pass'>
          <h4>Change Password</h4>

          <input
            type={showPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            placeholder='New Password'
            required
          ></input>
          <input
            type={showPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            placeholder='Verify Password'
            required
          ></input>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <input
              style={{ height: '12px', width: '2rem' }}
              type='checkbox'
              checked={showPassword}
              id='check'
              onChange={handlePasswordVisibility}
            />
            <label htmlFor='check' style={{ marginBottom: '11px', cursor: 'pointer' }}>
              Show Password
            </label>
          </div>
          <button type='submit'>Save</button>
        </form>
      </div>
    </>
  )
}

export default PasswordChange
