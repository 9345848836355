import React from 'react'
import '../styles/nav.scss'
import useWindowDimesions from '../components/useWindowDimesions'
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import EmptyPage from '../components/EmptyPage'
import DesktopNavBar from '../components/DesktopNavBar'
import MobileNavBar from '../components/MobileNavBar'

const PressRelease = () => {
  // const [open, isOpen] = useState(false)
  const { width } = useWindowDimesions()
  // const pages = ['Home', 'About', 'Courses', 'News &  Media', 'Why Study at SHAH?']

  if (width < 999) {
    return (
      <>
        <MobileNavBar />
        <Hero title='Press Release' />
        <EmptyPage />
        <Footer />
      </>
    )
  } else {
    return (
      <>
        <div className='desktop'>
          <DesktopNavBar />
          <Hero title='Press Release' />
          <EmptyPage />

          <Footer />
        </div>
      </>
    )
  }
}

export default PressRelease
