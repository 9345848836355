import React from 'react'
import GridComponent from './GridComponent'
import './index.scss'

export default function ShahLearnings() {
  return (
    <>
      <div className='shah-learnings'>
        {/* NavBar */}

        <div className='navbar'>
          <div className='drawer'>
            <span style={{ width: '95%', marginRight: 23 }}></span>
            <span style={{ width: '95%' }}></span>
            <span style={{ width: '95%', marginRight: 43 }}></span>
          </div>
          <div className='image'>
            {' '}
            <img src='/shahlogo.svg' />
          </div>
          <div className='circle'></div>
        </div>

        {/* Content */}
        <div style={{ height: '8em' }}></div>
        <GridComponent
          url='/shahlearnings/m/digital'
          title='Dr. Hahnemann Digital Organon of Medicine'
          category='digitalorganon'
        />
        <GridComponent url='/shahlearnings/m/organon' title='Organon – Access to Success' category='accesstosuccess' />
        <GridComponent url='/shahlearnings/m/kbh' title='Kaun Banega Homeopath' category='kbh' />
      </div>
    </>
  )
}
