import React from 'react'
import './CourseDetailPage.scss'
import ShopingCard from '../../components/shopingcard'
import Hero from '../../components/Hero'
import Overview from '../../components/courseOverview'

export default function CourseDetails() {
  return (
    <div className='main-course-detail-page'>
      <Hero
        title='
        Organon - Access to Success 3:
        Advanced Course on Simple Homeopathy'
        img='/images/digitalOrganon.jpg'
      />
      <Overview value='Course Certification' subValue='Fellowship' />
      <div className='course-details-page'>
        <div className='Course Curriculum'></div>
        <div className='teacher-section'>
          <div className='krutikShah'>
            <div className='author-reviewcontent'>
              <div className='image-section'>
                <div className='image-container'>
                  <img src='/drkrutikshah.png' />
                </div>
              </div>
              <div className='reviewcontent'>
                <div className='instructor'>
                  <span className='teacher'>Mentor</span>
                </div>
                {/* <div className="star-user">
              <div className="stars"></div>

              <div className="user"></div>
            </div> */}

                <div className='author-data'>
                  <div className='name'>Dr. Krutik Shah, M.D. (Organon of Medicine)</div>
                  <div className='author-bio'>
                    Dr. Krutik Shah apart from receiving many national and international honours in his homeopathic
                    career, believes to be the best when he is known as “Proud Child of Dr. Samuel Hahnemann.” His life
                    and work is dedicated to the promote teachings of Dr. Samuel Hahnemann in a very religious way. He
                    always believes “Original Homeopathy is Simple Homeopathy.”
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='FalguniHathila'>
            <div className='author-reviewcontent'>
              <div className='image-section'>
                <div className='image-container'>
                  <img src='/Dr.FalguniHathila.png' />
                </div>
              </div>
              <div className='reviewcontent'>
                <div className='instructor'>
                  <span className='teacher'>Mentor</span>
                </div>
                <div className='author-data'>
                  <div className='name'>Dr. Falguni Hathila, M.D. (Hom.)</div>
                  <div className='author-bio'>
                    She is closely associated with Dr. Krutik Shah since 2013 in Shree Sai Homeopathic Hospital. Her
                    skills of getting vital information in case taking and case management is worth inspiring others.
                    She always plays an integral part of all homeopathic projects of Samuel Hahnemann Academy for
                    Homeopathy - SHAH. “Homeopath with Pure Heart” is the word Dr. Krutik Shah uses for her.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='group-save'>
          <div className='box'>
            <div className='top-section' style={{ color: 'orange', textDecoration: 'underline' }}>
              Save 60% on Course Fees for a Group of 15 Students.
            </div>
            <div className='para'>Get a group access by filling the form.</div>
            <div className='group-access-link'>
              <a
                href='https://5ucgovoefff.typeform.com/to/IoScLGOg?typeform-source=samuelhahnemann.org'
                target='__blank'
              >
                GET GROUP ACCESS NOW
              </a>
            </div>
          </div>
        </div>

        <div className='courses'>
          <div className='courses-heading'>Related Course</div>
          <div className='cards-container'>
            <ShopingCard
              image='https://courseimg.s3.amazonaws.com/1%20to%2070.jpg'
              header='KBH : 2 - Women and Homeopathic Career - Responsibilities, Opportunities and Management'
            />
            <ShopingCard
              image='https://courseimg.s3.amazonaws.com/1%20to%2070.jpg'
              header='Dr. Hahnemann’s Digital Organon of Medicine Part – A: Theory Part — 1 : § 1 to 70'
            />
            <ShopingCard
              image='https://courseimg.s3.amazonaws.com/1%20to%2070.jpg'
              header='KBH : 2 - Women and Homeopathic Career - Responsibilities, Opportunities and Management'
            />
            <ShopingCard
              image='https://courseimg.s3.amazonaws.com/1%20to%2070.jpg'
              header='KBH : 2 - Women and Homeopathic Career - Responsibilities, Opportunities and Management'
            />
            <ShopingCard
              image='https://courseimg.s3.amazonaws.com/1%20to%2070.jpg'
              header='KBH : 2 - Women and Homeopathic Career - Responsibilities, Opportunities and Management'
            />
            <ShopingCard
              image='https://courseimg.s3.amazonaws.com/1%20to%2070.jpg'
              header='KBH : 2 - Women and Homeopathic Career - Responsibilities, Opportunities and Management'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
