import React, { useEffect, useState } from 'react'
import './typoForm.scss'

import { Line } from 'rc-progress'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    }
  }
}

const questionListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/questions/questions/:type'
}
const questionAddEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/answers/answers'
}

const checkType = (value, type) => {
  if (type === 'number') {
    let numberVAlidation = /^[0-9]+$/i
    const numberValue = numberVAlidation.test(value)
    return numberValue
  }
  if (type === 'email') {
    let emailVAlidation =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailValue = emailVAlidation.test(value)
    return emailValue
  }
  if (type == 'text') {
    let textVAlidation = /^[A-Z . a-z]+$/i
    const textValue = textVAlidation.test(value)
    return textValue
  }
  if (type === 'phone') {
    let numberVAlidation = /^[0-9]+$/i

    const numberValue = numberVAlidation.test(value)
    if (numberValue && value.length >= 10) {
      return true
    } else {
      return false
    }
  }
}

const TypoForm = ({ type, title, secondTitle }) => {
  const [sliderView, setSliderView] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [extra, setExtra] = useState(0)
  const [questionData, setQuestionData] = useState([])

  const handleSubmit = async e => {
    e.preventDefault()
    const valueCheck = questionData.every(e => e.answer)
    const submitArray = []

    for (let i = 0; i < questionData.length; i++) {
      submitArray.push({ answer: questionData[i].answer, question_id: questionData[i]._id })
    }

    if (valueCheck) {
      await callApi({ uriEndPoint: questionAddEndPoint, body: { type: type, answers: submitArray } })
        .then(res => {
          const newArray = []
          for (let i = 0; i < questionData.length; i++) {
            delete questionData[i].answer
            newArray.push(questionData[i])
          }
          setCurrentIndex(0)
          setQuestionData(newArray)
          setSliderView(false)
          alert(res?.message || 'Your form submitted successfully')
          console.log(res)
        })
        .catch(e => console.log(e))
    }
  }

  const previewQuestion = data => {
    if (questionData[0]._id === data) {
      return null
    } else {
      setCurrentIndex(currentIndex - 1)
    }
  }
  const nextQuestion = (data, value) => {
    if (questionData.length === data) {
      setQuestionData(pre => {
        pre[currentIndex].answer = value || ''

        return pre
      })
      setExtra(extra + 1)
      return null
    } else {
      if (questionData[currentIndex]?.answer?.length && questionData[currentIndex]?.pattern) {
        setCurrentIndex(currentIndex + 1)
      } else {
        setQuestionData(pre => {
          pre[currentIndex].answer = value || ''

          return pre
        })
        setExtra(extra + 1)
      }
    }
  }

  const callApiFunction = async () => {
    await callApi({ uriEndPoint: questionListEndPoint, pathParams: { type: type } })
      .then(res => {
        if (res?.data) {
          setQuestionData(res.data)
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApiFunction()
  }, [])

  return (
    <div className='mainDiv'>
      <h1>{title && <span> {title}</span>}</h1>

      {sliderView && questionData?.length && (
        <Line
          percent={(100 / questionData.length) * (currentIndex + 1)}
          strokeWidth={0.5}
          trailWidth={0.5}
          strokeColor='orangered'
        />
      )}
      {!sliderView && secondTitle && (
        <h3>
          <span>{secondTitle}</span>
        </h3>
      )}
      {/* {!sliderView && <h2>fill the form</h2>} */}
      {!sliderView && (
        <div className='btn-div'>
          <button onClick={() => setSliderView(true)}>Click to fill the form</button>
        </div>
      )}

      {sliderView && questionData?.length && (
        <form onSubmit={handleSubmit}>
          <div className='question-main-div'>
            <div className='question-div'>
              <div className='question'>
                {questionData[currentIndex].question.includes('?')
                  ? questionData[currentIndex].question.slice(0, questionData[currentIndex].question.length - 1) +
                    ' ' +
                    questionData[0]?.answer +
                    questionData[currentIndex].question.slice(questionData[currentIndex].question.indexOf('?'))
                  : questionData[currentIndex].question.includes('.')
                  ? questionData[currentIndex].question.slice(0, questionData[currentIndex].question.indexOf('.')) +
                    ' ' +
                    questionData[0]?.answer +
                    questionData[currentIndex].question.slice(questionData[currentIndex].question.indexOf('.'))
                  : questionData[currentIndex].question}
              </div>
              <div style={{ width: '100%' }}>
                <input
                  type={questionData[currentIndex].input_type}
                  placeholder='type your answer here..'
                  value={questionData[currentIndex]?.answer || ''}
                  onChange={async e => {
                    const targetValue = e.target.value
                    const value = await checkType(targetValue, questionData[currentIndex].input_type)
                    questionData[currentIndex].answer = targetValue
                    questionData[currentIndex].pattern = value
                    setQuestionData(questionData)
                    setExtra(extra + 1)
                  }}
                />
                <div style={{ color: 'red', alignSelf: 'self-start' }}>
                  {(questionData[currentIndex]?.answer?.length === 0 ||
                    questionData[currentIndex]?.pattern === false) &&
                    questionData[currentIndex]?.error}
                </div>
              </div>
            </div>
            <div className='ok-btn-div'>
              <button
                type={(100 / questionData.length) * (currentIndex + 1) === 100 ? 'submit' : 'button'}
                onClick={() => {
                  if ((100 / questionData.length) * (currentIndex + 1) !== 100) {
                    if (questionData[currentIndex]?.answer?.length && questionData[currentIndex]?.pattern) {
                      setCurrentIndex(currentIndex + 1)
                    } else {
                      setQuestionData(pre => {
                        pre[currentIndex].answer = questionData[currentIndex]?.answer || ''

                        return pre
                      })
                      setExtra(extra + 1)
                    }
                  } else {
                    return null
                  }
                }}
              >
                {(100 / questionData.length) * (currentIndex + 1) === 100 ? 'SUBMIT' : 'OK'}
                {(100 / questionData.length) * (currentIndex + 1) !== 100 && <i className='fa fa-check'></i>}
              </button>
            </div>
          </div>
        </form>
      )}
      {sliderView && questionData.length && (
        <div className='arrow-div'>
          <i className='fa fa-arrow-up' onClick={() => previewQuestion(questionData[currentIndex]._id)}></i>
          <i
            className='fa fa-arrow-down'
            onClick={() => nextQuestion(currentIndex + 1, questionData[currentIndex]?.answer)}
          ></i>
        </div>
      )}
    </div>
  )
}

export default TypoForm
