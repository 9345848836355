import React, { useState } from 'react'
// import Logo from '../img/logo.svg';
import useWindowDimesions from '../components/useWindowDimesions'
import FooterShah from '../components/FooterShah'
import '../styles/footer.scss'
import axios from 'axios'
// import { Newsletter } from '../data/formConfig'
// import CookieConsent from 'react-cookie-consent'
const Footer = () => {
  const { width } = useWindowDimesions()
  const [email, setEmail] = useState('')

  async function Submit(e) {
    e.preventDefault()
    if (email.length === 0) {
      alert('Please enter your email address')
    } else {
      const Data = {
        email: email
      }
      await axios
        .post(`/api/news-letter/add`, Data)
        .then(async () => {
          // window.location.reload()
          alert(
            'Welcome to SHAH family!\nPlease write us your confusion, disappointments and failures to us which will guide us to make attractive future courses and qualitative learnings @\norganon@samuelhahnemann.org'
          )
          setEmail('')
        })
        // axios
        //   .post(`https://formsubmit.co/ajax/${Newsletter}`, {
        //     _subject: 'SHAH Newsletter Subscribed',
        //     For: 'SHAH Newsletter',
        //     Email: email,
        //     Date: new Date().toString(),
        //     body: 'front-end-api'
        //   })
        //   .then(() => {
        //     setEmail('')
        //     alert(
        //       'Welcome to SHAH family! Please write us your confusion, disappointments and failures to us which will guide us to make attractive future courses and qualitative learnings!'
        //     )
        //   })
        .catch(e => console.log(e))
    }
  }

  // if (width < 999) {
  return (
    <>
      <div className={width < 999 ? 'm-footers' : 'footers'}>
        <div className='newsletter'>
          <div className='title-description'>
            <div className='heading'>
              <h2 style={{ fontFamily: width < 999 && "'Jost',sans-serif", display: 'flex', alignItems: 'end' }}>
                {' '}
                <img src='/message.png' />
                <span style={{ marginLeft: '5px' }}> Newsletter</span>
              </h2>
              <p style={{ fontFamily: width < 999 && "'Jost',sans-serif" }}>
                Never Miss any Updates from <b>S</b>
                amuel <b>H</b>ahnemann <b>A</b>cademy for <b>H</b>omeopathy-<b>SHAH</b>,
                <br /> Avail Special Discounts on Courses. Attend Free Webinars.
                <br /> <b style={{ color: 'orangered' }}>Join the SHAH Family!</b>
              </p>
            </div>
          </div>

          <form onSubmit={Submit} className='mail-input'>
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              type='email'
              placeholder='Your email address'
              required
              style={{ fontFamily: width < 999 && "'Jost',sans-serif" }}
            />
            <button style={{ fontFamily: width < 999 && "'Jost',sans-serif" }} type='submit'>
              SEND
            </button>
          </form>
        </div>
        {/* <CookieConsent
          location='bottom'
          buttonText='Accept'
          cookieName='myAwesomeCookieName2'
          style={{
            background: '#2B373B',
            fontFamily: " 'Jost',sans-serif ",
            zIndex: 100000
          }}
          buttonStyle={{
            color: '#fff',
            backgroundColor: 'orangered',
            fontSize: width < 999 ? '13px' : '16px',
            fontFamily: " 'Jost',sans-serif ",
            paddingLeft: 12,
            paddingRight: 12
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{' '}
          <span
            onClick={() => (window.location.href = '/terms-conditions')}
            style={{
              fontSize: width < 999 ? '10px' : '16px',
              cursor: 'pointer',
              textDecoration: 'underline',
              color: 'orangered'
            }}
          >
            Cookie Policy
          </span>
        </CookieConsent> */}
        <FooterShah />
      </div>
    </>
  )
  // } else {
  //   return (
  //     <>
  //       <div className="footers">
  //         <div className="newsletter">
  //           <div className="title-description">
  //             <img src="/message.png" />
  //             <div className="heading">
  //               <h2>Newsletter</h2>
  //               <p>
  //                 Never Miss an Updates from Samuel Hahnemann Academy for
  //                 Homeopathy-SHAH. <br />
  //                 Avail Special Discounts on Courses to Free Webinars. <br />
  //                 <b>Join the SHAH Family!</b>
  //               </p>
  //             </div>
  //           </div>

  //           <form onSubmit={Submit} className="mail-input">
  //             <input
  //               value={email}
  //               onChange={(e) => setEmail(e.target.value)}
  //               type="email"
  //               placeholder="Your email address"
  //               required
  //             />
  //             <button type="submit">SEND</button>
  //           </form>
  //         </div>
  //         <CookieConsent
  //           location="bottom"
  //           buttonText="Accept"
  //           cookieName="myAwesomeCookieName2"
  //           style={{
  //             background: "#2B373B",
  //             fontFamily: " 'Jost',sans-serif ",
  //             zIndex: 100000,
  //           }}
  //           buttonStyle={{
  //             color: "#fff",
  //             backgroundColor: "orangered",
  //             fontSize: "16px",
  //             fontFamily: " 'Jost',sans-serif ",
  //             paddingLeft: 12,
  //             paddingRight: 12,
  //           }}
  //           expires={150}
  //         >
  //           This website uses cookies to enhance the user experience.{" "}
  //           <span
  //             onClick={() => (window.location.href = "/terms-conditions")}
  //             style={{
  //               fontSize: "16px",
  //               cursor: "pointer",
  //               textDecoration: "underline",
  //               color: "orangered",
  //             }}
  //           >
  //             Cookie Policy
  //           </span>
  //         </CookieConsent>
  //         <FooterShah />
  //       </div>
  //     </>
  //   );
  // }
}

export default Footer
