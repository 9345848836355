import React from 'react'
import useWindowDimesions from '../components/useWindowDimesions'
import '../styles/hero.scss'

const Hero = props => {
  const { width } = useWindowDimesions()

  const someString = props.title

  const index = someString.indexOf(props.divide)
  const id = someString.substr(0, index)
  const text = someString.substr(index)

  if (width > 999) {
    return (
      <>
        <div className='desktop-hero'>
          <img src={props.img} alt='courses' />

          <div className='content'>
            {props.divide ? (
              <div className='title'>
                {id}
                <br />
                {text}
              </div>
            ) : (
              <div className='title'>{props.title}</div>
            )}
            <div className='breadcrumb'>
              <span onClick={() => (window.location.href = '/')}>Home</span>
              <i className='fa fa-chevron-right'></i>
              <span>{props.title}</span>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <div className='mobile-hero'>
        <img src={props.img} alt='courses' />

        <div className='content'>
          {props.divide ? (
            <div className='title course-mobile-title'>
              {id}
              <br />
              {text}
            </div>
          ) : (
            <div className='title'>{props.title}</div>
          )}
          <div className='breadcrumb'>
            <span onClick={() => (window.location.href = '/')}>Home</span>
            <i className='fa fa-chevron-right'></i>
            <span>{props.title}</span>
          </div>
        </div>
      </div>
    )
  }
}

Hero.defaultProps = {
  title: 'Hero Name',
  img: '/images/WhyStudy@SHAH.jpg'
}

export default Hero
