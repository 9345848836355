import React from 'react'
import useWindowDimesions from '../components/useWindowDimesions'
import '../styles/coursecard.scss'
import RatingCard from './ratingCard'

const CourseCard = props => {
  const { width } = useWindowDimesions()

  const p = props.purchased === true ? '/view-course/' : '/'

  if (width > 999) {
    return (
      <>
        <div
          className='coursecard-1'
          onClick={() =>
            props.published === true ? (window.location.href = p + props.slug) : alert('This course is Coming soon')
          }
        >
          <img src={props.img === 'nnn' ? 'https://courseimg.s3.amazonaws.com/1.png' : props.img} alt={props.title} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '0.5rem 0',
              height: '100%'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='main-title-card'>
                {props.title.split(':')[0]} :
                <span className=''>
                  {props.title.split(':')[2] === undefined
                    ? props.title.split(':')[1]
                    : props.title.split(':')[1] + props.title.split(':')[2]}
                </span>{' '}
                <p style={{ color: 'orangered', fontSize: '12px', fontWeight: '700', margin: '0' }}>
                  {props?.one_line}
                </p>
              </span>
              <div className='border-div'></div>
              <div className='duration-time-div'>
                <span className='duration-time'>
                  {props?.chapterCount} Chapters | {props?.lessonCount} Lessons | {props.TotalDuration}
                </span>
              </div>
            </div>
            <RatingCard rating={props.rating} />
            <div className='read-more-div'>Read More</div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div
          className='coursecard-1'
          onClick={() =>
            props.published === true ? (window.location.href = p + props.slug) : alert('This course is Coming soon')
          }
        >
          <img src={props.img === 'nnn' ? 'https://courseimg.s3.amazonaws.com/1.png' : props.img} alt={props.title} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '0.5rem 0',
              height: '100%'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='main-title-card'>
                {props.title.split(':')[0]}:
                <span className=''>
                  {props.title.split(':')[2] === undefined
                    ? props.title.split(':')[1]
                    : props.title.split(':')[1] + props.title.split(':')[2]}
                </span>
                <p style={{ color: 'orangered', fontSize: '12px', fontWeight: '700', margin: '0' }}>
                  {props?.one_line}
                </p>
              </span>
              <div className='border-div'></div>
              <div className='duration-time-div'>
                <span className='duration-time'>
                  {props?.chapterCount} Chapters | {props?.lessonCount} Lessons | {props.TotalDuration}
                </span>
              </div>
            </div>
            <RatingCard rating={props.rating} />
            <div className='read-more-div'>Read More</div>
          </div>
        </div>
      </>
    )
  }
}

CourseCard.defaultProps = {
  title: 'Course Name',
  description: 'Course Description',
  img: 'https://wordpress-461136-1831741.cloudwaysapps.com/wp-content/uploads/2021/03/working-desk-with-digital-tablet-showing-digital-marketing-concept.jpg',
  chapters: '0',
  lessons: '0',
  slug: 'course-name'
}

export default CourseCard
