/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import useWindowDimesions from '../components/useWindowDimesions'
import '../styles/coursecard.scss'
import axios from 'axios'
import RatingCard from './ratingCard'

function formatDuration(minutes) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = Math.floor(minutes % 60)
  const seconds = Math.round((minutes % 1) * 60)

  return `${hours} hr. ${remainingMinutes} min. ${seconds} sec.`
}

const ChapterCard = props => {
  const { width } = useWindowDimesions()
  const [Chapters, setChapters] = useState([])
  const [minute, setMinute] = useState(null)

  const [Lessons, setLessons] = useState('')

  useEffect(() => {
    getLessons()

    getChapters()
  }, [])

  async function getChapters() {
    await axios
      .post('/api/course/all-chapters', {
        id: props.chapters,

        body: 'front-end-api'
      })
      .then(res => {
        const array = res.data.filter(i => i._id === props.chapter_id) || []

        setChapters(array)
      })
      .catch(e => alert(e))
  }

  async function getLessons() {
    await axios
      .get(`/api/course/all-course-lessons/${props.chapters}/?type=front-end-api`)
      .then(res => {
        const filtreData = res.data.flat().filter(i => i.chapterid === props.chapter_id)

        const totalMinutes =
          props.timeInChapter.hours * 60 + props.timeInChapter.minutes + props.timeInChapter.seconds / 60
        console.log(`🚀  ~ getLessons ~ filter:`, totalMinutes)

        setMinute(totalMinutes)

        setLessons(filtreData.length)
      })
      .catch(e => console.log(e))
  }

  const p = props.purchased === true ? '/view-course/' : '/course/'

  if (width > 999) {
    return (
      <>
        <div
          className='coursecard-1'
          onClick={() =>
            props.published === true
              ? (window.location.href = p + props.chapter_id + '-' + props.slug)
              : alert('This course is Coming soon')
          }
        >
          <img
            src={
              props.img === 'nnn'
                ? 'https://courseimg.s3.amazonaws.com/1.png'
                : props.chapter_thumbnail
                ? props.chapter_thumbnail
                : props.img
            }
            alt={props.title}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '0.5rem 0',
              height: '100%'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='title-card'>
                {props.chaptername.split(':')[2] === undefined
                  ? props.chaptername.split(':')[1]
                  : props.chaptername.split(':')[1] + props.chaptername.split(':')[2]}
              </span>

              <span className='main-title-card'>{props.chaptername.split(':')[0]}</span>
              <div className='border-div'></div>
              <div className='duration-time-div'>
                <span className='duration-time'>
                  {Chapters?.length} Chapters | {Lessons} Lessons | {formatDuration(minute)}
                </span>
              </div>
            </div>
            <RatingCard rating={props.rating} />
            <div className='read-more-div'>Read More</div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div
          className='coursecard-1'
          onClick={() =>
            props.published === true ? (window.location.href = p + props.slug) : alert('This course is Coming soon')
          }
        >
          <img
            src={
              props.img === 'nnn'
                ? 'https://courseimg.s3.amazonaws.com/1.png'
                : props.chapter_thumbnail
                ? props.chapter_thumbnail
                : props.img
            }
            alt={props.chaptername}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '0.5rem 0',
              height: '100%'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='title-card'>
                {props.chaptername.split(':')[2] === undefined
                  ? props.chaptername.split(':')[1]
                  : props.chaptername.split(':')[1] + props.chaptername.split(':')[2]}
              </span>

              <span className='main-title-card'>{props.chaptername.split(':')[0]}</span>
              <div className='border-div'></div>
              <div className='duration-time-div'>
                <span className='duration-time'>
                  {Chapters?.length} Chapters | {Lessons} Lessons | {formatDuration(minute)}
                </span>
              </div>
            </div>
            <RatingCard rating={props.rating} />
            <div className='read-more-div'>Read More</div>
          </div>
        </div>
      </>
    )
  }
}

ChapterCard.defaultProps = {
  title: 'Course Name',
  description: 'Course Description',
  img: 'https://wordpress-461136-1831741.cloudwaysapps.com/wp-content/uploads/2021/03/working-desk-with-digital-tablet-showing-digital-marketing-concept.jpg',
  chapters: '0',
  lessons: '0',
  slug: 'course-name'
}

export default ChapterCard
