import { Button, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment-timezone'
import useWindowDimensions from './components/useWindowDimesions'
import { app } from './config/firebase'

const Logger = () => {
  const [dateData, setDateData] = useState(null)
  const [loggerData, setLoggerData] = useState([])
  const [selectName, setSelectName] = useState('front-end-api')
  const [dateValidation, setDateValidation] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loadings, setLoadings] = useState(false)
  const { width } = useWindowDimensions()

  const DateOnChange = value => {
    setDateData(value)
    setDateValidation(false)
  }

  async function getLoggerData() {
    if (dateData === null) {
      setDateValidation(true)
    }
    if (dateData !== null) {
      const body = { type: selectName, date: dateData }

      await axios
        .post(`/api/logger/logger-user`, body)
        .then(res => {
          setLoggerData(res.data.Data)
        })
        .catch(e => alert(e))
    }
  }

  function LoginSubmit(e) {
    e.preventDefault()
    app
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setLoadings(true)
      })
      .catch(e => {
        setLoadings(false)
        alert(e.message)
      })
  }

  const LogoutClick = async () => {
    await app
      .auth()
      .signOut()
      .then(() => (window.location.href = '/logger'))
    localStorage.removeItem('loginUser')
    localStorage.removeItem('studentStore')
    localStorage.removeItem('buyCourses')
    localStorage.removeItem('profileInformation')
    localStorage.removeItem('referral_code')
    localStorage.removeItem('saveCurrentChat')
  }

  return (
    <>
      {loadings === false && (
        <div
          className='signup-form'
          style={{
            paddingBottom: width < 999 ? 30 : 6,
            paddingTop: 45,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img className='logo' src='/shahlogo.svg' alt='drkrutikshah.online' style={{ width: '25%' }} />

          <form onSubmit={LoginSubmit}>
            <TextField
              required
              id='outlined-required'
              className='email'
              label='Email address '
              value={email}
              onChange={e => setEmail(e.target.value)}
              size='small'
              variant='outlined'
            />

            <TextField
              required
              id='outlined-required'
              className='password'
              label='Password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              size='small'
              variant='outlined'
              type='password'
            />

            <button style={{ cursor: 'pointer' }} type='submit'>
              LogIn
            </button>
          </form>
        </div>
      )}
      {loadings === true && (
        <div style={{ padding: '30px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <select
                name='viewCategory'
                onChange={e => {
                  setSelectName(e.target.value)
                  setDateValidation(false)
                }}
                style={{ padding: '8px 10px 8px 10px' }}
              >
                <option>front-end-api</option>
                <option>mobile-api</option>
              </select>
            </div>
            <div>
              <input
                type='date'
                style={{ padding: '8px 10px 8px 10px' }}
                max={moment().format('YYYY-MM-DD')}
                onChange={e => DateOnChange(e.target.value)}
              />
              {dateValidation && <div style={{ color: 'red' }}>Please select the Date</div>}
            </div>
            <div>
              <Button onClick={getLoggerData} variant='contained' color='primary'>
                view Logs
              </Button>
            </div>
          </div>
          {loggerData.length !== 0 && (
            <div style={{ marginTop: '30px' }}>
              <textarea
                style={{ width: '100%' }}
                rows={10}
                value={Object.keys(loggerData).length !== 0 ? loggerData : 'No Data Found'}
                disabled
              />
            </div>
          )}
          <Button variant='contained' style={{ marginTop: '20px' }} onClick={LogoutClick}>
            Logout
          </Button>
        </div>
      )}
    </>
  )
}

export default Logger
